<template>
  <div class="modaldetail">
    <div class="modaldetail__mask" @click="closeModal()"></div>
    <div class="modaldetail__box">
      <div class="modaldetail__box-title">
        <p class="modaldetail__box-title-text">RO Detail</p>
        <img
          src="@/assets/admin/icon/close.svg"
          alt="close"
          class="modaldetail__box-title-text-img"
          @click="closeModal()"
        />
      </div>
      <div v-if="dataDetail.id !== null">
        <div class="modaldetail__box-row">
          <p
            class="modaldetail__box-row-title modaldetail__box-row-title_center modaldetail__box-row-title_bold"
          >
            {{ dataDetail?.id }}
          </p>
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Unit</p>
          <div class="modaldetail__box-row-user-wrapper">
            <div class="modaldetail__box-row-input-preview">
              <p>{{ dataDetail?.user || "-" }}</p>
            </div>
            <!-- <div class="modaldetail__box-row-input-preview">
              <p>{{ dataDetail?.attribute?.name || "-" }}</p>
            </div> -->
          </div>
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Status</p>
          <div
            class="transactioncontent__list-table-body-status-box"
            :class="
              dataDetail.status === 'PAID'
                ? 'transactioncontent__list-table-body-status-box_paid'
                : dataDetail.status === 'PENDING'
                ? 'transactioncontent__list-table-body-status-box_pending '
                : ''
            "
          >
            <p class="transactioncontent__list-table-body-status">
              {{ dataDetail?.status }}
            </p>
          </div>
        </div>
        <!-- <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Deskripsi</p>
          <div class="modaldetail__box-row-input-preview">
            <p>{{ dataDetail?.description || "-" }}</p>
          </div>
        </div> -->
        <!-- <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Jumlah</p>
          <div class="modaldetail__box-row-input-preview">
            <p>Rp. {{ dataDetail?.omset || "-" }}</p>
          </div>
        </div> -->
        <!-- <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Point Value</p>
          <div class="modaldetail__box-row-input-preview">
            <p>{{ dataDetail?.point_value_locking || "-" }}</p>
          </div>
        </div> -->
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Payment</p>
          <div class="modaldetail__box-row-input-payment">
            <div class="modaldetail__box-row-input-payment-status">
              <div
                class="transactioncontent__list-table-body-status-box transactioncontent__list-table-body-status-box_pending"
                :class="
                  dataDetail?.payments[0]?.status?.toUpperCase() === 'SUCCESS'
                    ? 'transactioncontent__list-table-body-status-box_paid'
                    : dataDetail?.payments[0]?.status?.toUpperCase() ===
                      'PENDING'
                    ? 'transactioncontent__list-table-body-status-box_pending '
                    : ''
                "
              >
                <p class="transactioncontent__list-table-body-status">
                  {{ dataDetail?.payments[0]?.status?.toUpperCase() || "-" }}
                </p>
              </div>
            </div>
            <div class="modaldetail__box-row-input-payment-row">
              <p class="modaldetail__box-row-input-payment-row-text">
                Tanggal:
              </p>
              <p
                class="modaldetail__box-row-input-payment-row-text modaldetail__box-row-input-payment-row-text_right"
              >
                {{ moment(dataDetail?.date).format("DD MMM YYYY") || "-" }}
              </p>
            </div>
            <div class="modaldetail__box-row-input-payment-row">
              <p class="modaldetail__box-row-input-payment-row-text">
                Metode Pembayaran:
              </p>
              <p
                class="modaldetail__box-row-input-payment-row-text modaldetail__box-row-input-payment-row-text_right"
              >
                {{ dataDetail?.payments[0]?.payment_method || "-" }}
              </p>
            </div>
            <div class="modaldetail__box-row-input-payment-row">
              <p class="modaldetail__box-row-input-payment-row-text">
                Jumlah Pembayaran:
              </p>
              <p
                class="modaldetail__box-row-input-payment-row-text modaldetail__box-row-input-payment-row-text_right"
              >
                Rp.
                {{
                  formatNumber(dataDetail?.payments[0]?.payment_amount) || "-"
                }}
              </p>
            </div>
            <div class="modaldetail__box-row-input-payment-row">
              <p class="modaldetail__box-row-input-payment-row-text">
                Bukti Bayar:
              </p>
              <p
                class="modaldetail__box-row-input-payment-row-text modaldetail__box-row-input-payment-row-text_pointer modaldetail__box-row-input-payment-row-text_right"
                @click="openPreviewImage(dataDetail?.payments[0]?.payment_url)"
              >
                {{ dataDetail?.payments[0]?.payment_url || "-" }}
              </p>
            </div>
          </div>
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Product</p>
          <div class="modaldetail__box-row-transaction-product">
            <div
              class="modaldetail__box-row-transaction-product-component-wrapper"
            >
              <div class="modaldetail__box-row-transaction-product-component">
                <p
                  class="modaldetail__box-row-transaction-product-component-text"
                >
                  Nama
                </p>
                <p
                  class="modaldetail__box-row-transaction-product-component-text"
                >
                  Harga Member
                </p>
                <p
                  class="modaldetail__box-row-transaction-product-component-text"
                >
                  Point Value
                </p>
                <p
                  class="modaldetail__box-row-transaction-product-component-text-stock"
                >
                  Kuantitas
                </p>
              </div>
              <div
                class="modaldetail__box-row-transaction-product-component-value-wrapper"
                v-for="(dt, index) in dataDetail.product_transactions"
                :key="index"
              >
                <div
                  class="modaldetail__box-row-transaction-product-component-value"
                >
                  <div class="modaldetail__box-row-input-preview">
                    <p>{{ dt.product.name || "-" }}</p>
                  </div>
                </div>
                <div
                  class="modaldetail__box-row-transaction-product-component-value"
                >
                  <div class="modaldetail__box-row-input-preview">
                    <p>{{ dt.product.member_price || "-" }}</p>
                  </div>
                </div>
                <div
                  class="modaldetail__box-row-transaction-product-component-value"
                >
                  <div class="modaldetail__box-row-input-preview">
                    <p>{{ dt.product.point_value || "-" }}</p>
                  </div>
                </div>
                <div
                  class="modaldetail__box-row-transaction-product-component-value"
                >
                  <div class="modaldetail__box-row-input-preview">
                    <p>{{ dt.quantity || "-" }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PreviewImageVue
      v-if="previewImageStatus"
      :data="previewImageData"
      @closeModal="closePreviewImage"
    />
  </div>
</template>

<script>
import moment from "moment";
import PreviewImageVue from "@/components/molecules/PreviewImage.vue";

export default {
  components: {
    PreviewImageVue,
  },
  props: {
    transactionDetail: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dataDetail: {
        attribute: null,
        id: null,
        description: null,
        payments: null,
        point_value_locking: null,
        product_transactions: null,
        status: null,
        type: null,
        user: null,
        omset: null,
        date: null,
      },
      previewImageStatus: false,
      previewImageData: null,
    };
  },
  watch: {
    transactionDetail(val) {
      if (val) {
        this.setTransaction();
      }
    },
  },
  mounted() {
    this.setTransaction(this.transactionDetail);
  },
  methods: {
    moment,
    openPreviewImage(url) {
      this.previewImageStatus = true;
      this.readUrlOrFileImage(url);
    },
    closePreviewImage() {
      this.previewImageStatus = false;
    },
    readUrlOrFileImage(file, type = "") {
      if (type !== "file") {
        if (!file) {
          this.previewImageData = "";
          return;
        }
        if (file.includes("http")) {
          this.previewImageData = this.getGoogleDriveImage(file);
          return;
        }
      }

      const _this = this;
      const reader = new FileReader();

      reader.onload = function (e) {
        _this.previewImageData = e.target.result;
      };

      reader.readAsDataURL(file);
      return;
    },
    setTransaction(val) {
      this.dataDetail.date = val?.created_at;
      // this.dataDetail.attribute = val?.attribute
      this.dataDetail.id = val?.id;
      // this.dataDetail.description = val?.description;
      this.dataDetail.payments = val?.user_reapet_order?.transaction?.payments;
      // this.dataDetail.point_value_locking = val?.point_value_locking;
      this.dataDetail.product_transactions =
        val?.user_reapet_order?.transaction?.product_transactions;
      this.dataDetail.status = val?.user_reapet_order?.transaction?.status;
      this.dataDetail.type = val?.user_reapet_order?.transaction?.type;
      this.dataDetail.user = val?.user_reapet_order?.user_unit?.unit;
      this.dataDetail.omset = val?.payments?.[0]?.payment_amount;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    resetModalData() {
      this.dataDetail = {
        id: null,
        description: null,
        payments: null,
        point_value_locking: null,
        product_transactions: null,
        status: null,
        type: null,
        user: null,
      };
    },
  },
};
</script>

<style scoped>
.modaldetail {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.modaldetail__mask {
  position: fixed;
  background: black;
  opacity: 75%;
  width: 100vw;
  height: 100vh;
}

.modaldetail__box {
  padding: 0px 2rem 2rem 2rem;
  background-color: white;
  border-radius: 16px;
  z-index: 2;
  min-width: 55rem;
  height: auto;
  max-height: 600px;
  overflow-y: scroll;
}

.modaldetail__box-row-last {
  padding-bottom: 48px;
}

.modaldetail__box::-webkit-scrollbar {
  display: none;
}

.modaldetail__box-title {
  position: sticky;
  background: white;
  top: 0px;
  padding: 2rem 0 1rem 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modaldetail__box-row {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2rem;
}

.modaldetail__box-row-title {
  font-size: 14px;
  line-height: 16px;
  color: #707880;
}

.modaldetail__box-row-input {
  outline: none;
  border-radius: 8px;
  border: 1px solid #eef0f5;
  background: #f6fafd;
  padding: 8px;
}

.modaldetail__box-row-input::placeholder {
  font-size: 14px;
  line-height: 16px;
  color: darkgray;
}

.modaldetail__box-title-text {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.modaldetail__box-row-button {
  position: sticky;
  text-align: right;
  bottom: 0px;
  width: 100%;
}

.modaldetail__box-title-text-img {
  width: 26px;
  height: auto;
  position: absolute;
  right: 0px;
  cursor: pointer;
}

.modaldetail__box-row-image-box {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  border: 1px dashed black;
  border-radius: 8px;
}

.modaldetail__box-row-image-box-text {
  font-weight: bold;
}

.modaldetail__box-row-image-box-text_normal {
  font-weight: 400;
}

.modaldetail__box-row-image-box-file {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  opacity: 0;
}

.modaldetail__box-row-level {
  display: flex;
  border-radius: 8px;
  border: 1px solid #e7ecf5;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 16px;
  color: black;
  padding: 10px;
  width: fit-content;
  gap: 8px;
}

.modaldetail__box-row-level-wrapper {
  display: flex;
  gap: 1rem;
}

.modaldetail__box-row-level-remove {
  cursor: pointer;
  width: 12px;
}

.modaldetail__box-row-transaction {
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background: #19cb46;
  width: 9rem;
  margin-top: 10px;
}

.modaldetail__box-row-transaction-text {
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.modaldetail__box-row-transaction-product {
  display: flex;
  gap: 10px;
}

.modaldetail__box-row-transaction-product-component {
  display: flex;
  gap: 10px;
  padding: 16px 0px;
  margin: 16px 0px;
  border-bottom: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;
}

.modaldetail__box-row-transaction-product-component-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
}

.modaldetail__box-row-transaction-product-component-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  flex: 0.25;
}

.modaldetail__box-row-transaction-product-component-value {
  flex: 0.25;
}

.modaldetail__box-row-transaction-product-component-stock {
  flex: 0.25;
}

.modaldetail__box-row-transaction-product-component-delete-img {
  width: 24px;
  cursor: pointer;
}

.modaldetail__box-row-transaction-product-component-value-wrapper {
  display: flex;
  gap: 10px;
}

.modaldetail__box-row-input-preview {
  outline: none;
  border-radius: 8px;
  border: 1px solid #eef0f5;
  background: #f6fafd;
  padding: 6px 12px;
  width: 100%;
  height: 100%;
}

.modaldetail__box-row-input-preview::placeholder {
  font-size: 14px;
  line-height: 16px;
  color: darkgray;
}

.modaldetail__box-row-transaction-product-component-text-stock {
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  flex: 0.25;
}

.modaldetail__box-row-transaction_gray {
  filter: grayscale(1);
}

.transactioncontent__list-table-body-status-box {
  width: fit-content;
  padding: 8px 16px;
  border-radius: 10px;
  background: #fa5c7c;
}

.transactioncontent__list-table-body-status-box_pending {
  background-color: #f7b011;
}

.transactioncontent__list-table-body-status-box_paid {
  background-color: #19cb46;
}

.modaldetail__box-row-user-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.modaldetail__box-row-title_bold {
  font-weight: bold;
}

.modaldetail__box-row-title_center {
  text-align: center;
}

.modaldetail__box-row-input-payment {
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  padding: 16px;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modaldetail__box-row-input-payment-status {
  display: flex;
  justify-content: center;
}

.modaldetail__box-row-input-payment-row {
  display: flex;
}

.modaldetail__box-row-input-payment-row-text {
  display: flex;
  font-size: 12px;
  flex: 0.5;
  justify-content: flex-start;
  font-weight: bold;
  word-break: break-all;
}

.modaldetail__box-row-input-payment-row-text_right {
  justify-content: flex-end;
  font-weight: 400;
}

.modaldetail__box-row-input-payment-row-text_pointer {
  cursor: pointer;
}
</style>
