<template>
  <footer class="footer_area p_120">
    <div class="container">
      <div class="row footer_inner">
        <div class="col-lg-5 col-sm-6">
        </div>
        <div class="col-lg-5 col-sm-6">
          <aside class="f_widget ab_widget">
            <div class="f_title">
          <a href="https://dashboard.togamartherbal.com/foto/kode_etik.pdf"><h1 style="color: #059240;" >KODE ETIK</h1></a>
            </div>
          </aside>
            </div>
            <div class="col-lg-5 col-sm-6">
        </div>
        </div>
     </div>
    <div class="container">
      <div class="row footer_inner">
        <div class="col-lg-5 col-sm-6">
          <aside class="f_widget ab_widget">
            <div class="f_title">
              <h3>Tentang GHANI</h3>
            </div>
            <p>PT. Gahar Herba Natura Indonesia adalah sebuah perusahaan direct selling berbasis syariah </p>
            <a href="#"><img src="@/assets/ICON FB.svg" alt=""></a>
            <a href="#"><img src="@/assets/ICON IG (1).svg" alt=""></a>
            <a href="#"><img src="@/assets/ICON TIKTOK.svg" alt=""></a>
            <a href="#"><img src="@/assets/ICON YT.svg" alt=""></a>
          </aside>
        </div>
        <div class="col-lg-3 col-sm-6">
          <aside class="f_widget news_widget">
            <div class="f_title">
              <h3>Telepon/Chat</h3>
            </div>
            <div class="row">
              <div class="col-1"><img src="@/assets/phone-svgrepo-com (1).svg" alt="" style="width: 25px;"></div>
              <div class="col-2"><p>+6281370707885</p></div>
            </div>
            
          </aside>
        </div>
        <div class="col-lg-4">
          <aside class="f_widget social_widget">
            <div class="f_title">
              <h3>Alamat</h3>
            </div>
            <div class="row">
              <div class="col-1"><img src="@/assets/gps-svgrepo-com.svg" alt="" style="width: 25px;"></div>
              <div class="col-10"><p>Jl. Raya Mess Al No.49, Rt.004/ Rw.005, Kel. Jatiraden, Kec. Jatisampurna, Kota Bekasi - Jawa Barat 17434
            </p></div>
            </div>
            
          </aside>
        </div>
      </div>
      <div class="copy_right_text">
        <p>
          Copyright &copy; 2023 GHANIPUSAT
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style></style>