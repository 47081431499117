<template>
  <div class="main">
  
    <FloatingButton></FloatingButton>

    <Header></Header>
    
    <!--================Home Banner Area =================-->
    <Dashboard></Dashboard>
    <!--================End Home Banner Area =================-->

    <!--================About Area =================-->
    <About></About>
    <!--================About Area =================-->

    <!--================Feature Area =================-->
    <Produk></Produk>
    <!--================End Feature Area =================-->

    <!--================Testimonials Area =================-->
    <Client></Client>
    <!--================End Testimonials Area =================-->

    <!--================Post Slider Area =================-->
    <PaketProduk></PaketProduk>
    <!--================End Post Slider Area =================-->

    <!--================Clients Logo Area =================-->
    <Komisi></Komisi>
    <!--================End Clients Logo Area =================-->

    <!--================Our Blog Area =================-->
    <Berita></Berita>
    <!--================End Our Blog Area =================-->

    <!--================Footer Area =================-->
    <Footer > </Footer>
    <!--================End Footer Area =================-->
  </div>
</template>

<script>
// @ is an alias to /src
import FloatingButton from "@/components/compro/FloatingButton.vue";
import Header from "@/components/compro/Header.vue";
import Dashboard from "./sections/Dashboard.vue";
import About from "./sections/About.vue";
import PaketProduk from "./sections/PaketProduk.vue";
import Produk from "./sections/Produk.vue";
import Komisi from "./sections/Komisi.vue";
import Client from "./sections/Client.vue";
import Berita from "./sections/Berita.vue";
import Footer from "@/components/compro/Footer.vue";

export default {
  name: "MainPage",
  components: {
    FloatingButton,
    Header,
    Dashboard,
    About,
    PaketProduk,
    Produk,
    Komisi,
    Client,
    Berita,
    Footer,
  },
};
</script>


<style >
</style>