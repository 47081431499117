<template>
  <div class="totalsalescontent">
    <div class="totalsalescontent__list">
      <div class="totalsalescontent__list-menu">
        <div class="totalsalescontent__list-date">
          <p>Pilih Tanggal</p>
          <date-picker v-model="dateRange" range />
        </div>
        <div class="totalsalescontent__list-adddelete">
          <p class="totalsalescontent__list-adddelete_omset">
            Periode:
            <span class="totalsalescontent__list-adddelete_omset-amount">
              {{ moment(validData?.[0]?.date_range?.split(" ")[0], 'DD-MM-YYYY').format('DD MMM YYYY') }} - 
              {{ moment(validData?.[0]?.date_range?.split(" ")[2], 'DD-MM-YYYY').format('DD MMM YYYY') }}
            </span>
          </p>
        </div>
      </div>
      <div class="totalsalescontent__list-table">
        <div class="totalsalescontent__list-table-header">
          <p class="totalsalescontent__list-table-header-no">No</p>
          <p class="totalsalescontent__list-table-header-status">Nama Produk</p>
          <p class="totalsalescontent__list-table-header-status">Total Terjual</p>
          <p class="totalsalescontent__list-table-header-status">Stok</p>
          <p class="totalsalescontent__list-table-header-status">Total Harga</p>
        </div>
        <div class="totalsalescontent__list-table-body">
          <div v-if="validData && validData.length > 0">
            <div class="totalsalescontent__list-table-body-user" v-for="(item, index) in validData" :key="index">
              <p class="totalsalescontent__list-table-body-no">
                {{ index + 1 }}
              </p>
              <p class="totalsalescontent__list-table-body-status">
                {{ item.product_name }}
              </p>
              <p class="totalsalescontent__list-table-body-status">
                {{ item.total }}
              </p>
              <p class="totalsalescontent__list-table-body-status">
                {{ item.stock }}
              </p>
              <p class="totalsalescontent__list-table-body-status">
                {{ item.total_price }}
              </p>
            </div>
          </div>
          <div class="totalsalescontent__list-table-body-user_no-data" v-else>
            <p class="totalsalescontent__list-table-body-user_no-data-text">
              Tidak ada data
            </p>
          </div>
        </div>
      </div>
      <div class="totalsalescontent__list-pagination">
        <!-- Go Back -->
        <div class="totalsalescontent__list-pagination-chevron" @click="setCurrentPage(paginationOption.currentPage - 1)"
          v-if="paginationOption.currentPage - 1 > 0">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
          </svg>
        </div>

        <div class="totalsalescontent__list-pagination-first" v-if="paginationOption.currentPage > 2">
          <p class="totalsalescontent__list-pagination-number" @click="setCurrentPage(1)">
            1
          </p>
          <p class="totalsalescontent__list-pagination-number" v-if="paginationOption.currentPage > 3">
            . . .
          </p>
        </div>

        <!-- Previous Page -->
        <p class="totalsalescontent__list-pagination-number" v-if="paginationOption.currentPage - 1 > 0"
          @click="setCurrentPage(paginationOption.currentPage - 1)">
          {{ paginationOption.currentPage - 1 }}
        </p>

        <!-- Current Page -->
        <p class="totalsalescontent__list-pagination-number totalsalescontent__list-pagination-number_bold">
          {{ paginationOption.currentPage }}
        </p>

        <!-- Next Page -->
        <p class="totalsalescontent__list-pagination-number"
          v-if="paginationOption.currentPage + 1 < paginationOption.totalPage"
          @click="setCurrentPage(paginationOption.currentPage + 1)">
          {{ paginationOption.currentPage + 1 }}
        </p>

        <div class="totalsalescontent__list-pagination-first"
          v-if="paginationOption.currentPage < paginationOption.totalPage">
          <p class="totalsalescontent__list-pagination-number" v-if="paginationOption.currentPage + 3 <= paginationOption.totalPage
            ">
            . . .
          </p>
          <p class="totalsalescontent__list-pagination-number" @click="setCurrentPage(paginationOption.totalPage)">
            {{ paginationOption.totalPage }}
          </p>
        </div>

        <!-- Go Next -->
        <div class="totalsalescontent__list-pagination-chevron" @click="setCurrentPage(paginationOption.currentPage + 1)"
          v-if="paginationOption.currentPage < paginationOption.totalPage">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </div>
      </div>
    </div>
    <LoadingVue v-if="loading" />
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import moment from "moment";
import "vue2-datepicker/index.css";
import LoadingVue from "@/components/molecules/Loading.vue";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "NavigationBar",
  components: {
    DatePicker,
    LoadingVue,
  },
  data() {
    return {
      loading: false,
      dateRange: null,
      dailyOmset: null,
      totalOmset: null,
      transactionList: [],
      resultSearch: null,
      searchInput: null,
      selectedFilter: null,
      filterOpened: false,
      filterList: [
        {
          name: "Transaction Status",
          code: "status",
        },
        {
          name: "Transaction Id",
          code: "id",
        },
      ],
      paginationOption: {
        limitPage: 10000,
        totalPage: 1,
        currentPage: 1,
      },

      selectedSort: {
        name: "Transaction Status",
        order_by: "status",
        sortAsc: false,
      },
      sortOpened: false,
      sortList: [
        {
          name: "Transaction Status",
          order_by: "status",
          sortAsc: false,
        },
        {
          name: "Transaction Id",
          order_by: "id",
          sortAsc: false,
        },
        {
          name: "Date",
          order_by: "created_at",
          sortAsc: false,
        },
      ],
      statusAddModal: false,
      statusModalConfirmation: false,
      statusModalConfirmationDelivery: false,
      detailTransaction: {},
      type: "preview",
      buttonHovered: false,
      totalPage: null,
      savedId: null,
    };
  },
  mounted() {
    this.getTransactionList();
    this.setInitialFilter();
  },
  watch: {
    dateRange() {
      this.getTransactionList();
    },
  },
  methods: {
    moment,

    setDetailTransaction(trx) {
      this.detailTransaction = trx;
      this.setType("preview");
      this.showAddModal();
    },
    setInitialFilter() {
      this.selectedFilter = this.filterList[0];
    },
    setInitialSort() {
      this.selectedSort = this.sortList[0];
    },
    setSelectedFilter(params) {
      this.selectedFilter = params;
    },
    setSelectedSort(params) {
      if (this.selectedSort.order_by === params.order_by) {
        this.selectedSort.sortAsc = !this.selectedSort.sortAsc;
      } else {
        this.selectedSort = params;
      }

      this.getTransactionList();
    },
    showModalConfirmation(id) {
      this.savedId = id;
      this.statusModalConfirmation = true;
    },
    hideModalConfirmation() {
      this.statusModalConfirmation = false;
    },
    showModalConfirmationDelivery(id) {
      this.savedId = id;
      this.statusModalConfirmationDelivery = true;
    },
    hideModalConfirmationDelivery() {
      this.statusModalConfirmationDelivery = false;
    },
    showAddModal() {
      this.statusAddModal = true;
    },
    hideAddModal() {
      this.statusAddModal = false;
    },
    setType(val) {
      this.type = val;
    },
    formatDate(date) {
      return moment(date)
        .utcOffset("+07:00")
        .format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
    },
    async getTransactionList() {
      try {
        this.loading = true;
        const params = {
          // date: moment(new Date()).format("YYYY-MM-DD"),
          limit: this.paginationOption.limitPage,
          start_date: this.dateRange?.[0]
            ? this.formatDate(new Date(this.dateRange?.[0]))
            : "",
          end_date: this.dateRange?.[1]
            ? this.formatDate(new Date(this.dateRange?.[1]))
            : "",
          offset:
            (this.paginationOption.currentPage - 1) *
            this.paginationOption.limitPage,
          // order_by: {
          //   created_at: "desc",
          // },
          // order_sort: this.selectedSort.sortAsc ? "asc" : "desc",
        };
        const response = await this.ApiGo({
          url: "api/transactions/all-data",
          formData: params,
        }).post();
        this.dailyOmset = response?.body?.data?.daily_omset;
        this.totalOmset = response?.body?.data?.total_omset;
        this.paginationOption.totalPage = 1
        this.totalPage = this.paginationOption.totalPage;
        this.transactionList = response.body.data.total_sales;
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      } finally {
        this.loading = false;
      }
    },
    async confirmTransaction() {
      try {
        await this.ApiGo({
          url: `api/transactions/payment/${this.savedId}`,
          formData: {
            status: "success",
          },
        }).put();
        this.getTransactionList();
        this.$toast.success("Transaksi berhasil disetujui");
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      } finally {
        this.hideModalConfirmation();
      }
    },
    findTransactionStatus() {
      const trx = this.transactionList.filter((dt) => dt.id === this.savedId);
      return trx?.[0].status;
    },
    async confirmDelivery() {
      if (this.findTransactionStatus() !== "PAID") {
        this.$toast.error("Mohon menyetujui transaksi ini terlebih dahulu");
        return
      }

      try {
        await this.Api({
          url: `api/rest/transactions/${this.savedId}/sent/package`,
          formData: {
            sent: true,
          },
        }).patch();
        this.getTransactionList();
        this.$toast.success("Pengiriman status berhasil diupdate");
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      } finally {
        this.hideModalConfirmationDelivery();
      }
    },
    async deleteTransaction(id) {
      try {
        const response = await this.Api({
          url: `api/rest/roles/${id}`,
        }).delete();

        if (response) {
          this.$toast.success("Produk berhasil dihapus");
          this.getTransactionList();
        }
      } catch {
        this.$toast.error("Gagal menghapus produk");
      }
    },
    getTotalPage(totalData) {
      this.paginationOption.totalPage = Math.ceil(
        totalData / this.paginationOption.limitPage
      );
    },
    searchData() {
      const lowerCaseinput = this.searchInput?.toLowerCase();
      if (lowerCaseinput) {
        this.resultSearch = this.transactionList.filter((dt) =>
          String(dt[this.selectedFilter.code])
            .toLowerCase()
            .includes(lowerCaseinput)
        );
        this.paginationOption.totalPage = 1;
      } else {
        this.resultSearch = null;
        this.paginationOption.totalPage = this.totalPage;
      }
    },
    toggleFilter() {
      this.filterOpened = !this.filterOpened;
    },
    closeFilter() {
      this.filterOpened = false;
    },
    toggleSort() {
      this.sortOpened = !this.sortOpened;
    },
    closeSort() {
      this.sortOpened = false;
    },
    setCurrentPage(page) {
      this.paginationOption.currentPage = page;
      this.getTransactionList();
    },
    resetSortList() {
      this.sortList.forEach((dt) => {
        dt.sortAsc = true;
      });
    },
    onTransactionCreated() {
      this.hideAddModal();
      this.hideModalConfirmationDelivery();
      this.getTransactionList();
    },
  },
  computed: {
    validData() {
      return this.resultSearch ? this.resultSearch : this.transactionList;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style>
@import "./transactioncontent.css";
</style>
