// import axios from "axios";

export default function auth(to, from, next) {
  if (!localStorage.getItem("token-jwt")) {
    next("/mlm-admin");
    // axios
    //   .get("/profile", {
    //     headers: {
    //       'token': localStorage.getItem("token-jwt")
    //     }
    //   })
    //   .then(() => {
    //     next();
    //   })
    //   .catch(() => {
    //     next("/mlm-admin");
    //   });
  } else {
    next();
  }
}
