import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueResource from "vue-resource";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Mixin from "./mixin";
import VueCarousel from 'vue-carousel';
import axios from 'axios';
import VueFab from 'vue-float-action-button'

const options = {};

axios.defaults.baseURL = process.env.VUE_APP_API;

const EventBus = new Vue()

Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function() {
      return EventBus
    }
  }
})
Vue.use(VueFab, /* {
  ----------------------
  // opitons Optional Alibaba iconfont icon or MaterialIcons
  iconType: 'MaterialDesign'
  // iconType: 'iconfont'
} */)
Vue.use(VueCarousel);
Vue.use(Toast, options);
Vue.use(VueResource);
Vue.mixin(Mixin);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
