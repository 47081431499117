var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header_area"},[_c('div',{staticClass:"main_menu",attrs:{"id":"mainNav"}},[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light"},[_c('div',{staticClass:"container box_1620"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"collapse navbar-collapse offset",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"nav navbar-nav menu_nav ml-auto"},[_c('li',{staticClass:"nav-item active"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/"}},[_vm._v("Beranda")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/about"}},[_vm._v("Tentang")])],1),_c('li',{staticClass:"nav-item submenu dropdown"},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"href":"#","data-toggle":"dropdown","role":"button","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v("Produk")]),_c('ul',{staticClass:"dropdown-menu"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/productslist"}},[_vm._v("Loadmore....")])],1)])]),_vm._m(6),_vm._m(7),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/contact"}},[_vm._v("Kontak")])],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"navbar-brand logo_h",attrs:{"href":"/"}},[_c('img',{staticClass:"img_brand_logo",staticStyle:{"width":"130px"},attrs:{"src":require("@/assets/ghani-icon.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/products/spirulina-fit"}},[_vm._v("SPIRULINA FIT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/products/obajan"}},[_vm._v("OBAJAN")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/products/habbatplus-19"}},[_vm._v("HABBATPLUS 19")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/products/propolis-plus"}},[_vm._v("PROPOLIS PLUS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item submenu dropdown"},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"href":"#","data-toggle":"dropdown","role":"button","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v("Marketing Plan")]),_c('ul',{staticClass:"dropdown-menu"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/commissions/komisi-penjualan-awal"}},[_vm._v("KOMISI PENJUALAN AWAL ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/commissions/komisi-kepemimpinan"}},[_vm._v("KOMISI KEPEMIMPINAN ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/commissions/komisi-generasi"}},[_vm._v("KOMISI GENERASI ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/commissions/komisi-apresiasi"}},[_vm._v("KOMISI APRESIASI (Berulang) ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/commissions/komisi-belanja-ulang"}},[_vm._v("KOMISI BELANJA ULANG ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/commissions/komisi-penjualan-belanja-ulang"}},[_vm._v("KOMISI PENJUALAN BELANJA ULANG ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/commissions/komisi-generasi-belanja-ulang"}},[_vm._v("KOMISI GENERASI BELANJA ULANG ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item submenu dropdown"},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"href":"#","data-toggle":"dropdown","role":"button","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v("Transparansi")]),_c('ul',{staticClass:"dropdown-menu"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/commissions/cashback"}},[_vm._v("CASHBACK ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/commissions/reward"}},[_vm._v("REWARD ")])])])])
}]

export { render, staticRenderFns }