<template>
  <div class="main">
    <Header></Header>
    <Hero></Hero>

    <!--================About Area =================-->
    <div class="whole-wrap" v-for="data in compro" :key="data.id">
      <div class="container">
        <div class="section-top-border">
          <h3 class="mb-30 title_color">{{ data.name }}</h3>
          <div class="row">
            <div class="col-md-3 mb-3">
              <img
                v-bind:src="getGoogleDriveImage(data.image_url)"
                alt=""
                style="width: 600px; height: 300px"
                class="img-fluid"
              />
            </div>
            <div class="col-md-9 mt-sm-20 left-align-p">
              <h4>Salam sukses para sahabat GHANI !</h4>
              <p>
                {{ data.description }} 
                <br><br>
              </p>
              <h4> Kenapa harus GHANI ?</h4>
              <p>Karena di GHANI hanya dengan Rp 300,000 jadikan Manfaat lain dari Nilai tersebut Berniaga dengan menjadi 
                Mitra GHANI Banyak keuntungan BERLIMPAH</p>

            </div>
          </div>
         
        </div>
        <div class="section-top-border">
          <h3 class="mb-30 title_color">VISI DAN MISI GHANI</h3>
          <div class="row">
            <div class="col-md-6">
              <div class="single-defination">
                <h4 class="mb-20">VISI</h4>
                <p>
                  Menjadi Perusahaan Direct Selling Syariah yang memiliki
                  komitmen sebagai solusi kesehatan & keuangan bagi masyarakat
                  melalui produk-produk herbal yang bermutu dan berkualitas.
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="single-defination">
                <h4 class="mb-20">MISI</h4>
                <p>
                  Menciptakan para pengusaha sukses yang mandiri, profesional &
                  berakhlak mulia.
                </p>
              </div>
            </div>
          </div>
          
        </div>
        <h4 class="mb-3 title_color">MOTTO GHANI</h4>
              <p class="unordered-list">
                  <b><span>“SEHAT BERKAH BERLIMPAH”</span></b>
              </p>

        <div class="section-top-border">
          <h3 class="mb-30 title_color">Alamat</h3>
          <div class="row">
            <div class="col-lg-12">
              <blockquote class="generic-blockquote">
                {{ data.address }}
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--================About Area END =================-->

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/compro/Header.vue";
import Hero from "@/components/compro/Hero.vue";
import Footer from "@/components/compro/Footer.vue";
// import axios from "axios";

export default {
  name: "AboutPage",
  components: {
    Header,
    Hero,
    Footer,
  },
  data() {
    return {
      compro: [],
      err: "",
    };
  },
  mounted() {
    this.fetchCompro();
  },
  methods : {
    async fetchCompro() {
      try {
        const params = {
            limit : 10,
            offset : 0,
            order_by: {
              id : 'asc'
            }
        };
        const response = await this.ApiCompro({
          url: "api/rest/compro/companies",
          formData: params,
        }).post();
        this.compro = response.data.company_companies;
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
  }

};
</script>

<style>
</style>
