<template>
  <div class="maintenance">
    <div class="maintenance__container">
      <img
        src="@/assets/admin/images/maintenance.jpg"
        alt="search"
        class="maintenance__image"
      />
      <h1 class="maintenance__text">We are Under Maintenance</h1>
      <h3 class="maintenance__text">Will be Back Soon!</h3>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.checkMaintenance();
  },
  methods: {
    async checkMaintenance() {
      const response = await this.Api({
        url: "api/rest/maintenance",
      }).get();
      const isMaintenance = response?.body?.maintenance?.[0]?.is_on_maintenance;

      if (!isMaintenance) {
        this.$router.back()
      }
    },
  },
};
</script>

<style scoped>
.maintenance {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
}

.maintenance__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.maintenance__image {
  width: 35%;
}

.maintenance__text {
  color: #355fa0;
}
</style>
