<template>
  <section class="our_blog_area p_120">
    <!-- <div class="container"> -->
      <!-- <div class="row" v-for="data in compro" :key="data.id"> -->
        <!-- <div class="row"> -->
        <!-- <div class="col-lg-7">
          <img src="@/assets/asset.png" alt="" style="width: 500px;">
          
        </div> -->
        <div class="row">
          <div class="col-2"></div>
          <div class="col-8" style="text-align: center; " >
          <br>
          <br>
          <h1 style="color: white;">Dengan Menjadi Mitra GHANI Kamu Bisa Mendapatkan Penghasilan Yang Tak Terbatas, Serta Didukung Dengan Produk Berkualitas</h1>
          <br>
            <!-- <a
              class="genric-btn primary-border radius e-large"
              v-bind:href="
                'https://api.whatsapp.com/send?phone=' +
                data.phone +
                '&text=Hello,%20' +
                data.name +
                '!'
              "
              target="_blank"
              >Konsultasi</a
            > -->
        </div>
        <div class="col-2"></div>
        </div>
        
      <!-- </div> -->
    <!-- </div> -->
    <!-- <div class="container">
      <div class="main_title">
        <h2>Berita Terbaru</h2>
        <p>
          Berita terbaru tentang produk kami: sukses besar dengan penjualan yang
          memuaskan dan respons positif dari pelanggan.
        </p>
      </div>
      <div class="blog_inner row">
        <div class="col-lg-4" v-for="data in news" :key="data.id">
          <div class="o_blog_item">
            <div class="blog_img">
              <img
                class="img-fluid"
                v-bind:src="getGoogleDriveImage(data.cover_url)"
                alt=""
                style="width: 360px; height: 260px"
              />
            </div>
            <div class="blog_text">
              <div class="blog_cat"></div>
              <a v-bind:href="'/news/' + data.slug">
                <h4>{{ data.title }}</h4>
              </a>
              <p class="">
                {{ data.description.substring(0, 100) + "......" }}
              </p>
              <a class="date" v-bind:href="'/news/' + data.slug">{{
                moment(data.created_at).format("DD-MM-YYYY")
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </section>
</template>

<script>
// import moment from "moment";
export default {
  name: "Berita",
  // data() {
  //   return {
  //     compro: [],
  //     err: "",
  //   };
  // },
  // mounted() {
  //   this.fetchCompro();
  // },

  // methods: {
  //   async fetchCompro() {
  //     try {
  //       const params = {
  //         limit: 10,
  //         offset: 0,
  //         order_by: {
  //           id: "asc",
  //         },
  //       };
  //       const response = await this.ApiCompro({
  //         url: "api/rest/compro/companies",
  //         formData: params,
  //       }).post();
  //       this.compro = response.data.company_companies;
  //     } catch (err) {
  //       this.$toast.error("Maaf terjadi kesalahan");
  //     }
  //   },
  // },
};
  // data() {
  //   return {
  //     moment: moment,
  //     news: [],
  //     err: "",
  //   };
  // },
  // mounted() {
  //   this.fetchNews();
  // },
  // methods: {
  //   async fetchNews() {
  //     try {
  //       const params = {
  //         limit: 10,
  //         offset: 0,
  //         order_by: {
  //           id: "asc",
  //         },
  //       };
  //       const response = await this.ApiCompro({
  //         url: "api/rest/compro/news",
  //         formData: params,
  //       }).post();
  //       this.news = response.data.company_news;
  //     } catch (err) {
  //       this.$toast.error("Maaf terjadi kesalahan");
  //     }
  //   },
  // },
// };
</script>
<style></style>
