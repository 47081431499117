<template>
  <div class="dashboardmlm">
    <NavigationBarVue :available-page="roleList?.page"></NavigationBarVue>
    <HeaderVue :name="detailUser?.name"></HeaderVue>
    <div class="dashboardmlm__body">
      <p class="dashboardmlm__body-title">{{ pageName }}</p>
      <div>
        <UserContent v-if="pageName === 'User'"></UserContent>
        <ProductContent v-if="pageName === 'Product'"></ProductContent>
        <ScopeContent v-if="pageName === 'Scope'"></ScopeContent>
        <RoleContent v-if="pageName === 'Role'"></RoleContent>
        <LevelContent v-if="pageName === 'Level'"></LevelContent>
        <CommissionsContent v-if="pageName === 'Commissions'"></CommissionsContent>
        <CompanyContent v-if="pageName === 'CompanyProfile'"></CompanyContent>
        <ContactsContent v-if="pageName === 'Contacts'"></ContactsContent>
        <NewsContent v-if="pageName === 'News'"></NewsContent>
        <TransactionContent v-if="pageName === 'Transaction'"></TransactionContent>
        <NetworkTreeContent v-if="pageName === 'Network Tree Unit'"></NetworkTreeContent>
        <NetworkTreeReferralContentVue v-if="pageName === 'Network Tree Referral'"></NetworkTreeReferralContentVue>
        <KomisiPenjualanAwalContentVue v-if="pageName === 'Penjualan Awal'"></KomisiPenjualanAwalContentVue>
        <KomisiKepemimpinanContentVue v-if="pageName === 'Kepemimpinan'"></KomisiKepemimpinanContentVue>
        <CashbackContentVue v-if="pageName === 'Cashback'"></CashbackContentVue>
        <RoyaltiKepemimpinanContentVue v-if="pageName === 'R. Kepemimpinan'"></RoyaltiKepemimpinanContentVue>
        <BonusUserContentVue v-if="pageName === 'Bonus User'"></BonusUserContentVue>
        <AdminPanelContent v-if="pageName === 'Admin Panel'"></AdminPanelContent>
        <RepeatOrderKomisi v-if="pageName === 'RO Komisi'"></RepeatOrderKomisi>
        <RepeatOrderCashback v-if="pageName === 'RO Cashback'"></RepeatOrderCashback>
        <RepeatOrderRoyalty v-if="pageName === 'RO Royalty'"></RepeatOrderRoyalty>
        <TotalSalesContent v-if="pageName === 'Total Sales'"></TotalSalesContent>
      </div>
    </div>
  </div>
</template>

<script>
import roleJson from "@/assets/admin/role.json";
import NavigationBarVue from "@/components/admin/NavigationBar/NavigationBar.vue";
import HeaderVue from "@/components/admin/Header/Header.vue";
import ProductContent from "@/components/admin/ProductContent/ProductContent.vue";
import ScopeContent from "@/components/admin/ScopeContent/ScopeContent.vue";
import RoleContent from "@/components/admin/RoleContent/RoleContent.vue";
import LevelContent from "@/components/admin/LevelContent/LevelContent.vue";
import UserContent from "@/components/admin/UserContent/UserContent.vue";
import CommissionsContent from "@/components/admin/ComproContent/Comissions/CommissionsContent.vue";
import CompanyContent from "@/components/admin/ComproContent/CompanyProfile/CompanyContent.vue";
import ContactsContent from "@/components/admin/ComproContent/Contacts/ContactsContent.vue";
import NewsContent from "@/components/admin/ComproContent/News/NewsContent.vue";
import TransactionContent from "@/components/admin/TransactionContent/TransactionContent.vue";
import NetworkTreeContent from "@/components/admin/NetworkTreeContent/NetworkTreeContent.vue";
import NetworkTreeReferralContentVue from "@/components/admin/NetworkTreeReferralContent/NetworkTreeReferralContent.vue";
import KomisiPenjualanAwalContentVue from "@/components/admin/KomisiPenjualanAwalContent/KomisiPenjualanAwalContent.vue";
import KomisiKepemimpinanContentVue from "@/components/admin/KomisiKepemimpinanContent/KomisiKepemimpinanContent.vue";
import CashbackContentVue from "@/components/admin/CashbackContent/CashbackContent.vue";
import RoyaltiKepemimpinanContentVue from "@/components/admin/RoyaltiKepemimpinanContent/RoyaltiKepemimpinanContent.vue";
import BonusUserContentVue from "@/components/admin/BonusUserContent/BonusUserContent.vue";
import AdminPanelContent from "@/components/admin/AdminPanelContent/AdminPanelContent.vue";
import RepeatOrderKomisi from "@/components/admin/RepeatOrderKomisi/RepeatOrderKomisi.vue";
import RepeatOrderCashback from "@/components/admin/RepeatOrderCashback/RepeatOrderCashback.vue";
import RepeatOrderRoyalty from "@/components/admin/RepeatOrderRoyalty/RepeatOrderRoyalty.vue";
import TotalSalesContent from "@/components/admin/TotalSalesContent/TotalSalesContent.vue";

export default {
  name: "DashboardView",
  components: {
    NavigationBarVue,
    HeaderVue,
    UserContent,
    ProductContent,
    ScopeContent,
    RoleContent,
    LevelContent,
    CommissionsContent,
    CompanyContent,
    ContactsContent,
    NewsContent,
    TransactionContent,
    NetworkTreeContent,
    NetworkTreeReferralContentVue,
    KomisiPenjualanAwalContentVue,
    KomisiKepemimpinanContentVue,
    CashbackContentVue,
    RoyaltiKepemimpinanContentVue,
    BonusUserContentVue,
    AdminPanelContent,
    RepeatOrderKomisi,
    RepeatOrderCashback,
    RepeatOrderRoyalty,
    TotalSalesContent
  },
  data() {
    return {
      pageName: "Dashboard",
      detailUser: null,
      roleList: null,
      pagePermission: false,
    };
  },
  async mounted() {
    await this.checkMaintenance();
    await this.getProfile();
    this.getPageName();
    this.setRole();
    this.checkRole();
  },
  watch: {
    async $route() {
      await this.checkMaintenance();
      this.getPageName();
    },
  },
  methods: {
    async checkMaintenance() {
      const response = await this.Api({
        url: "api/rest/maintenance",
      }).get();
      const isMaintenance = response?.body?.maintenance?.[0]?.is_on_maintenance;

      if (isMaintenance) {
        this.$router.push("/maintenance");
      }
    },
    setRole() {
      roleJson.forEach((dt) => {
        if (dt?.name === this.detailUser?.name) {
          this.roleList = dt;
        }
      });
    },
    checkRole() {
      this.pagePermission = false;
      this.roleList?.page.forEach((dt) => {
        if (dt === "all" || this.pageName === "Dashboard") {
          this.pagePermission = true;
        } else {
          if (dt === this.pageName) {
            this.pagePermission = true;
          }
        }
      });

      if (this.pagePermission === false) {
        this.$router.push(
          `dashboard?type=${this.roleList?.page?.[0] || "Dashboard"}`,
          () => { }
        );
      }
    },
    getPageName() {
      if (!this.$route.query.type) {
        this.pageName = "Dashboard";
        return;
      }

      this.pageName = this.$route.query.type;
    },
    async getProfile() {
      const userId = localStorage.getItem("user-id");
      try {
        const response = await this.Api({
          url: `api/rest/users/${userId}`,
        }).get();
        this.detailUser = response?.body?.users[0];
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
  },
};
</script>

<style>
@import "./dashboard.css";
</style>
