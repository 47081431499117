<template>
  <div class="main">
    <Header></Header>
    <Hero></Hero>

    <!--================Contact Area =================-->
    <section class="contact_area p_120">
      <div class="container">
        <div id="mapBox" class="mapBox">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.3679786339208!2d106.93040337557196!3d-6.3463726936434455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e699322d5e90995%3A0xd6629c1b0691d3d8!2sJl.%20Raya%20Mess%20Al%20No.49%2C%20RT.001%2FRW.001%2C%20Jatiranggon%2C%20Kec.%20Jatisampurna%2C%20Kota%20Bks%2C%20Jawa%20Barat%2017432!5e0!3m2!1sen!2sid!4v1684924923017!5m2!1sen!2sid" 
          width="100%" 
          height="450px" 
          style="border:0; border-radius: 23px"  
          allowfullscreen="" 
          ></iframe>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <div class="contact_info">
              <div class="info_item">
                <i class="lnr lnr-home"></i>
                <h6>Jl. Raya Mess Al No.49, Rt.004/ Rw.005, Kel. Jatiraden, Kec. Jatisampurna</h6>
                <p>Kota Bekasi - Jawa Barat 17434</p>
              </div>
              <div class="info_item">
                <i class="lnr lnr-phone-handset"></i>
                <h6><a href="https://wa.me/message/JMT7LYSMJ5UKG1">+6281370707885</a></h6>
                <p>Mon to Fri 9am to 6 pm</p>
              </div>
              <div class="info_item">
                <i class="lnr lnr-envelope"></i>
                <h6><a href="#">support@ghanicenter.com</a></h6>
                <p>Kirim kita email sekarang !</p>
              </div>
            </div>
          </div>
          <div class="col-lg-9">
            <form
              class="row contact_form"
              v-on:submit.prevent="submitForm"
              id="contactForm"
              novalidate="novalidate"
            >
              <div class="col-md-6">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    name="name"
                    placeholder="Enter your name"
                    v-model="inputData.name"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    name="email"
                    placeholder="Enter email address"
                    v-model="inputData.email"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    id="subject"
                    name="subject"
                    placeholder="Enter Subject"
                    v-model="inputData.subject"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <textarea
                    class="form-control"
                    name="message"
                    id="message"
                    rows="1"
                    placeholder="Enter Message"
                    v-model="inputData.message"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-12 text-right">
                <button type="submit" value="submit" class="btn submit_btn">
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!--================Contact Area END =================-->

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/compro/Header.vue";
import Hero from "@/components/compro/Hero.vue";
import Footer from "@/components/compro/Footer.vue";
// import axios from "axios";

export default {
  name: "KontakPage",
  components: {
    Header,
    Hero,
    Footer,
  },
  data() {
    return {
      inputData: {
        name: null,
        email: null,
        subject: null,
        message: null,
      },
    };
  },
  methods: { 
    async submitForm() {
      if (!this.inputData.name) {
        this.$toast.error("nama wajib diisi");
        return;
      }

      if (!this.inputData.email) {
        this.$toast.error("email wajib diisi");
        return;
      }

      if (!this.inputData.subject) {
        this.$toast.error("subject wajib diisi");
        return;
      }

      if (!this.inputData.message) {
        this.$toast.error("message wajib diisi");
        return;
      }
      try {
        await this.ApiCompro({
          url: "api/rest/compro/contacts/create",
          formData: {company_contact : this.inputData},
        }).post();
        this.$toast.success("Data terkirim ke email");
      } catch(err){
        this.$toast.error("Maaf terjadi gagal mengirim email");
        console.log(err)
      }
    },
  }

};
</script>

<style>
</style>