<template>
  <div>
    <div class="uploadimage__box-row">
      <p class="uploadimage__box-row-title">Gambar {{ title }}</p>
      <div class="uploadimage__box-row-image-box">
        <p class="uploadimage__box-row-image-box-text">
          Tekan untuk unggah
          <span class="uploadimage__box-row-image-box-text_normal"
            >atau seret file ke sini</span
          >
        </p>
        <input
          class="uploadimage__box-row-image-box-file"
          type="file"
          accept="image/*"
          multiple="multiple"
          @change="fileChange"
        />
      </div>
    </div>
    <div class="uploadimage__preview">
      <div
        class="uploadimage__preview-box"
        v-for="(dt, index) in imageList"
        :key="index"
      >
        <div class="uploadimage__preview-box-image">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            id="document"
          >
            <path
              d="M20,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19l-.09,0L13.06,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V9S20,9,20,8.94ZM14,5.41,16.59,8H14ZM18,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4h5V9a1,1,0,0,0,1,1h5Z"
            ></path>
          </svg>
        </div>
        <p
          class="uploadimage__preview-box-text"
          @click="openPreviewImage(index)"
        >
          {{ dt.name ? dt.name : "image " + (index + 1) }}
        </p>
        <div class="uploadimage__preview-box-close" @click="deleteImage(index)">
          <img src="@/assets/admin/icon/red-close.svg" alt="close" />
        </div>
      </div>
    </div>
    <LoadingVue v-if="loading" />
    <PreviewImageVue
      v-if="previewImageStatus"
      :data="previewImageData"
      @closeModal="closePreviewImage"
    />
  </div>
</template>

<script>
import LoadingVue from "./Loading.vue";
import PreviewImageVue from "./PreviewImage.vue";

export default {
  components: {
    LoadingVue,
    PreviewImageVue,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    initialImage: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imageList: [],
      imageUrl: [],
      loading: false,
      previewImageStatus: false,
      previewImageData: null,
    };
  },
  created() {
    this.$bus.$on("check-image-list", () => {
      if (this.imageList.length === 0) {
        this.$bus.$emit("sent-image-list-checked", false);
      } else {
        this.$bus.$emit("sent-image-list-checked", true);
      }
    });
    this.$bus.$on("process-upload-image", async () => {
      if (this.imageList.length === 0) {
        return this.$toast.error("Gambar tidak boleh kosong");
      }
      this.loading = true;
      for (let i = 0; i < this.imageList.length; i++) {
        if (this.imageList[i].name.includes("http")) {
          this.imageUrl.push({ image_url: this.imageList[i].name });
        } else {
          await this.uploadImage(this.imageList[i]);
        }
      }

      this.loading = false;
      this.$bus.$emit("image-data", this.imageUrl);
    });
  },
  beforeDestroy() {
    this.$bus.$off("check-image-list");
    this.$bus.$off("process-upload-image");
  },
  watch: {
    initialImage() {
      this.setInitialImage();
    },
  },
  mounted() {
    this.setInitialImage();
  },
  methods: {
    openPreviewImage(index) {
      this.previewImageStatus = true;
      const dataFile = this.imageList[index];
      if (typeof dataFile === "object" && dataFile !== null) {
        if (dataFile instanceof File) {
          this.readUrlOrFileImage(dataFile, "file");
        } else {
          this.readUrlOrFileImage(dataFile.name);
        }
      }
    },
    closePreviewImage() {
      this.previewImageStatus = false;
    },
    setInitialImage() {
      this.imageList = []
      if (this.initialImage.length > 0 && this.initialImage[0]) {
        this.initialImage.forEach((dt) => {
          this.imageList.push({
            name: dt,
          });
        });
      }
    },
    readUrlOrFileImage(file, type = "") {
      if (type !== "file") {
        if (file.includes("http")) {
          this.previewImageData = this.getGoogleDriveImage(file);
          return;
        }
      }

      const _this = this;
      const reader = new FileReader();

      reader.onload = function (e) {
        _this.previewImageData = e.target.result;
      };

      reader.readAsDataURL(file);
      return;
    },
    async fileChange(file) {
      if (this.imageList.length + file.target.files.length > 3) {
        this.$toast.error("Maksimal 3 File");
        return;
      }
      for (let i = 0; i < file.target.files.length; i++) {
        this.imageList.push(file.target.files[i]);
      }
    },
    async uploadImage(file) {
      var formData = new FormData();
      formData.append("image", file);
      try {
        const response = await this.ApiGo({
          url: "api/upload-image",
          formData: formData,
        }).post();
        this.imageUrl.push({
          id: response.body.data.id,
          image_url: response.body.data.image_url,
        });
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    deleteImage(index) {
      this.imageList.splice(index, 1);
      this.imageUrl.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.uploadimage__box-row {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.uploadimage__box-row-title {
  font-size: 14px;
  line-height: 16px;
  color: #707880;
}

.uploadimage__box-row-image-box {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  border: 1px dashed black;
  border-radius: 8px;
}

.uploadimage__box-row-image-box-text {
  font-weight: bold;
}

.uploadimage__box-row-image-box-text_normal {
  font-weight: 400;
}

.uploadimage__box-row-image-box-file {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  opacity: 0;
}

.uploadimage__preview {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.uploadimage__preview-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.uploadimage__preview-box-image {
  width: 24px;
}

.uploadimage__preview-box-text {
  cursor: pointer;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uploadimage__preview-box-close {
  cursor: pointer;
}
</style>
