<template>
  <div class="invoice">
    <div class="loading__mask" @click="closeModal()"></div>
    <div id="invoice-page" class="invoice__body">
      <div
        style="
          display: flex;
          align-items: center;
          background-color: #f0effa;
          padding: 40px 80px;
        "
      >
        <div style="width: 33%">
          <img
            style="width: 95px"
            src="@/assets/admin/images/ghani-logo-small.png"
            alt="company image"
          />
        </div>

        <div style="display: flex; flex-direction: column; align-items: center">
          <p
            style="
              width: 33%;
              text-align: center;
              font-size: 24px;
              font-weight: bold;
            "
          >
            Tagihan
          </p>
          <span style="font-size: 11px">{{ invoiceId }}</span>
        </div>
        <p style="width: 33%; text-align: right">
          {{ moment(createdAt).format("DD MMM YYYY") }}
        </p>
      </div>
      <div style="padding: 32px 40px; background-color: white">
        <div style="border: 2px solid #929394"></div>
        <div style="padding: 32px 16px">
          <div style="display: flex; flex-direction: column">
            <p style="font-size: 20px; font-weight: bold; margin-bottom: 16px">
              Informasi Pelanggan
            </p>
            <div style="display: flex">
              <div style="width: 50%; margin-right: 8px">
                <div style="margin-bottom: 16px">
                  <p style="font-weight: bold; margin-bottom: 4px">ID Member</p>
                  <p>{{ userDetail?.code }}</p>
                </div>
                <div style="margin-bottom: 16px">
                  <p style="font-weight: bold; margin-bottom: 4px">Nama</p>
                  <p>{{ userDetail?.name }}</p>
                </div>
                <div style="margin-bottom: 16px">
                  <p style="font-weight: bold; margin-bottom: 4px">
                    Nomor Telepon
                  </p>
                  <p>{{ userDetail?.user_infos?.[0]?.phone }}</p>
                </div>
                <div style="margin-bottom: 16px">
                  <p style="font-weight: bold; margin-bottom: 4px">Email</p>
                  <p>{{ userDetail?.email }}</p>
                </div>
              </div>
              <div style="width: 50%; margin-right: 8px">
                <div style="margin-bottom: 16px">
                  <p style="font-weight: bold; margin-bottom: 4px">Provinsi</p>
                  <p>{{ userDetail?.user_infos?.[0]?.province }}</p>
                </div>
                <div style="margin-bottom: 16px">
                  <p style="font-weight: bold; margin-bottom: 4px">Kota</p>
                  <p>{{ userDetail?.user_infos?.[0]?.city }}</p>
                </div>
                <div style="margin-bottom: 16px">
                  <p style="font-weight: bold; margin-bottom: 4px">Kelurahan</p>
                  <p>{{ userDetail?.user_infos?.[0]?.district }}</p>
                </div>
                <div style="margin-bottom: 16px">
                  <p style="font-weight: bold; margin-bottom: 4px">Alamat</p>
                  <p>
                    {{ userDetail?.user_infos?.[0]?.address }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="border: 2px solid #929394; margin-bottom: 32px"></div>
        <table>
          <tr>
            <th class="table__header-name">Nama</th>
            <th class="table__header-price">Harga</th>
            <th class="table__header-pv">Point Value</th>
            <th class="table__header-qty">Kuantitas</th>
          </tr>
          <tr v-for="(item, index) in product" :key="index">
            <td class="table__data-name">{{ item?.product?.name }}</td>
            <td class="table__data-price">
              Rp. {{ formatNumber(item?.product?.member_price) }}
            </td>
            <td class="table__data-pv">
              {{ formatNumber(item?.product?.point_value) }}
            </td>
            <td class="table__data-qty">{{ formatNumber(item?.quantity) }}</td>
          </tr>
        </table>
        <p
          class="table__data-total"
          style="
            width: 100%;
            text-align: right;
            font-size: 24px;
            font-weight: bold;
            padding-top: 32px;
            background-color: white;
          "
        >
          Rp. {{ formatNumber(total) }}
        </p>
        <div style="border: 2px solid #929394; margin-top: 32px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";

export default {
  props: {
    userId: {
      type: String,
      default: null,
    },
    product: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: null,
    },
    createdAt: {
      type: String,
      default: null,
    },
    invoiceId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      moment: moment,
      userDetail: null,
    };
  },
  mounted() {
    moment.locale("id");
    this.getUserDetail();
  },
  // watch: {
  //   userId() {
  //     this.getUserDetail();
  //   },
  // },
  methods: {
    numberFormatter(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatAllPrice() {
      let priceElement = document.getElementsByClassName("table__data-price");
      for (let i = 0; i < priceElement.length; i++) {
        let priceValue = this.numberFormatter(
          Number(
            document.getElementsByClassName("table__data-price")[i].innerHTML
          )
        );
        document.getElementsByClassName("table__data-price")[i].innerHTML =
          "Rp " + priceValue;
      }

      let totalValue = this.numberFormatter(
        Number(
          document.getElementsByClassName("table__data-total")[0].innerHTML
        )
      );
      document.getElementsByClassName("table__data-total")[0].innerHTML =
        "Total: Rp " + totalValue;
    },
    async getUserDetail() {
      try {
        const response = await this.Api({
          url: `api/rest/users/${this.userId}`,
        }).get();

        if (response) {
          this.userDetail = response?.body?.users?.[0];
          setTimeout(() => {
            this.exportToPDF();
          }, 500);
        }
      } catch (err) {
        this.$toast.error("Gagal mendapatkan informasi user");
      }
    },
    exportToPDF() {
      try {
        html2pdf(document.getElementById("invoice-page"), {
          margin: 1,
          filename: "invoice.pdf",
        });
        this.$toast.success("PDF berhasil di download");
      } catch {
        this.$toast.error("Gagal mendownload PDF");
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style>
.invoice {
  position: fixed;
  display: flex;
  overflow-y: scroll;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
.invoice__body {
  max-width: 800px;
  width: 800px;
  min-height: 1000px;
  height: fit-content;
  margin: 0 auto;
  /* border: 1px solid #ccc; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
  z-index: 9;
}

* {
  margin: 0px;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.table__header-name {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  width: 30%;
  word-wrap: break-word;
  word-break: break-all;
}

.table__header-price {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  width: 30%;
  word-wrap: break-word;
  word-break: break-all;
}

.table__header-pv {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  width: 25%;
  word-wrap: break-word;
  word-break: break-all;
}

.table__header-qty {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  width: 10%;
  word-wrap: break-word;
  word-break: break-all;
}

.table__data-name {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  width: 25%;
  word-wrap: break-word;
  word-break: break-all;
}

.table__data-price {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  width: 25%;
  word-wrap: break-word;
  word-break: break-all;
}

.table__data-pv {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  width: 25%;
  word-wrap: break-word;
  word-break: break-all;
}

.table__data-qty {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  width: 25%;
  word-wrap: break-word;
  word-break: break-all;
}

th {
  background-color: #f2f2f2;
}

.loading__mask {
  position: fixed;
  background: black;
  opacity: 75%;
  width: 100vw;
  height: 100vh;
}
</style>
