<template>
  <section class="testimonials_area p_120">
    <div class="container">
      <div class="main_title_komisi">
        <h2>Komisi yang kita berikan</h2>
        <p>
          Komisi menarik tersedia untuk Anda! Bergabunglah dengan kami dan
          dapatkan penghasilan tambahan yang signifikan.
        </p>
      </div>
      <div class="row">
        <div class="col-3">
          <img src="@/assets/KOMISI PENJUALAN AWAL (1).svg" alt="" style="display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;">
          <br>
          <h4 style="text-align: center; color:#f8b600;">KOMISI</h4>
          <h4 style="text-align: center; color:#f8b600;">PENJUALAN AWAL</h4>
          <a class="genric-btn primary-border" href="/commissions/komisi-penjualan-awal" style="margin-left: auto;" >Pejalari Lebih Lanjut</a>
        </div>
        <div class="col-3">
          <img src="@/assets/KOMISI KEPEMIMPINAN (1).svg" alt="" style="display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;">
          <br>
          <h4 style="text-align: center; color:#f8b600;">KOMISI KEPEMIMPINAN</h4>
          <h4 style="text-align: center; color:#f8b600;">KEPEMIMPINAN</h4>
          <a class="genric-btn primary-border" href="/commissions/komisi-kepemimpinan" >Pejalari Lebih Lanjut</a>
        </div>
        <div class="col-3">
          <img src="@/assets/KOMISI GENERASI.svg" alt="" style="display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;">
          <br>
          <h4 style="text-align: center; color:#f8b600;">KOMISI </h4>
          <h4 style="text-align: center; color:#f8b600;">GENERASI</h4>
          <a class="genric-btn primary-border" href="/commissions/komisi-generasi" >Pejalari Lebih Lanjut</a>
        </div>
        <div class="col-3">
          <img src="@/assets/KOMISI APRESIASI.svg" alt="" style="display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;">
          <br>
          <h4 style="text-align: center; color:#f8b600;">KOMISI </h4>
          <h4 style="text-align: center; color:#f8b600;">APRESIASI</h4>
          <a class="genric-btn primary-border" href="/commissions/komisi-apresiasi" >Pejalari Lebih Lanjut</a>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-3">
          <img src="@/assets/KOMISI BELANJA ULANG.svg" alt="" style="display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;">
          <br>
          <h4 style="text-align: center; color:#f8b600;">KOMISI</h4>
          <h4 style="text-align: center; color:#f8b600;">BELANJA ULANG</h4>
          <a class="genric-btn primary-border" href="/commissions/komisi-belanja-ulang" >Pejalari Lebih Lanjut</a>
        </div>
        <div class="col-3">
          <img src="@/assets/KOMISI PENJUALAN BELANJA ULANG.svg" alt="" style="display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;">
          <br>
          <h4 style="text-align: center; color:#f8b600;">KOMISI PENJUALAN</h4>
          <h4 style="text-align: center; color:#f8b600;">BELANJA ULANG</h4>
          <a class="genric-btn primary-border" href="/commissions/komisi-penjualan-belanja-ulang" >Pejalari Lebih Lanjut</a>
        </div>
        <div class="col-3">
          <img src="@/assets/KOMISI GENERASI BELANJA ULANG.svg" alt="" style="display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;">
          <br>
          <h4 style="text-align: center; color:#f8b600;">KOMISI GENERASI</h4>
          <h4 style="text-align: center; color:#f8b600;">BELANJA ULANG</h4>
          <a class="genric-btn primary-border" href="/commissions/komisi-generasi-belanja-ulang" >Pejalari Lebih Lanjut</a>
        </div>
        <div class="col-3">
          <img src="@/assets/REWARD (1).svg" alt="" style="display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;">
          <br>
          <h4 style="text-align: center; color:#f8b600; margin-bottom: 2.3rem;" >REWARD</h4>
          <a class="genric-btn primary-border" href="/commissions/reward" >Pejalari Lebih Lanjut</a>
        </div>
      </div>

      <!-- <div class="testi_slider">
        <carousel
          :per-page="2"
          :autoplay="true"
          :mouse-drag="true"
          :looping="true"
        >
          <slide v-for="data in comissions" :key="data.id">
            <div class="item ml-3">
              <div class="testi_item">
                <div class="media">
                  <div class="d-flex">
                    <img  v-bind:src="getGoogleDriveImage(data.image_url)"
                      alt="" style="width: 100px;">
                  </div>
                  <div class="media-body">
                    <p>
                      {{ data.description.substring(0, 110) + ".." }}
                    </p>
                    <a v-bind:href="'/commissions/' + data.slug">
                      <h4>{{ data.name }}</h4>
                    </a>
                    <div class="rating">
                      <b>{{ data.frequency }}</b>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </slide>
        </carousel>
      </div> -->
    </div>
  </section>
</template>

<script>
// import { Carousel, Slide } from "vue-carousel";
export default {
  // components: {
  //   Carousel,
  //   Slide,
  // },
  name: "komisi",
  data() {
    return {
      comissions: [],
      err: "",
    };
  },

  mounted() {
    this.fetchComissions();
  },
  methods: {
    async fetchComissions() {
      try {
        const params = {
          limit: 10,
          offset: 0,
          order_by: {
            id: "asc",
          },
        };
        const response = await this.ApiCompro({
          url: "api/rest/compro/commissions",
          formData: params,
        }).post();
        this.comissions = response.data.company_commissions;
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
  },
};
</script>

<style></style>
