import Vue from 'vue'
import VueRouter from 'vue-router'

//route untuk robby
import MainPage from '../views/compro/MainPage.vue'
import AboutPage from '../views/compro/pages/AboutPage.vue'
import KontakPage from '../views/compro/pages/KontakPage.vue'
import NewsPage from '../views/compro/pages/NewsPage.vue'
import PaketPage from '../views/compro/pages/PaketPage.vue'
import ProdukPage from '../views/compro/pages/ProdukPage.vue'
import KomisiPage from '../views/compro/pages/KomisiPage.vue'
import ProdukPageList from '../views/compro/pages/ProdukPageList.vue'
import DeleteAkun from '../views/compro/pages/DeleteAkun.vue'

// import Joshua
import auth from '../middleware/auth'
import AdminLoginView from '../views/admin/login/LoginView.vue'
import AdminDashboardView from '../views/admin/dashboard/DashboardView.vue'

import MaintenancePage from '../views/admin/MaintenancePage/index.vue'

Vue.use(VueRouter)

const routes = [
  //route untuk robby
  {
    path: '/',
    name: 'main',
    component: MainPage
  },
  {
    path: '/about',
    name: 'AboutPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutPage
  },

  // Route Joshua biar ga conflict
  {
    path: '/mlm-admin',
    name: 'LoginView',
    component: AdminLoginView
  },
  {
    path: '/mlm-admin/dashboard',
    name: 'DashboardView',
    component: AdminDashboardView,
    beforeEnter: auth
  },
  {
    path: '/contact',
    name: 'KontakPage',
    component: KontakPage
  },
  {
    path: '/news/:slug',
    name: 'NewsPage',
    component: NewsPage
  },
  {
    path: '/packages',
    name: 'PaketPage',
    component: PaketPage
  },

  {
    path: '/products/:slug',
    name: 'ProdukPage',
    component: ProdukPage
  },
  {
    path: '/productslist',
    name: 'ProductsPageList',
    component: ProdukPageList
  },

  {
    path: '/commissions/:slug',
    name: 'KomisiPage',
    component: KomisiPage
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: MaintenancePage
  },
  {
    path: '/delete',
    name: 'DeleteAkun',
    component: DeleteAkun
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL || "/",
  routes
})

export default router


