<template>
    <div class="main">
      <Header></Header>
      <Hero></Hero>
  
      <!--================About Area =================-->
      <div class="whole-wrap" v-for="data in compro" :key="data.id">
        <div class="container">
          <div class="section-top-border">
            <h3 class="mb-30 title_color">{{ data.name }}</h3>
            <div class="row">
              <div class="col-md-3 mb-3">
                <img
                  v-bind:src="getGoogleDriveImage(data.image_url)"
                  alt=""
                  style="width: 600px; height: 300px"
                  class="img-fluid"
                />
              </div>
              <div class="col-md-9 mt-sm-20 left-align-p">
                <h4>Menghapus akun Ghani Anda</h4>
                <p>
                    Karena alasan keamanan, kami tidak bisa menghapus akun untuk Anda. Anda harus login ke akun Anda untuk meminta penghapusan. Jika Anda lupa kata sandi atau nama pengguna, lihat tips untuk login.
                </p>
<h4>Menghapus akun Anda secara permanen : </h4>
<p>1. Klik menu posting </p>
<p>2. Klik icon foto profile  </p>
<p>3. Klik menu delete akun </p>
<p>4. Konfirmasi bahwa anda ingin menghapus akun</p>
<p>5. Akun anda akan dihapus secara permanen</p>
<p>Setelah akun dihapus, Anda tidak bisa mendaftar lagi dengan nama pengguna yang sama. </p>
<p>Proses penghapusan mungkin perlu waktu hingga 90 hari setelah diajukan. Setelah 90 hari, salinan konten Anda mungkin masih ada di penyimpanan cadangan yang kami gunakan untuk memulihkan jika terjadi bencana, kerusakan perangkat lunak, atau kehilangan data lainnya. Kami juga menyimpan informasi Anda untuk hal-hal seperti masalah hukum, pelanggaran ketentuan, atau upaya pencegahan bahaya.</p>

              </div>
            </div>
           
          </div>



        </div>
      </div>
  
      <!--================About Area END =================-->
  
      <Footer />
    </div>
  </template>
  
  <script>
  import Header from "@/components/compro/Header.vue";
  import Hero from "@/components/compro/Hero.vue";
  import Footer from "@/components/compro/Footer.vue";
  // import axios from "axios";
  
  export default {
    name: "DeleteAkun",
    components: {
      Header,
      Hero,
      Footer,
    },
    data() {
      return {
        compro: [],
        err: "",
      };
    },
    mounted() {
      this.fetchCompro();
    },
    methods : {
      async fetchCompro() {
        try {
          const params = {
              limit : 10,
              offset : 0,
              order_by: {
                id : 'asc'
              }
          };
          const response = await this.ApiCompro({
            url: "api/rest/compro/companies",
            formData: params,
          }).post();
          this.compro = response.data.company_companies;
        } catch (err) {
          this.$toast.error("Maaf terjadi kesalahan");
        }
      },
    }
  
  };
  </script>
  
  <style>
  </style>
  