<template>
  <div class="modalconfirmation">
    <div class="modalconfirmation__mask" @click="closeModal()"></div>
    <div class="modalconfirmation__box">
      <p class="modalconfirmation__box-text">{{ title }}</p>
      <div class="modalconfirmation__box-button">
        <CustomButton
          :button-display="false"
          :button-text="'Delete'"
          :background-color="'#FA5C7C'"
          :padding-top="'0.5rem'"
          :padding-bottom="'0.5rem'"
          :image="require('@/assets/admin/icon/close-white.png')"
          @buttonClicked="closeModal()"
        ></CustomButton>
        <CustomButton
          :button-display="false"
          :button-text="'Add'"
          :padding-top="'0.5rem'"
          :padding-bottom="'0.5rem'"
          :image="require('@/assets/admin/icon/white-check.svg')"
          @buttonClicked="emitYes()"
        ></CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '../atoms/CustomButton.vue';
export default {
  components: {
    CustomButton
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    emitYes() {
      this.$emit("yesClicked")
    },
    closeModal() {
      this.$emit("closeModal");
    }
  },
};
</script>

<style scoped>
.modalconfirmation {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.modalconfirmation__mask {
  position: fixed;
  background: black;
  opacity: 75%;
  width: 100vw;
  height: 100vh;
}

.modalconfirmation__box {
  padding: 2rem;
  background-color: white;
  border-radius: 16px;
  z-index: 2;
  min-width: 35rem;
  height: auto;
  max-height: 600px;
}

.modalconfirmation__box-text {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.modalconfirmation__box-button {
  display: flex;
  margin-top: 3rem;
  justify-content: center;
  align-items: center;
  gap: 64px;
}
</style>
