<template>
  <div class="main">
    <Header></Header>
    <Hero></Hero>

    <!--================About Area =================-->
    <div class="whole-wrap">
      <div class="container">
        <div class="section-top-border">
          <h3 class="mb-30 title_color">{{ commissions.name }}</h3>
          <div class="row">
            <div class="col-lg-12">
              <blockquote class="generic-blockquote">
                <h4>(Dibayar {{ commissions.frequency }})</h4>
                {{ commissions.description }}
              </blockquote>
              <h4><u>Contoh :</u></h4>
              <ul class="unordered-list">
                <li>
                  <b
                    ><span>{{ commissions.additional_note }}</span></b
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="section-top-border">
          <img
            class="img-fluid"
            v-bind:src="getGoogleDriveImage(commissions.image_url)"
            alt=""
            style="width: 50%"
          />
        </div>
      </div>
    </div>

    <!--================About Area END =================-->

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/compro/Header.vue";
import Hero from "@/components/compro/Hero.vue";
import Footer from "@/components/compro/Footer.vue";

export default {
  name: "KomisiPage",
  components: {
    Header,
    Hero,
    Footer,
  },
  data() {
    return {
      commissions: [],
      comissionsPage: [],
      err: "",
    };
  },
  mounted() {
    this.fetchKomisiPage();
  },
  methods: {
    async fetchKomisiPage() {
      try {
        const response = await this.ApiCompro({
          url: "api/rest/compro/commissions/" + this.$route.params.slug,
        }).get();
        this.commissions = response.data.company_commissions;
        this.commissions =
          this.commissions.length > 0 ? this.commissions[0] : [];
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
  },
};
</script>

<style></style>
