<template>
  <div class="komisikepemimpinan">
    <Dropdown
      :options="userListDropdown"
      @selected="userSelected"
      name="User Id"
      placeholder="Pilih user yang ingin dicari"
    >
    </Dropdown>
    <div class="komisikepemimpinan__list">
      <div class="komisikepemimpinan__list-menu">
        <!-- <div class="komisikepemimpinan__list-search">
          <input
            v-model="searchInput"
            type="text"
            class="komisikepemimpinan__list-search-input"
            placeholder="Cari data..."
          />
          <button
            type="submit"
            class="komisikepemimpinan__list-search-button"
            @click="searchData()"
          >
            <img
              src="@/assets/admin/icon/search.png"
              alt="search"
              class="komisikepemimpinan__list-search-button-img"
            />
          </button>
        </div>
        <div class="komisikepemimpinan__list-dropdown">
          <div
            class="komisikepemimpinan__list-dropdown-box"
            @click="toggleFilter()"
            v-click-outside="closeFilter"
          >
            <p class="komisikepemimpinan__list-dropdown-box-text">
              {{ selectedFilter?.name }}
            </p>
            <div
              class="komisikepemimpinan__list-dropdown-box-content"
              v-if="filterOpened"
            >
              <p
                class="komisikepemimpinan__list-dropdown-box-content-text"
                :class="
                  index === filterList.length - 1
                    ? 'kepemimpinan__list-dropdown-box-content-text_no-bottom'
                    : ''
                "
                v-for="(dt, index) in filterList"
                :key="index"
                @click="setSelectedFilter(dt)"
              >
                {{ dt.name }}
              </p>
            </div>
          </div>
          <button type="submit" class="komisikepemimpinan__list-search-button">
            <img
              src="@/assets/admin/icon/filter.png"
              alt="search"
              class="komisikepemimpinan__list-search-button-img"
            />
          </button>
        </div>
        <div class="komisikepemimpinan__list-dropdown">
          <div
            class="komisikepemimpinan__list-dropdown-box"
            @click="toggleSort()"
            v-click-outside="closeSort"
          >
            <p class="komisikepemimpinan__list-dropdown-box-text">
              {{ selectedSort?.name }}
            </p>
            <img
              src="@/assets/admin/icon/sort-asc-black.png"
              alt="search"
              class="komisikepemimpinan__list-search-button-img"
              v-if="selectedSort?.sortAsc"
            />
            <img
              src="@/assets/admin/icon/sort-desc-black.png"
              alt="search"
              class="komisikepemimpinan__list-search-button-img"
              v-else
            />
            <div
              class="komisikepemimpinan__list-dropdown-box-content"
              v-if="sortOpened"
            >
              <p
                class="komisikepemimpinan__list-dropdown-box-content-text"
                :class="
                  index === sortList.length - 1
                    ? 'kepemimpinan__list-dropdown-box-content-text_no-bottom'
                    : ''
                "
                v-for="(dt, index) in sortList"
                :key="index"
                @click="setSelectedSort(dt)"
              >
                {{ dt.name }}
              </p>
            </div>
          </div>
          <button type="submit" class="komisikepemimpinan__list-search-button">
            <img
              src="@/assets/admin/icon/filter.png"
              alt="search"
              class="komisikepemimpinan__list-search-button-img"
            />
          </button>
        </div> -->
        <div class="komisikepemimpinan__list-adddelete">
          <!-- <CustomButton
            :button-text="'Klaim Bonus Harian'"
            @buttonClicked="claimBonusDaily()"
          ></CustomButton> -->
        </div>
      </div>
      <div class="komisikepemimpinan__list-table">
        <div class="komisikepemimpinan__list-table-header">
          <p class="komisikepemimpinan__list-table-header-no">No</p>
          <p class="komisikepemimpinan__list-table-header-code">Unit</p>
          <div class="komisikepemimpinan__list-table-header-ending-balance">
            <p>Saldo Terakhir</p>
          </div>
          <div class="komisikepemimpinan__list-table-header-ending-balance">
            <p>Omset Hari Ini</p>
          </div>
          <div class="komisikepemimpinan__list-table-header-ending-balance">
            <p>Total Claim</p>
          </div>
          <p class="komisikepemimpinan__list-table-header-attribute">Bonus</p>
          <div class="komisikepemimpinan__list-table-header-ending-balance">
            <p>Saldo yang di Claim</p>
          </div>
          <p class="komisikepemimpinan__list-table-header-cashback">Income Cashback</p>
          <p class="komisikepemimpinan__list-table-header-cashback">Total Cashback</p>
        </div>
        <div class="komisikepemimpinan__list-table-body">
          <div v-if="validData && validData.length > 0">
            <div
              class="komisikepemimpinan__list-table-body-user"
              v-for="(data, index) in validData"
              :key="index"
            >
              <p class="komisikepemimpinan__list-table-body-no">
                {{ index + 1 || "-" }}
              </p>
              <p class="komisikepemimpinan__list-table-body-code">
                {{ data?.unit || "-" }}
              </p>
              <div class="komisikepemimpinan__list-table-body-balance">
                <p class="komisikepemimpinan__list-table-body-balance_text">
                  {{ formatNumber(data?.ending_balance?.point_value_left) || 0 }}
                </p>
                <p class="komisikepemimpinan__list-table-body-balance_text">
                  {{ formatNumber(data?.ending_balance?.point_value_right) || 0 }}
                </p>
              </div>
              <div class="komisikepemimpinan__list-table-body-balance">
                <p class="komisikepemimpinan__list-table-body-balance_text">
                  {{ formatNumber(data?.today_balance?.point_value_left) || 0 }}
                </p>
                <p class="komisikepemimpinan__list-table-body-balance_text">
                  {{ formatNumber(data?.today_balance?.point_value_right) || 0 }}
                </p>
              </div>
              <div class="komisikepemimpinan__list-table-body-balance">
                <p class="komisikepemimpinan__list-table-body-balance_text">
                  {{ formatNumber(data?.total_claim_balance?.point_value_left) || 0 }}
                </p>
                <p class="komisikepemimpinan__list-table-body-balance_text">
                  {{ formatNumber(data?.total_claim_balance?.point_value_right) || 0 }}
                </p>
              </div>
              <p class="komisikepemimpinan__list-table-body-attribute">
                {{ formatNumber(data?.bonus) ?? 0 }}
              </p>
              <div class="komisikepemimpinan__list-table-body-balance">
                <p class="komisikepemimpinan__list-table-body-balance_text">
                  {{ formatNumber(data?.remaining_balance?.point_value_left) || 0 }}
                </p>
                <p class="komisikepemimpinan__list-table-body-balance_text">
                  {{ formatNumber(data?.remaining_balance?.point_value_right) || 0 }}
                </p>
              </div>
              <p class="komisikepemimpinan__list-table-body-cashback">
                {{ formatNumber(data?.cashback_reward?.income_cashback) || 0 }}
              </p>
              <p class="komisikepemimpinan__list-table-body-cashback">
                {{ formatNumber(data?.cashback_reward?.total_cashback) || 0 }}
              </p>
            </div>
          </div>
          <div class="komisikepemimpinan__list-table-body-user_no-data" v-else>
            <p class="komisikepemimpinan__list-table-body-user_no-data-text">
              Tidak ada data
            </p>
          </div>
        </div>
      </div>
      <div class="komisikepemimpinan__list-pagination">
        <!-- Go Back -->
        <div
          class="komisikepemimpinan__list-pagination-chevron"
          @click="setCurrentPage(paginationOption.currentPage - 1)"
          v-if="paginationOption.currentPage - 1 > 0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </div>

        <div
          class="komisikepemimpinan__list-pagination-first"
          v-if="paginationOption.currentPage > 2"
        >
          <p
            class="komisikepemimpinan__list-pagination-number"
            @click="setCurrentPage(1)"
          >
            1
          </p>
          <p
            class="komisikepemimpinan__list-pagination-number"
            v-if="paginationOption.currentPage > 3"
          >
            . . .
          </p>
        </div>

        <!-- Previous Page -->
        <p
          class="komisikepemimpinan__list-pagination-number"
          v-if="paginationOption.currentPage - 1 > 0"
          @click="setCurrentPage(paginationOption.currentPage - 1)"
        >
          {{ paginationOption.currentPage - 1 }}
        </p>

        <!-- Current Page -->
        <p
          class="komisikepemimpinan__list-pagination-number kepemimpinan__list-pagination-number_bold"
        >
          {{ paginationOption.currentPage }}
        </p>

        <!-- Next Page -->
        <p
          class="komisikepemimpinan__list-pagination-number"
          v-if="paginationOption.currentPage + 1 < paginationOption.totalPage"
          @click="setCurrentPage(paginationOption.currentPage + 1)"
        >
          {{ paginationOption.currentPage + 1 }}
        </p>

        <div
          class="komisikepemimpinan__list-pagination-first"
          v-if="paginationOption.currentPage < paginationOption.totalPage"
        >
          <p
            class="komisikepemimpinan__list-pagination-number"
            v-if="
              paginationOption.currentPage + 3 <= paginationOption.totalPage
            "
          >
            . . .
          </p>
          <p
            class="komisikepemimpinan__list-pagination-number"
            @click="setCurrentPage(paginationOption.totalPage)"
          >
            {{ paginationOption.totalPage }}
          </p>
        </div>

        <!-- Go Next -->
        <div
          class="komisikepemimpinan__list-pagination-chevron"
          @click="setCurrentPage(paginationOption.currentPage + 1)"
          v-if="paginationOption.currentPage < paginationOption.totalPage"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-right"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CustomButton from "@/components/atoms/CustomButton.vue";
import ClickOutside from "vue-click-outside";
// import ModalDetailVue from "./ModalDetail.vue";
import Dropdown from "@/components/molecules/CustomDropdown.vue";

export default {
  name: "NavigationBar",
  components: {
    // CustomButton,
    Dropdown,
  },
  data() {
    return {
      userList: [],
      userListDropdown: [
        {
          id: 1,
          name: null,
        },
      ],
      resultSearch: null,
      searchInput: null,
      selectedFilter: null,
      filterOpened: false,
      filterList: [
        {
          name: "Unit",
          code: "unit",
        },
      ],
      paginationOption: {
        limitPage: 1000,
        totalPage: 1,
        currentPage: 1,
      },

      selectedSort: {
        name: "User Name",
        order_by: "name",
        sortAsc: false,
      },
      sortOpened: false,
      sortList: [
        {
          name: "Unit",
          order_by: "unit",
          sortAsc: false,
        },
      ],
      statusAddModal: false,
      detailUser: {},
      type: "preview",
      buttonHovered: false,
      totalPage: null,
      selectedUser: null,
      dataKomisi: [],
    };
  },
  mounted() {
    this.getUserList();
    this.setInitialFilter();
  },
  methods: {
    userSelected(val) {
      this.selectedUser = val;
      this.getKomisiKepemimpinan();
    },
    setDetailUser(user) {
      this.detailUser = user;
      this.setType("preview");
      this.showAddModal();
    },
    setInitialFilter() {
      this.selectedFilter = this.filterList[0];
    },
    setInitialSort() {
      this.selectedSort = this.sortList[0];
    },
    setSelectedFilter(params) {
      this.selectedFilter = params;
    },
    setSelectedSort(params) {
      if (this.selectedSort.order_by === params.order_by) {
        this.selectedSort.sortAsc = !this.selectedSort.sortAsc;
      } else {
        this.selectedSort = params;
      }

      this.getUserList();
    },
    showAddModal() {
      this.statusAddModal = true;
    },
    hideAddModal() {
      this.statusAddModal = false;
    },
    setType(val) {
      this.type = val;
    },
    async getUserList() {
      try {
        const params = {
          limit: this.paginationOption.limitPage,
          offset:
            (this.paginationOption.currentPage - 1) *
            this.paginationOption.limitPage,
          order_by: {
            [this.selectedSort.order_by]: this.selectedSort.sortAsc
              ? "asc"
              : "desc",
          },
          // order_sort: this.selectedSort.sortAsc ? "asc" : "desc",
        };
        const response = await this.Api({
          url: "api/rest/users",
          formData: params,
        }).post();
        this.userListDropdown = response.body.users;
        // this.getTotalPage(response.data.total_page);
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    async getKomisiKepemimpinan() {
      this.loading = true;
      try {
        // const params = {
        //   limit: 10,
        //   offset: 0,
        //   order_by: {
        //     created_at: "asc",
        //   },
        // };
        const response = await this.ApiGo({
          url: `api/users/${this.selectedUser?.id}/commissions/leaderships`,
          // formData: params,
        }).get();

        // this.paginationOption.totalPage = Math.ceil(
        //   response?.body?.user_commission_initial_sale_aggregate?.aggregate
        //     ?.count / this.paginationOption.limitPage
        // );
        // this.totalPage = this.paginationOption.totalPage;
        this.dataKomisi = response?.body?.data?.user_unit;
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      } finally {
        this.loading = false;
      }
    },
    editUser(user) {
      this.detailUser = user;
      this.setType("edit");
      this.showAddModal();
    },
    async deleteUser(id) {
      try {
        const response = await this.Api({
          url: `api/rest/users/${id}`,
        }).delete();

        if (response) {
          this.$toast.success("User berhasil dihapus");
          this.getUserList();
        }
      } catch {
        this.$toast.error("Gagal menghapus user");
      }
    },
    getTotalPage(totalData) {
      this.paginationOption.totalPage = Math.ceil(
        totalData / this.paginationOption.limitPage
      );
    },
    searchData() {
      const lowerCaseinput = this.searchInput?.toLowerCase();
      if (lowerCaseinput) {
        this.resultSearch = this.dataKomisi.filter((dt) =>
          String(dt?.user?.[this.selectedFilter.code])
            .toLowerCase()
            .includes(lowerCaseinput)
        );
        this.paginationOption.totalPage = 1;
      } else {
        this.resultSearch = null;
        this.paginationOption.totalPage = this.totalPage;
      }
    },
    toggleFilter() {
      this.filterOpened = !this.filterOpened;
    },
    closeFilter() {
      this.filterOpened = false;
    },
    toggleSort() {
      this.sortOpened = !this.sortOpened;
    },
    closeSort() {
      this.sortOpened = false;
    },
    setCurrentPage(page) {
      this.paginationOption.currentPage = page;
      this.getUserList();
    },
    resetSortList() {
      this.sortList.forEach((dt) => {
        dt.sortAsc = true;
      });
    },
    async claimBonusDaily() {
      try {
        await this.ApiGo({
          url: `api/commissions/daily`,
          // formData: params,
        }).post();
        this.$toast.success("Berhasil claim bonus harian");
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    onUserCreated() {
      this.hideAddModal();
      this.getUserList();
    },
  },
  computed: {
    validData() {
      return this.resultSearch ? this.resultSearch : this.dataKomisi;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style>
@import "./komisikepemimpinancontent.css";
</style>
