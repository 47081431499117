<template>
  <div class="loading">
    <div class="loading__mask" @click="closeModal()"></div>
    <div class="loading__box">
      <img :src="data" alt="preview image" class="image__width" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: String,
      default: "",
    },
  },
  watch: {},
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
.loading {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.loading__mask {
  position: fixed;
  background: black;
  opacity: 75%;
  width: 100vw;
  height: 100vh;
}

.loading__box {
  z-index: 9999999;
}

.loading__box-text {
  color: white;
  font-weight: 700;
  text-align: center;
}

.image__width {
  width: 500px;
}
</style>
