<template>
  <div class="modaldetail">
    <div class="modaldetail__mask" @click="closeModal()"></div>
    <div class="modaldetail__box">
      <div class="modaldetail__box-title">
        <p class="modaldetail__box-title-text">Produk Detail</p>
        <img
          src="@/assets/admin/icon/close.svg"
          alt="close"
          class="modaldetail__box-title-text-img"
          @click="closeModal()"
        />
      </div>
      <div>
        <div class="modaldetail__box-row">
          <UploadImageCover
            :initialImage="[formData.cover_url]"
          ></UploadImageCover>
        </div>
        <div class="modaldetail__box-row">
          <UploadImageVue
            :title="'Produk'"
            :initialImage="initialImage()"
          ></UploadImageVue>
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Nama Produk</p>
          <input
            v-model="formData.name"
            class="modaldetail__box-row-input"
            placeholder="Nama Produk"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Harga Member</p>
          <input
            type="number"
            v-model="formData.member_price"
            class="modaldetail__box-row-input"
            placeholder="Harga Member"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Harga Reguler</p>
          <input
            type="number"
            v-model="formData.regular_price"
            class="modaldetail__box-row-input"
            placeholder="Harga Reguler"
          />
        </div>
        <!-- <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Slug / Link di Web</p>
          <div
            class="modaldetail__box-row-input"
            placeholder="Masukkan link web"
          />
          <p>{{ autoGenerateSlug }}</p>
        </div> -->
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Point Value</p>
          <input
            type="number"
            v-model="formData.point_value"
            class="modaldetail__box-row-input"
            placeholder="Point Value"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Kuantitas</p>
          <input
            type="number"
            v-model="formData.piece"
            class="modaldetail__box-row-input"
            placeholder="Kuantitas Produk"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Stok</p>
          <input
            type="number"
            v-model="formData.stock"
            class="modaldetail__box-row-input"
            placeholder="Stok Produk"
          />
        </div>
        <div class="modaldetail__box-row modaldetail__box-row-last">
          <p class="modaldetail__box-row-title">Deskripsi</p>
          <textarea
            v-model="formData.description"
            class="modaldetail__box-row-input"
            placeholder="Deskripsi Produk"
            rows="4"
            cols="50"
          >
          </textarea>

        </div>
        <div class="modaldetail__box-row-button" v-if="type !== 'preview'">
          <CustomButton
            :button-text="capitalizeFirstLetter(type)"
            @buttonClicked="formValidation()"
          ></CustomButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/atoms/CustomButton.vue";
import UploadImageVue from "@/components/molecules/UploadImage.vue";
import UploadImageCover from "@/components/molecules/UploadCover.vue";
export default {
  components: {
    CustomButton,
    UploadImageVue,
    UploadImageCover,
  },
  props: {
    productDetail: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formData: {
        name: null,
        member_price: null,
        slug: null,
        point_value: null,
        piece: null,
        stock: null,
        description: null,
        regular_price: null,
        cover_url: null,
        is_active: true,
      },
      formImage: {
        imageData: [],
      },
      savedInsertId: null,
    };
  },
  created() {
    this.$bus.$on("sent-image-cover-checked", (status) => {
      if (status) {
        this.productImageValidation();
      } else {
        this.$toast.error("Gambar cover tidak boleh kosong");
      }
    });

    this.$bus.$on("sent-image-list-checked", (status) => {
      if (status) {
        this.getCoverUrl();
      } else {
        this.$toast.error("Gambar produk tidak boleh kosong");
      }
    });

    this.$bus.$on("image-data", (data) => {
      if (data.length > 0) {
        this.formImage.imageData = data;
      }
      this.addImage(this.savedInsertId);
    });

    this.$bus.$on("image-data-cover", (data) => {
      if (data.length > 0) {
        this.formData.cover_url = data[0];
      }
      if (this.type === "add") {
        this.createProduct();
      } else {
        this.editProduct();
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off("image-data");
    this.$bus.$off("image-data-cover");
    this.$bus.$off("sent-image-cover-checked");
    this.$bus.$off("sent-image-list-checked");
  },
  watch: {
    productDetail(val) {
      if (val) {
        this.setProductDetail();
      }
    },
  },
  mounted() {
    this.setProductDetail(this.productDetail);
    this.resetModalData();
  },
  methods: {
    initialImage() {
      if (this.type === "add") {
        return;
      }
      let imageUrlList = [];
      this.productDetail?.product_images?.forEach((dt) => {
        imageUrlList.push(dt.image_url);
      });
      return imageUrlList;
    },
    setProductDetail(val) {
      this.formData.name = val.name;
      this.formData.member_price = val.member_price;
      this.formData.regular_price = val.regular_price;
      this.formData.slug = val.slug;
      this.formData.point_value = val.point_value;
      this.formData.piece = val.piece;
      this.formData.stock = val.stock;
      this.formData.description = val.description;
      this.formData.cover_url = val.cover_url;
      this.formImage.imageData = val.product_images;
    },
    autoGenerateSlug() {
      const lowerCase = this.formData.name?.toLowerCase();
      const replaceSpace = lowerCase?.replace(/ /g, "-");
      return replaceSpace;
    },
    getCoverUrl() {
      this.$bus.$emit("process-upload-cover");
    },
    imageCoverValidation() {
      this.$bus.$emit("check-image-cover");
    },
    productImageValidation() {
      this.$bus.$emit("check-image-list");
    },
    formValidation() {
      if (!this.formData.name) {
        this.$toast.error("Nama produk wajib diisi");
        return;
      }

      if (!this.formData.member_price) {
        this.$toast.error("Harga member wajib diisi");
        return;
      }

      if (!this.formData.regular_price) {
        this.$toast.error("Harga regular wajib diisi");
        return;
      }

      if (!this.formData.point_value) {
        this.$toast.error("Point value wajib diisi");
        return;
      }

      if (!this.formData.piece) {
        this.$toast.error("Kuantitas produk wajib diisi");
        return;
      }

      if (!this.formData.stock) {
        this.$toast.error("Stok produk wajib diisi");
        return;
      }

      if (!this.formData.description) {
        this.$toast.error("Deskripsi produk wajib diisi");
        return;
      }

      // if (!this.formData.cover_url) {
      //   this.$toast.error("Gambar cover produk tidak boleh kosong");
      //   return;
      // }

      // if (!this.selectedLevel || this.selectedLevel.length === 0) {
      //   this.$toast.error("Gambar produk tidak boleh kosong");
      //   return;
      // }

      this.imageCoverValidation();
    },
    async createProduct() {
      try {
        this.formData.slug = this.autoGenerateSlug();
        var formData = new FormData();
        const _this = this;

        Object.keys(this.formData).forEach(function (key) {
          if (Object.prototype.hasOwnProperty.call(_this.formData, key)) {
            formData.append(key, _this.formData[key]);
          }
        });

        const headers = {
          Authorization: "Bearer " + localStorage.getItem("token-jwt"),
        };
        const response = await this.Api({
          url: "api/rest/products/create",
          formData: formData,
          headers: headers,
        }).post();
        // await this.addImage(response.body.insert_products_one?.id);
        this.savedInsertId = response.body.insert_products_one?.id;
        this.$bus.$emit("process-upload-image");
        this.$toast.success("Produk berhasil ditambahkan");
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    async addImage(id) {
      try {
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("token-jwt"),
        };

        let product_images = [];
        this.formImage?.imageData?.forEach((dt) => {
          product_images.push({
            product_id: id,
            image_url: dt.image_url
          });
        });

        await this.Api({
          url: "api/rest/products/images/create",
          formData: {
            product_id: id,
            product_images: product_images,
          },
          headers: headers,
        }).post();
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan untuk gambar");
      } finally {
        this.$emit("createProduct");
      }
    },
    async editProduct() {
      try {
        var formData = new FormData();
        const _this = this;

        Object.keys(this.formData).forEach(function (key) {
          if (Object.prototype.hasOwnProperty.call(_this.formData, key)) {
            formData.append(key, _this.formData[key]);
          }
        });

        const headers = {
          Authorization: "Bearer " + localStorage.getItem("token-jwt"),
        };
        const response = await this.Api({
          url: `/api/rest/products/${this.productDetail.id}`,
          formData: formData,
          headers: headers,
        }).patch();
        this.savedInsertId = response.body.update_products?.returning[0].id;
        this.$bus.$emit("process-upload-image");
        this.$toast.success("Produk berhasil diubah");
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    resetModalData() {
      if (this.type === "add") {
        this.formData = {
          name: null,
          member_price: null,
          slug: null,
          point_value: null,
          piece: null,
          stock: null,
          description: null,
          regular_price: null,
          cover_url: null,
        };
      }
    },
  },
};
</script>

<style scoped>
.modaldetail {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.modaldetail__mask {
  position: fixed;
  background: black;
  opacity: 75%;
  width: 100vw;
  height: 100vh;
}

.modaldetail__box {
  padding: 0px 2rem 2rem 2rem;
  background-color: white;
  border-radius: 16px;
  z-index: 2;
  min-width: 55rem;
  height: auto;
  max-height: 600px;
  overflow-y: scroll;
}

.modaldetail__box-row-last {
  padding-bottom: 48px;
}

.modaldetail__box::-webkit-scrollbar {
  display: none;
}

.modaldetail__box-title {
  position: sticky;
  background: white;
  top: 0px;
  padding: 2rem 0 1rem 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modaldetail__box-row {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2rem;
}

.modaldetail__box-row-title {
  font-size: 14px;
  line-height: 16px;
  color: #707880;
}

.modaldetail__box-row-input {
  outline: none;
  border-radius: 8px;
  border: 1px solid #eef0f5;
  background: #f6fafd;
  padding: 8px;
}

.modaldetail__box-row-input::placeholder {
  font-size: 14px;
  line-height: 16px;
  color: darkgray;
}

.modaldetail__box-title-text {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.modaldetail__box-row-button {
  position: sticky;
  text-align: right;
  bottom: 0px;
  width: 100%;
}

.modaldetail__box-title-text-img {
  width: 26px;
  height: auto;
  position: absolute;
  right: 0px;
  cursor: pointer;
}

.modaldetail__box-row-image-box {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  border: 1px dashed black;
  border-radius: 8px;
}

.modaldetail__box-row-image-box-text {
  font-weight: bold;
}

.modaldetail__box-row-image-box-text_normal {
  font-weight: 400;
}

.modaldetail__box-row-image-box-file {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  opacity: 0;
}
</style>
