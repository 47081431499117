<template>
  <section class="home_banner_area" id="home">
    <div class="banner_inner">
      <div class="container">
        <div class="banner_content ">
          <div class="row">
            <div class="col-md-6">
              <br>
              <h3>Bersama GHANI Hidup Sehat Dan Bermanfaat 
                Hadir Untuk Menjadi Solusi Bagi Ummat 
                Kami Menyediakan Produk Yang Bisa Membantu 
                Untuk memenuhi kesehatan Bagi Masyarakat Luas,
                Produk Yang Kami Pasarkan Melalui 
                Program Penjualan Langsung 
              </h3>
              <br>
              <br>

              <a class="banner_btn" href="#about">Pelajari Lebih Lanjut</a>  
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-4">
              <br>
              <img src="@/assets/ghani-icon.png" alt="" style="width: 350px;  max-width: 100%;
              height: auto;">
              <br>
              <br>
              <h4 style="text-align: center;">Gahar Herba Natura Indonesia</h4>
              <p style="text-align: center;">Sehat Berkah berlimpah </p>
              </div>
          </div>   
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Dashboard",
};
</script>

<style>
</style>