<template>
  <div class="navigationbar">
    <div class="navigationbar__box">
      <div class="navigationbar__box-header">
        <img
          class="navigationbar__box-header-image"
          src="@/assets/admin/images/ghani-logo-small.png"
        />
      </div>
      <div class="navigationbar__box-body">
        <div
          class="navigationbar__box-body-list"
          v-for="(dt, index) in filteredPage"
          :key="index"
        >
          <p
            class="navigationbar__box-body-list-text"
            @click="
              !dt.subMenu
                ? goTo(dt.queryRoute)
                : (dt.subMenuOpened = !dt.subMenuOpened)
            "
          >
            {{ dt?.name }}
          </p>
          <div
            class="navigationbar__box-body-list-wrapper"
            :class="
              dt.subMenuOpened
                ? 'navigationbar__box-body-list-wrapper-full'
                : ''
            "
            v-if="dt.subMenu"
          >
            <div
              class="navigationbar__box-body-list-sub-container"
              :class="
                dt.subMenuOpened
                  ? 'navigationbar__box-body-list-sub-container_active'
                  : ''
              "
            >
              <div
                class="navigationbar__box-body-list-sub"
                v-for="(detail, idxDetail) in dt.subMenuContent"
                :key="idxDetail"
                @click="goTo(detail.queryRoute)"
              >
                <p>{{ detail.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationBar",
  props: {
    availablePage: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      navbarContentList: [
        // {
        //   name: "Dashboard",
        //   subMenu: false,
        //   queryRoute: "Dashboard",
        // },
        {
          name: "Admin Panel",
          subMenu: false,
          queryRoute: "Admin Panel",
        },
        {
          name: "User",
          subMenu: false,
          queryRoute: "User",
        },
        {
          name: "Product",
          subMenu: false,
          queryRoute: "Product",
        },
        {
          name: "Transaction",
          subMenu: false,
          queryRoute: "Transaction",
        },
        {
          name: "Total Sales",
          subMenu: false,
          queryRoute: "Total Sales",
        },
        {
          name: "Bonus User",
          subMenu: false,
          queryRoute: "Bonus User",
        },
        {
          name: "Komisi",
          subMenu: true,
          subMenuContent: [
            {
              name: "Penjualan Awal",
              queryRoute: "Penjualan Awal",
            },
            {
              name: "Kepemimpinan",
              queryRoute: "Kepemimpinan",
            },
            {
              name: "Cashback",
              queryRoute: "Cashback",
            },
          ],
          subMenuOpened: false,
        },
        {
          name: "Repeat Order",
          subMenu: true,
          subMenuContent: [
            {
              name: "RO Komisi",
              queryRoute: "RO Komisi",
            },
            {
              name: "RO Cashback",
              queryRoute: "RO Cashback",
            },
            {
              name: "RO Royalty",
              queryRoute: "RO Royalty",
            },
          ],
          subMenuOpened: false,
        },
        {
          name: "Royalti",
          subMenu: true,
          subMenuContent: [
            {
              name: "Kepemimpinan",
              queryRoute: "R. Kepemimpinan",
            },
          ],
          subMenuOpened: false,
        },
        {
          name: "Network Unit",
          subMenu: false,
          queryRoute: "Network Tree Unit",
        },
        {
          name: "Network Referral",
          subMenu: false,
          queryRoute: "Network Tree Referral",
        },
        {
          name: "Users Management",
          subMenu: true,
          subMenuContent: [
            {
              name: "Scope",
              queryRoute: "Scope",
            },
            {
              name: "Role",
              queryRoute: "Role",
            },
            {
              name: "Level",
              queryRoute: "Level",
            },
          ],
          subMenuOpened: false,
        },
        {
          name: "Company Management",
          subMenu: true,
          subMenuContent: [
            {
              name: "Profile Management",
              queryRoute: "CompanyProfile",
            },
            {
              name: "Commissions Management",
              queryRoute: "Commissions",
            },
            {
              name: "News Management",
              queryRoute: "News",
            },
            {
              name: "Contacts Management",
              queryRoute: "Contacts",
            },
          ],
          subMenuOpened: false,
        },
      ],
      selectedMenu: null,
    };
  },
  computed: {
    filteredPage() {
      let result = [];
      if (this.availablePage[0] === "all") {
        result = this.navbarContentList;
        return result;
      }
      this.availablePage?.forEach((dt) => {
        this.navbarContentList.forEach((dtDetail) => {
          if (dtDetail.subMenu === true) {
            dtDetail.subMenuContent.forEach((dtSub) => {
              if (dt === dtSub) {
                result.push(dtDetail);
              }
            });
          } else if (dt === dtDetail.queryRoute) {
            result.push(dtDetail);
          }
        });
      });
      return result;
    },
  },
  methods: {
    setSelectedMenu(param) {
      this.selectedMenu = param;
    },
    resetSubMenuState() {
      this.navbarContentList.forEach((dt) => {
        if (dt.subMenu) {
          dt.subMenuOpened = false;
        }
      });
    },
    goTo(queryRoute) {
      this.resetSubMenuState();
      if (queryRoute === "dashboard") {
        this.$router.push(`/mlm-admin/dashboard`).catch(() => {});
      } else {
        this.$router
          .push(`/mlm-admin/dashboard?type=${queryRoute}`)
          .catch(() => {});
      }
    },
  },
};
</script>

<style>
@import "./navigationbar.css";
</style>
