export default {
  methods: {
    Api: function ({ url, formData = {}, headers }) {
      let _this = this;
      let baseMlmAdminUrl = process.env.VUE_APP_MLM_ADMIN_API;

      headers = {
        ...headers,
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token-jwt"),
        "Hasura-Client-Name": "hasura-console",
      };
      const errorHandler = (error) => {
        throw error;
      };
      return {
        get() {
          return _this.$http
            .get(`${baseMlmAdminUrl}/${url}`, { headers })
            .catch(errorHandler);
        },
        post() {
          return _this.$http
            .post(`${baseMlmAdminUrl}/${url}`, formData, { headers })
            .catch(errorHandler);
        },
        put() {
          return _this.$http
            .put(`${baseMlmAdminUrl}/${url}`, formData, { headers: headers })
            .catch(errorHandler);
        },
        patch() {
          return _this.$http
            .patch(`${baseMlmAdminUrl}/${url}`, formData, { headers: headers })
            .catch(errorHandler);
        },
        delete() {
          return _this.$http
            .delete(`${baseMlmAdminUrl}/${url}`, { headers: headers })
            .catch(errorHandler);
        },
      };
    },
    ApiGo({ url, formData = {}, headers }) {
      let _this = this;
      let baseMlmAdminUrl = process.env.VUE_APP_MLM_ADMIN_GO;

      headers = {
        ...headers,
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token-jwt"),
      };
      const errorHandler = (error) => {
        throw error;
      };
      return {
        get() {
          return _this.$http
            .get(`${baseMlmAdminUrl}/${url}`, formData, { headers: headers })
            .catch(errorHandler);
        },
        post() {
          return _this.$http
            .post(`${baseMlmAdminUrl}/${url}`, formData, { headers: headers })
            .catch(errorHandler);
        },
        put() {
          return _this.$http
            .put(`${baseMlmAdminUrl}/${url}`, formData, { headers: headers })
            .catch(errorHandler);
        },
        patch() {
          return _this.$http
            .patch(`${baseMlmAdminUrl}/${url}`, formData, { headers: headers })
            .catch(errorHandler);
        },
        delete() {
          return _this.$http
            .delete(`${baseMlmAdminUrl}/${url}`, formData, { headers })
            .catch(errorHandler);
        },
      };
    },
    ApiCompro: function ({ url, formData = {}, headers }) {
      let _this = this;
      let baseMlmAdminUrl = process.env.VUE_APP_MLM_ADMIN_API;

      headers = {
        ...headers,
        "Content-Type": "application/json",
        "Hasura-Client-Name": "hasura-console",
      };
      const errorHandler = (error) => {
        throw error;
      };
      return {
        get() {
          return _this.$http
            .get(`${baseMlmAdminUrl}/${url}`, { headers })
            .catch(errorHandler);
        },
        post() {
          return _this.$http
            .post(`${baseMlmAdminUrl}/${url}`, formData, { headers })
            .catch(errorHandler);
        },
        put() {
          return _this.$http
            .put(`${baseMlmAdminUrl}/${url}`, formData, { headers: headers })
            .catch(errorHandler);
        },
        patch() {
          return _this.$http
            .patch(`${baseMlmAdminUrl}/${url}`, formData, { headers: headers })
            .catch(errorHandler);
        },
        delete() {
          return _this.$http
            .delete(`${baseMlmAdminUrl}/${url}`, { headers: headers })
            .catch(errorHandler);
        },
      };
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getGoogleDriveImage(driveUrl) {
      if (!driveUrl) {
        return;
      }

      if (!driveUrl.includes("https://drive")) {
        return driveUrl;
      }

      const driveId = driveUrl.substring(
        driveUrl.indexOf("d/") + 2,
        driveUrl.lastIndexOf("/view")
      );

      return `https://drive.google.com/uc?export=view&id=${driveId}`;
    },
    formatNumber(number) {
      if (number || number === 0) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        return null;
      }
    },
  },
};
