<template>
  <div class="modaldetail">
    <div class="modaldetail__mask" @click="closeModal()"></div>
    <div class="modaldetail__box">
      <div class="modaldetail__box-title">
        <p class="modaldetail__box-title-text">Scope Detail</p>
        <img
          src="@/assets/admin/icon/close.svg"
          alt="close"
          class="modaldetail__box-title-text-img"
          @click="closeModal()"
        />
      </div>
      <div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Nama</p>
          <input
            v-model="formData.name"
            class="modaldetail__box-row-input"
            placeholder="Nama Scope"
          />
        </div>
        <div class="modaldetail__box-row modaldetail__box-row-last">
          <p class="modaldetail__box-row-title">Deskripsi</p>
          <input
            type="text"
            v-model="formData.description"
            class="modaldetail__box-row-input"
            placeholder="Deskripsi scope"
          />
        </div>
        <div class="modaldetail__box-row-button" v-if="type !== 'preview'">
          <CustomButton
            :button-text="capitalizeFirstLetter(type)"
            @buttonClicked="formValidation()"
          ></CustomButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/atoms/CustomButton.vue";
export default {
  components: {
    CustomButton,
  },
  props: {
    scopeDetail: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formData: {
        name: "",
        description: "",
        is_active: true,
      },
    };
  },
  watch: {
    scopeDetail(val) {
      if (val) {
        this.setScopeDetail();
      }
    },
  },
  mounted() {
    this.setScopeDetail(this.scopeDetail);
  },
  methods: {
    setScopeDetail(val) {
      this.formData.name = val.name;
      this.formData.description = val.description;
    },
    getCode() {
      let code = this.formData.name.replace(" ", "_");
      let codeNoDot = code.replace(".", "");
      let codeLowercase = codeNoDot.toLowerCase();
      return codeLowercase;
    },
    formValidation() {
      if (!this.formData.name) {
        this.$toast.error("Nama scope wajib diisi");
        return;
      }

      if (!this.formData.description) {
        this.$toast.error("Deskripsi scope wajib diisi");
        return;
      }

      if (this.type === "add") {
        this.createScope();
      } else {
        this.editScope();
      }
    },
    async createScope() {
      let formData = {
        code: this.getCode(),
        name: this.formData.name,
        description: this.formData.description,
        is_active: true,
      };

      try {
        await this.Api({
          url: "api/rest/scopes/create",
          formData: formData,
        }).post();
        this.$emit("createscope");
        this.$toast.success("Scope berhasil ditambahkan");
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    async editScope() {
      let formData = {
        code: this.getCode(),
        name: this.formData.name,
        description: this.formData.description,
        is_active: true,
      };

      try {
        await this.Api({
          url: "api/rest/scopes/" + this.scopeDetail.id,
          formData: formData,
        }).patch();
        this.$emit("createscope");
        this.$toast.success("Scope berhasil diubah");
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    resetModalData() {
      this.formData = {
        name: "",
        member_price: null,
        slug: "",
        point_value: null,
        piece: null,
        stock: null,
        description: "",
      };
    },
  },
};
</script>

<style scoped>
.modaldetail {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.modaldetail__mask {
  position: fixed;
  background: black;
  opacity: 75%;
  width: 100vw;
  height: 100vh;
}

.modaldetail__box {
  padding: 0px 2rem 2rem 2rem;
  background-color: white;
  border-radius: 16px;
  z-index: 2;
  min-width: 55rem;
  height: auto;
  max-height: 600px;
  overflow-y: scroll;
}

.modaldetail__box-row-last {
  padding-bottom: 48px;
}

.modaldetail__box::-webkit-scrollbar {
  display: none;
}

.modaldetail__box-title {
  position: sticky;
  background: white;
  top: 0px;
  padding: 2rem 0 1rem 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modaldetail__box-row {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2rem;
}

.modaldetail__box-row-title {
  font-size: 14px;
  line-height: 16px;
  color: #707880;
}

.modaldetail__box-row-input {
  outline: none;
  border-radius: 8px;
  border: 1px solid #eef0f5;
  background: #f6fafd;
  padding: 8px;
}

.modaldetail__box-row-input::placeholder {
  font-size: 14px;
  line-height: 16px;
  color: darkgray;
}

.modaldetail__box-title-text {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.modaldetail__box-row-button {
  position: sticky;
  text-align: right;
  bottom: 0px;
  width: 100%;
}

.modaldetail__box-title-text-img {
  width: 26px;
  height: auto;
  position: absolute;
  right: 0px;
  cursor: pointer;
}

.modaldetail__box-row-image-box {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  border: 1px dashed black;
  border-radius: 8px;
}

.modaldetail__box-row-image-box-text {
  font-weight: bold;
}

.modaldetail__box-row-image-box-text_normal {
  font-weight: 400;
}

.modaldetail__box-row-image-box-file {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  opacity: 0;
}
</style>
