<template>
  <div class="main">
    <Header></Header>
    <Hero></Hero>

    <!--================About Area =================-->
    <section class="portfolio_details_area p_120">
      <div class="container">
        <div class="portfolio_details_inner">
          <div class="row">
            <div class="col-md-6">
              <div class="left_img">
                <img
                  class="img-fluid"
                  v-if="products?.product_images?.length > 0"
                  v-bind:src="
                    products?.product_images[0]?.image_url
                  "
                  alt="products images"
                />
              </div>
              
            </div>
            <div class="col-md-6">
              <div class="portfolio_right_text">
                <br /><br /><br />
                <h4>{{ products.name }}</h4>
                
                <ul class="list">
                  <li><span>Nilai PV</span>: {{ products.point_value }} PV</li>
                  <li>
                    <span>Harga Member</span>: Rp. {{ products.member_price }}
                  </li>
                  <li>
                    <span>Isi </span>: {{ products.piece }} ml/g/Kapsul/Sachet
                  </li>
                </ul>
           
                
                
                <br />
                <!-- <a
                  class="banner_btn circle"
                  v-bind:href="
                    'https://api.whatsapp.com/send?phone=6281370707885&text=Hello,%20GHANI!%20Saya%20tertarik%20dengan%20produk%20' +
                    products.name
                  "
                  target="_blank"
                  >Beli Sekarang</a
                > -->
                <!-- <br>
                <br>
                <ShareFacebook url="http://recruit.istyle.co.jp/career/"/> 
                <ShareTwitter url="http://recruit.istyle.co.jp/career/"/>
                <ShareGooglePlus url="http://recruit.istyle.co.jp/career/"/>
                <ShareHatena url="http://recruit.istyle.co.jp/career/"/>
                <ShareLine url="http://recruit.istyle.co.jp/career/"/> -->
              </div>
            </div>
          </div>
          <h5>Manfaat :</h5>
          <p>
            {{ products.description }}
          </p>
        </div>
      </div>
    </section>

    <!--================About Area END =================-->

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/compro/Header.vue";
import Hero from "@/components/compro/Hero.vue";
import Footer from "@/components/compro/Footer.vue";
// import { ShareFacebook, ShareTwitter, ShareGooglePlus, ShareHatena, ShareLine } from 'vue-share-social'

export default {
  name: "ProdukPage",
  components: {
    Header,
    Hero,
    Footer,
    // ShareFacebook,
    // ShareTwitter,
    // ShareGooglePlus,
    // ShareHatena,
    // ShareLine
  },
  data() {
    return {
      products: [],
      ProdukPage: [],
    };
  },
  mounted() {
    this.fetchProductsPage();
  },
  methods: {
    async fetchProductsPage() {
      try {
        const response = await this.ApiCompro({
          url: "api/rest/products/" + this.$route.params.slug,
        }).get();
        this.products = response.data.products;
        this.products = this.products.length > 0 ? this.products[0] : [];
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
  },
};
</script>

<style></style>
