<template>
  <div class="modaldetail">
    <div class="modaldetail__mask" @click="closeModal()"></div>
    <div class="modaldetail__box">
      <div class="modaldetail__box-title">
        <p class="modaldetail__box-title-text">User Detail</p>
        <img
          src="@/assets/admin/icon/close.svg"
          alt="close"
          class="modaldetail__box-title-text-img"
          @click="closeModal()"
        />
      </div>
      <div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Email</p>
          <input
            v-model="formData.email"
            class="modaldetail__box-row-input"
            placeholder="Email pengguna"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Nama</p>
          <input
            v-model="formData.name"
            class="modaldetail__box-row-input"
            placeholder="Nama pengguna"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">No. Handphone</p>
          <input
            v-model="formData.phone"
            class="modaldetail__box-row-input"
            placeholder="No. handphone pengguna"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Level Id</p>
          <!-- <Dropdown
            :options="levelList"
            @selected="levelSelected"
            name="Level Id"
            placeholder="Pilih level untuk pengguna"
            :disabled="selectedLevel.length >= 7 ? true : false"
          >
          </Dropdown> -->
          <div
            class="modaldetail__box-row-level-wrapper"
            v-if="selectedLevel.length > 0"
          >
            <div
              class="modaldetail__box-row-level"
              v-for="(dt, index) in selectedLevel"
              :key="index"
            >
              <p>{{ dt.name }}</p>
              <!-- <img
                src="@/assets/admin/icon/close.svg"
                alt="close"
                class="modaldetail__box-row-level-remove"
                @click="removeLevel(index)"
              /> -->
            </div>
          </div>
          <!-- <input
            v-model="formData.user_unit.level_id"
            class="modaldetail__box-row-input"
            placeholder="Level id pengguna"
          /> -->
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Role Id</p>
          <Dropdown
            :options="roleList"
            @selected="roleSelected"
            name="Role Id"
            placeholder="Pilih role untuk pengguna"
            :disabled="selectedRole ? true : false"
          >
          </Dropdown>
          <div class="modaldetail__box-row-level-wrapper" v-if="selectedRole">
            <div class="modaldetail__box-row-level">
              <p>{{ selectedRole.name }}</p>
              <img
                src="@/assets/admin/icon/close.svg"
                alt="close"
                class="modaldetail__box-row-level-remove"
                @click="removeRole()"
              />
            </div>
          </div>
          <!-- <input
            v-model="formData.user_unit.level_id"
            class="modaldetail__box-row-input"
            placeholder="Level id pengguna"
          /> -->
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Sponsor Code</p>
          <!-- <Dropdown
            :options="userList"
            @selected="userSelected"
            name="User Id"
            placeholder="Pilih refferal code"
            :disabled="selectedUser ? true : false"
          >
          </Dropdown> -->
          <div class="modaldetail__box-row-level-wrapper" v-if="selectedUser">
            <div class="modaldetail__box-row-level">
              <p>{{ selectedUser?.code }}</p>
              <!-- <img
                src="@/assets/admin/icon/close.svg"
                alt="close"
                class="modaldetail__box-row-level-remove"
                @click="removeUser()"
              /> -->
            </div>
          </div>
          <!-- <input
            v-model="formData.user_unit.level_id"
            class="modaldetail__box-row-input"
            placeholder="Level id pengguna"
          /> -->
        </div>
        <!-- <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Nama Sponsor</p>
          <div
            class="modaldetail__box-row-input"
            placeholder="Ahli waris pengguna"
          >
            {{ selectedUser?.name }}
          </div>
        </div> -->
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Heir</p>
          <input
            v-model="formData.heir"
            class="modaldetail__box-row-input"
            placeholder="Ahli waris pengguna"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Card Id</p>
          <input
            v-model="formData.card_id"
            class="modaldetail__box-row-input"
            placeholder="Card id pengguna"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Provinsi</p>
          <input
            v-model="formData.province"
            class="modaldetail__box-row-input"
            placeholder="Provinsi pengguna"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Kota</p>
          <input
            v-model="formData.city"
            class="modaldetail__box-row-input"
            placeholder="Kota pengguna"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Alamat</p>
          <input
            v-model="formData.address"
            class="modaldetail__box-row-input"
            placeholder="Alamat pengguna"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Kelurahan</p>
          <input
            v-model="formData.district"
            class="modaldetail__box-row-input"
            placeholder="Kelurahan pengguna"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Kode Pos</p>
          <input
            v-model="formData.postal_code"
            class="modaldetail__box-row-input"
            placeholder="Kode pos pengguna"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Nomor Bank</p>
          <input
            v-model="formData.bank_account_number"
            class="modaldetail__box-row-input"
            placeholder="Nomor bank pengguna"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Nama Bank</p>
          <input
            v-model="formData.bank_name"
            class="modaldetail__box-row-input"
            placeholder="Nama bank pengguna"
          />
        </div>
        <!-- <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Transaksi</p>
          <div class="modaldetail__box-row-transaction-product">
            <div
              class="modaldetail__box-row-transaction-product-component-wrapper"
              v-if="transactionList.length > 0"
            >
              <div class="modaldetail__box-row-transaction-product-component">
                <p
                  class="modaldetail__box-row-transaction-product-component-text"
                >
                  Produk
                </p>
                <p
                  class="modaldetail__box-row-transaction-product-component-text"
                >
                  Harga Reguler
                </p>
                <p
                  class="modaldetail__box-row-transaction-product-component-text"
                >
                  Harga Member
                </p>
                <p
                  class="modaldetail__box-row-transaction-product-component-text-stock"
                >
                  Kuantitas
                </p>
                <p
                  class="modaldetail__box-row-transaction-product-component-text-delete"
                ></p>
              </div>
              <div
                class="modaldetail__box-row-transaction-product-component-value-wrapper"
                v-for="(dt, index) in transactionList"
                :key="index"
              >
                <div
                  class="modaldetail__box-row-transaction-product-component-value"
                >
                  <Dropdown
                    :options="productList"
                    @selected="(dt) => productSelected(dt, index)"
                    name="product id"
                    placeholder="Pilih produk"
                    :disabled="paymentStatus === 'PAID'"
                    :initialValue="
                      temporaryProductData.length > 0
                        ? temporaryProductData[index]
                        : null
                    "
                  >
                  </Dropdown>
                </div>
                <div
                  class="modaldetail__box-row-transaction-product-component-value"
                >
                  <div class="modaldetail__box-row-input-preview">
                    <p>{{ formatNumber(dt.regular_price) || "-" }}</p>
                  </div>
                </div>
                <div
                  class="modaldetail__box-row-transaction-product-component-value"
                >
                  <div class="modaldetail__box-row-input-preview">
                    <p>{{ formatNumber(dt.member_price) || "-" }}</p>
                  </div>
                </div>
                <div
                  class="modaldetail__box-row-transaction-product-component-stock"
                >
                  <div
                    v-if="paymentStatus === 'PAID'"
                    class="modaldetail__box-row-input-preview modaldetail__box-row-transaction_gray"
                  >
                    <p>{{ transactionList[index].quantity || "-" }}</p>
                  </div>
                  <input
                    type="number"
                    v-model="transactionList[index].quantity"
                    class="modaldetail__box-row-input-preview"
                    placeholder="Masukkan kuantitas"
                    v-else
                  />
                </div>
                <div
                  class="modaldetail__box-row-transaction-product-component-delete"
                >
                  <img
                    src="@/assets/admin/icon/close.svg"
                    alt="close"
                    class="modaldetail__box-row-transaction-product-component-delete-img"
                    @click="deleteProduct(index)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="modaldetail__box-row-transaction"
            :class="
              paymentStatus === 'PAID'
                ? 'modaldetail__box-row-transaction_gray'
                : ''
            "
            @click="addProduct()"
          >
            <img src="@/assets/admin/icon/white-circle-plus.svg" alt="plus" />
            <p class="modaldetail__box-row-transaction-text">Tambah Produk</p>
          </div>
        </div> -->
        <!-- <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Deskripsi Transaksi</p>
          <input
            v-model="formData.transaction.description"
            class="modaldetail__box-row-input"
            placeholder="Deskripsi Transaksi"
          />
        </div> -->
        <div class="modaldetail__box-row-button" v-if="type !== 'preview'">
          <CustomButton
            :button-text="capitalizeFirstLetter(type)"
            @buttonClicked="formValidation()"
          ></CustomButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CardTree from "@/components/atoms/CardTree.vue";
import CustomButton from "@/components/atoms/CustomButton.vue";
// import Dropdown from "vue-simple-search-dropdown";
import Dropdown from "@/components/molecules/CustomDropdown.vue";
export default {
  components: {
    // CardTree,
    CustomButton,
    Dropdown,
  },
  props: {
    userDetail: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formData: {
        email: null,
        password: null,
        name: null,
        role_id: null,
        card_id: null,
        phone: null,
        province: null,
        city: null,
        address: null,
        district: null,
        postal_code: null,
        bank_account_number: null,
        bank_name: null,
        referral_code: null,
        heir: null,
        avatar_url: null,
        user_unit: {
          level_id: [],
        },
        transaction: {
          point_value_locking: null,
          description: null,
          product_transactions: [],
        },
      },
      levelList: [
        {
          id: 1,
          name: null,
        },
      ],
      roleList: [
        {
          id: 1,
          name: null,
        },
      ],
      productList: [
        {
          id: 1,
          name: null,
        },
      ],
      userList: [
        {
          id: 1,
          name: null,
        },
      ],
      transactionList: [
        {
          id: null,
          regular_price: null,
          member_price: null,
          quantity: null,
          point_value: null,
        },
      ],
      selectedLevel: [],
      selectedRole: null,
      selectedUser: null,
      temporaryProductData: [],
      paymentStatus: null,
      dataReferral: [],
    };
  },
  watch: {
    userDetail(val) {
      if (val) {
        this.setUserDetail();
      }
    },
  },
  async mounted() {
    if (this.type === "add") {
      this.resetModalData();
    }

    await this.getProductList();
    await this.getLevelList();
    await this.getRoleList();
    await this.getUserList();
    this.setUserDetail(this.userDetail);
  },
  methods: {
    async getRefferalList() {
      try {
        const params = {
          limit: 10,
          offset: 0,
          order_by: {
            generation: "asc",
          },
          // order_sort: this.selectedSort.sortAsc ? "asc" : "desc",
        };
        const response = await this.ApiGo({
          url: `api/users/${this.userDetail?.id}/refferals`,
          formData: params,
        }).post();
        this.dataReferral = response.data.data;
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    deleteProduct(index) {
      if (this.paymentStatus === "PAID") {
        return;
      }

      if (this.transactionList.length === 1) {
        this.$toast.error("Minimal 1 produk");
        return;
      }

      this.transactionList.splice(index, 1);
    },
    addProduct() {
      if (this.paymentStatus === "PAID") {
        return;
      }

      this.transactionList.push({
        id: null,
        regular_price: null,
        member_price: null,
        quantity: null,
        point_value: null,
      });
    },
    removeLevel(index) {
      this.selectedLevel.splice(index, 1);
    },
    removeRole() {
      this.selectedRole = null;
    },
    removeUser() {
      this.selectedUser = null;
    },
    async getUserList() {
      try {
        const params = {
          limit: 100,
          offset: 0,
          order_by: {
            name: "desc",
          },
          // order_sort: this.selectedSort.sortAsc ? "asc" : "desc",
        };
        const response = await this.Api({
          url: "api/rest/users",
          formData: params,
        }).post();
        (this.userList = []),
          response.body.users.forEach((dt) => {
            this.userList.push({
              name: dt.code,
              id: dt.code,
              code: dt.code,
            });
          });
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    async getProductList() {
      try {
        const params = {
          limit: 100,
          offset: 0,
          order: {
            name: "desc",
          },
          // order_sort: this.selectedSort.sortAsc ? "asc" : "desc",
        };
        const response = await this.Api({
          url: "api/rest/products",
          formData: params,
        }).post();
        this.productList = response.body.products;
        // this.getTotalPage(response.data.total_page);
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    async getRoleList() {
      try {
        const params = {
          limit: 10,
          offset: 0,
        };
        const response = await this.Api({
          url: "api/rest/roles",
          formData: params,
        }).post();
        this.roleList = response.body.roles;
        // this.getTotalPage(response.data.total_page);
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    async getLevelList() {
      try {
        const params = {
          limit: 10,
          offset: 0,
        };
        const response = await this.Api({
          url: "api/rest/levels",
          formData: params,
        }).post();
        this.levelList = response.body.levels;
        // this.getTotalPage(response.data.total_page);
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    levelSelected(val) {
      this.selectedLevel.push(val);
    },
    roleSelected(val) {
      this.selectedRole = val;
    },
    userSelected(val) {
      this.selectedUser = val;
    },
    productSelected(val, index) {
      this.transactionList[index].id = val.id;
      this.transactionList[index].regular_price = val.regular_price;
      this.transactionList[index].member_price = val.member_price;
      this.transactionList[index].point_value = val.point_value;
    },
    async getTransactionDetail(id) {
      try {
        const response = await this.Api({
          url: `api/rest/users/${id}/transactions`,
        }).get();
        this.processTransactionData(
          response.body.users?.[0]?.transactions?.[0]?.product_transactions
        );
        // this.getTotalPage(response.data.total_page);
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan saat membaca data transaksi");
      }
    },
    processTransactionData(data) {
      if (!data) {
        return;
      }
      this.transactionList = [];
      data.forEach((dt) => {
        // This one for dropdown data
        const product = dt.product;
        this.productList.forEach((dtProduct) => {
          if (product.id === dtProduct.id) {
            this.temporaryProductData.push({
              ...dtProduct,
              quantity: Number(dt.quantity),
            });
          }
        });

        // This one for row data
        this.transactionList.push({
          id: dt.product.id,
          regular_price: null,
          member_price: null,
          quantity: Number(dt.quantity),
          point_value: null,
        });
      });
    },
    setInitialLevel(data) {
      data.forEach((dt) => {
        this.selectedLevel.push(dt.level);
      });
    },
    setUserDetail(val) {
      if (this.type === "add") {
        return;
      }

      this.paymentStatus = val.transactions[0]?.status;
      this.formData.name = val.name;
      this.formData.email = val.email;

      // Role
      this.formData.role_id = val.role?.id;
      this.selectedRole = val.role;

      // Referral
      if (val.user_sponsor) {
        this.formData.referral_code = val.user_sponsor[0]?.sponsor?.code;
        this.selectedUser = {
          name: val.user_sponsor[0]?.sponsor?.name,
          code: val.user_sponsor[0]?.sponsor?.code,
          id: val.user_sponsor[0]?.sponsor?.id,
        };
      }

      // Heir
      this.formData.heir = val.user_infos[0]?.heir;

      // Level
      this.setInitialLevel(val.user_units);
      // this.formData.user_unit.level_id = val.user_units

      // set avatar url
      this.formData.avatar_url = val?.avatar_url;

      // Bank
      this.formData.address = val.user_infos[0]?.address;
      this.formData.card_id = val.user_infos[0]?.card_id;
      this.formData.city = val.user_infos[0]?.city;
      this.formData.district = val.user_infos[0]?.district;
      this.formData.phone = val.user_infos[0]?.phone;
      this.formData.postal_code = val.user_infos[0]?.postal_code;
      this.formData.province = val.user_infos[0]?.province;

      this.formData.bank_account_number =
        val.user_banks[0]?.bank_account_number;
      this.formData.bank_name = val.user_banks[0]?.bank_name;

      // this.getRefferalList();
      this.getTransactionDetail(val.id);
    },
    getCode() {
      let code = this.formData.name.replace(" ", "_");
      let codeNoDot = code.replace(".", "");
      let codeLowercase = codeNoDot.toLowerCase();
      return codeLowercase;
    },
    generatePassword() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = " ";
      const charactersLength = characters.length;
      for (let i = 0; i < 20; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      function containsNumbers(str) {
        return /\d/.test(str);
      }

      if (!containsNumbers(result)) {
        const randomNumber = Math.ceil(Math.random() * 10);
        result = result + randomNumber * randomNumber;
      }

      return result;
    },
    getTransactionPointValue() {
      let pointValue = 0;
      this.transactionList.forEach((dt) => {
        pointValue += dt.point_value || 0;
      });
      return pointValue;
    },
    getProductTransaction() {
      let data = [];
      this.transactionList.forEach((dt) => {
        data.push({
          product_id: dt.id,
          quantity: Number(dt.quantity),
        });
      });
      return data;
    },
    getSelectedLevel() {
      let data = [];
      this.selectedLevel.forEach((dt) => {
        data.push(dt.id);
      });
      return data;
    },
    formValidation() {
      if (!this.formData.email) {
        this.$toast.error("Email wajib diisi");
        return;
      }

      if (!this.formData.name) {
        this.$toast.error("Nama wajib diisi");
        return;
      }

      if (!this.formData.phone) {
        this.$toast.error("No. handphone wajib diisi");
        return;
      }

      if (!this.selectedRole) {
        this.$toast.error("Role wajib diisi");
        return;
      }

      if (!this.formData.heir) {
        this.$toast.error("Ahli waris wajib diisi");
        return;
      }

      if (!this.formData.card_id) {
        this.$toast.error("Card id wajib diisi");
        return;
      }

      if (!this.formData.province) {
        this.$toast.error("Provinsi wajib diisi");
        return;
      }

      if (!this.formData.city) {
        this.$toast.error("Kota wajib diisi");
        return;
      }

      if (!this.formData.address) {
        this.$toast.error("Alamat wajib diisi");
        return;
      }

      if (!this.formData.district) {
        this.$toast.error("Kelurahan wajib diisi");
        return;
      }

      if (!this.formData.postal_code) {
        this.$toast.error("Kode pos wajib diisi");
        return;
      }

      if (!this.formData.bank_account_number) {
        this.$toast.error("Nomor bank wajib diisi");
        return;
      }

      if (!this.formData.bank_name) {
        this.$toast.error("Nama bank wajib diisi");
        return;
      }

      if (this.type === "add") {
        this.createUser();
      } else {
        this.editUser();
      }
    },
    removeUnusedTransactionField() {
      this.transactionList.forEach((dt, index) => {
        if (!dt.id) {
          this.transactionList.splice(index, 1);
        }
      });
    },
    async createUser() {
      try {
        this.removeUnusedTransactionField();
        this.formData = {
          ...this.formData,
          password: this.generatePassword(),
          role_id: this.selectedRole?.id,
          referral_code: this.selectedUser?.code,
          user_unit: {
            ...this.formData.user_unit,
            level_id: this.getSelectedLevel(),
          },
          transaction: {
            ...this.formData.transaction,
            point_value_locking: this.getTransactionPointValue(),
            product_transactions: this.getProductTransaction(),
          },
        };
        await this.ApiGo({
          url: "api/register",
          formData: this.formData,
        }).post();
        this.$emit("createuser");
        // this.getTotalPage(response.data.total_page);
        this.$toast.success("User berhasil ditambahkan");
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    async editUser() {
      let formData = {
        name: this.formData.name,
        email: this.formData.email,
        avatar_url: this.formData.avatar_url,
        heir: this.formData.heir,
        role_id: this.selectedRole?.id,
        card_id: this.formData.card_id,
        phone: this.formData.phone,
        province: this.formData.province,
        city: this.formData.city,
        district: this.formData.district,
        address: this.formData.address,
        postal_code: Number(this.formData.postal_code),
        bank_name: this.formData.bank_name,
        bank_account_number: this.formData.bank_account_number,
      };

      try {
        await this.ApiGo({
          url: "api/users/" + this.userDetail.id,
          formData: formData,
        }).put();
        this.$emit("createuser");
        // this.getTotalPage(response.data.total_page);
        this.$toast.success("User berhasil diubah");
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    resetModalData() {
      this.formData = {
        email: null,
        password: null,
        name: null,
        role_id: null,
        card_id: null,
        phone: null,
        province: null,
        city: null,
        address: null,
        district: null,
        postal_code: null,
        bank_account_number: null,
        bank_name: null,
        user_unit: {
          level_id: [],
        },
        transaction: {
          point_value_locking: null,
          description: null,
          product_transactions: [],
        },
      };
      this.transactionList = [
        {
          id: null,
          regular_price: null,
          member_price: null,
          quantity: null,
          point_value: null,
        },
      ];
      this.selectedLevel = [];
      this.selectedRole = null;
      this.paymentStatus = null;
    },
  },
};
</script>

<style scoped>
.modaldetail {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.modaldetail__mask {
  position: fixed;
  background: black;
  opacity: 75%;
  width: 100vw;
  height: 100vh;
}

.modaldetail__box {
  padding: 0px 2rem 2rem 2rem;
  background-color: white;
  border-radius: 16px;
  z-index: 2;
  min-width: 55rem;
  height: auto;
  max-height: 600px;
  overflow-y: scroll;
}

.modaldetail__box-row-last {
  padding-bottom: 48px;
}

.modaldetail__box::-webkit-scrollbar {
  display: none;
}

.modaldetail__box-title {
  position: sticky;
  background: white;
  top: 0px;
  padding: 2rem 0 1rem 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modaldetail__box-row {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2rem;
}

.modaldetail__box-row-title {
  font-size: 14px;
  line-height: 16px;
  color: #707880;
}

.modaldetail__box-row-input {
  outline: none;
  border-radius: 8px;
  border: 1px solid #eef0f5;
  background: #f6fafd;
  padding: 8px;
}

.modaldetail__box-row-input::placeholder {
  font-size: 14px;
  line-height: 16px;
  color: darkgray;
}

.modaldetail__box-title-text {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.modaldetail__box-row-button {
  position: sticky;
  text-align: right;
  bottom: 0px;
  width: 100%;
}

.modaldetail__box-title-text-img {
  width: 26px;
  height: auto;
  position: absolute;
  right: 0px;
  cursor: pointer;
}

.modaldetail__box-row-image-box {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  border: 1px dashed black;
  border-radius: 8px;
}

.modaldetail__box-row-image-box-text {
  font-weight: bold;
}

.modaldetail__box-row-image-box-text_normal {
  font-weight: 400;
}

.modaldetail__box-row-image-box-file {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  opacity: 0;
}

.modaldetail__box-row-level {
  display: flex;
  border-radius: 8px;
  border: 1px solid #e7ecf5;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 16px;
  color: black;
  padding: 10px;
  width: fit-content;
  gap: 8px;
}

.modaldetail__box-row-level-wrapper {
  display: flex;
  gap: 1rem;
}

.modaldetail__box-row-level-remove {
  cursor: pointer;
  width: 12px;
}

.modaldetail__box-row-transaction {
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background: #19cb46;
  width: 9rem;
  margin-top: 10px;
}

.modaldetail__box-row-transaction-text {
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.modaldetail__box-row-transaction-product {
  display: flex;
  gap: 10px;
}

.modaldetail__box-row-transaction-product-component {
  display: flex;
  gap: 10px;
  padding: 16px 0px;
  margin: 16px 0px;
  border-bottom: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;
}

.modaldetail__box-row-transaction-product-component-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
}

.modaldetail__box-row-transaction-product-component-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  flex: 0.25;
}

.modaldetail__box-row-transaction-product-component-value {
  flex: 0.25;
}

.modaldetail__box-row-transaction-product-component-stock {
  flex: 0.2;
}

.modaldetail__box-row-transaction-product-component-delete {
  display: flex;
  flex: 0.05;
  align-items: center;
  justify-content: center;
}

.modaldetail__box-row-transaction-product-component-delete-img {
  width: 24px;
  cursor: pointer;
}

.modaldetail__box-row-transaction-product-component-value-wrapper {
  display: flex;
  gap: 10px;
}

.modaldetail__box-row-input-preview {
  outline: none;
  border-radius: 8px;
  border: 1px solid #eef0f5;
  background: #f6fafd;
  padding: 6px 12px;
  width: 100%;
}

.modaldetail__box-row-input-preview::placeholder {
  font-size: 14px;
  line-height: 16px;
  color: darkgray;
}

.modaldetail__box-row-transaction-product-component-text-stock {
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  flex: 0.2;
}

.modaldetail__box-row-transaction-product-component-text-delete {
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  flex: 0.05;
}

.modaldetail__box-row-transaction_gray {
  filter: grayscale(1);
}

.networktreecontent__list-body {
  display: flex;
  flex-direction: column;
  min-height: 220px;
  gap: 80px;
}

.networktreecontent__list-body-row_first {
  flex: 1;
}

.networktreecontent__list-body-row_second {
  flex: 0.5;
}

.networktreecontent__list-body-row_third {
  flex: 0.25;
}

.networktreecontent__list-body-row_fourth {
  flex: 0.125;
}

.networktreecontent__list-body-row-wrapper {
  display: flex;
  flex: 0.25;
}

.networktreecontent__list-body-row-line {
  position: absolute;
  height: 40px;
  bottom: -40px;
  width: 2px;
  background-color: black;
  z-index: 10;
}

.networktreecontent__list-body-row-line_top {
  position: absolute;
  height: 40px;
  top: -40px;
  width: 2px;
  background-color: black;
  z-index: 10;
}

.networktreecontent__list-body-row-line_connector {
  position: absolute;
  height: 2px;
  top: -40px;
  width: 100%;
  right: -50%;
  background-color: black;
  z-index: 10;
}
</style>
