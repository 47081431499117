<template>
  <div class="modaldetail">
    <div class="modaldetail__mask" @click="closeModal()"></div>
    <div class="modaldetail__box">
      <div class="modaldetail__box-title">
        <p class="modaldetail__box-title-text">Bonus Detail</p>
        <img
          src="@/assets/admin/icon/close.svg"
          alt="close"
          class="modaldetail__box-title-text-img"
          @click="closeModal()"
        />
      </div>
      <div>
        <div class="modaldetail__box-row-wrapper">
          <div class="modaldetail__box-row">
            <p class="modaldetail__box-row-title">Nama Bank</p>
            <p class="modaldetail__box-row-bank">
              {{ formData?.user_bank?.bank_name }}
            </p>
          </div>
          <div class="modaldetail__box-row">
            <p class="modaldetail__box-row-title">Nomor Bank</p>
            <p class="modaldetail__box-row-bank">
              {{ formData?.user_bank?.bank_account_number }}
            </p>
          </div>
        </div>
        <div class="modaldetail__box-row-wrapper">
          <div class="modaldetail__box-row">
            <p class="modaldetail__box-row-title">Bonus Cashback</p>
            <p>Rp {{ formData?.bonus_cashback ?? 0 }}</p>
          </div>
          <div class="modaldetail__box-row">
            <p class="modaldetail__box-row-title">Bonus Cashback RO</p>
            <p>Rp {{ formData?.bonus_cashback_ro ?? 0 }}</p>
          </div>
        </div>
        <div class="modaldetail__box-row-wrapper">
          <div class="modaldetail__box-row">
            <p class="modaldetail__box-row-title">Bonus Penjualan Awal</p>
            <p>Rp {{ formData?.bonus_initial_sales ?? 0 }}</p>
          </div>
          <div class="modaldetail__box-row">
            <p class="modaldetail__box-row-title">Bonus Kepemimpinan</p>
            <p>Rp {{ formData?.bonus_leadership ?? 0 }}</p>
          </div>
        </div>
        <div class="modaldetail__box-row-wrapper">
          <div class="modaldetail__box-row">
            <p class="modaldetail__box-row-title">Bonus Point Reward</p>
            <p>Rp {{ formData?.bonus_point_reward ?? 0 }}</p>
          </div>
          <div class="modaldetail__box-row">
            <p class="modaldetail__box-row-title">Bonus RO</p>
            <p>Rp {{ formData?.bonus_ro ?? 0 }}</p>
          </div>
        </div>
        <div class="modaldetail__box-row-wrapper">
          <div class="modaldetail__box-row">
            <p class="modaldetail__box-row-title">Bonus Royalti Kepemimpinan</p>
            <p>Rp {{ formData?.bonus_royalty_leadership ?? 0 }}</p>
          </div>
          <div class="modaldetail__box-row">
            <p class="modaldetail__box-row-title">Bonus Royalti RO</p>
            <p>Rp {{ formData?.bonus_royalty_ro ?? 0 }}</p>
          </div>
        </div>
        <div class="modaldetail__box-row-wrapper">
          <div class="modaldetail__box-row">
            <p class="modaldetail__box-row-title">Total Bonus</p>
            <p>Rp {{ formData?.total_bonus ?? 0 }}</p>
          </div>
        </div>
      </div>
      <div class="modaldetail__box-row-date">
        <p>Pilih Range Waktu</p>
        <date-picker v-model="dateRange" range />
      </div>
      <div class="modaldetail__box-row-date">
        <p>Pilih Tanggal Bonus</p>
        <Dropdown
          :options="dateBatch"
          @selected="dateBatchSelected"
          name="Pilih Hari"
          placeholder="Pilih tanggal bonus yang mau dilihat"
          :disabled="dateBatch.length > 0 ? false : true"
        >
        </Dropdown>
        <div class="modaldetail__box-row-level-wrapper" v-if="selectedDate">
            <div class="modaldetail__box-row-level">
              <p>{{ selectedDate.name }}</p>
              <img
                src="@/assets/admin/icon/close.svg"
                alt="close"
                class="modaldetail__box-row-level-remove"
                @click="removeDate()"
              />
            </div>
        </div>
      </div>
      <div class="modaldetail__box-row-wrapper" v-if="selectedDate?.name">
          <div class="modaldetail__box-row">
            <p class="modaldetail__box-row-title">Total bonus pada tanggal {{ selectedDate?.name }}</p>
            <p>Rp {{ formatNumber(totalBonusDaily) ?? 0 }}</p>
          </div>
        </div>
      <div class="modaldetail__list-table-header">
        <p class="modaldetail__list-table-header-title">Tanggal Dibuat</p>
        <p class="modaldetail__list-table-header-title">Status</p>
        <p class="modaldetail__list-table-header-title">Tipe</p>
        <p class="modaldetail__list-table-header-title">Bonus</p>
      </div>
      <div class="modaldetail__list-table-body">
        <div v-if="dataBonus?.length > 0">
          <div
            class="modaldetail__list-table-body-user"
            v-for="(data, index) in dataDetailBonus"
            :key="index"
          >
            <p class="modaldetail__list-table-body-content">
              {{ moment.utc(data?.created_at).format("DD MMM YYYY") }}
            </p>
            <div class="modaldetail__list-table-body-status-wrapper">
              <div
                class="modaldetail__list-table-body-status-box"
                :class="
                  data.status === 'PAID'
                    ? 'modaldetail__list-table-body-status-box_paid'
                    : data.status === 'PENDING'
                    ? 'modaldetail__list-table-body-status-box_pending '
                    : ''
                "
              >
                <p class="modaldetail__list-table-body-status">
                  {{ data.status }}
                </p>
              </div>
            </div>
            <p class="modaldetail__list-table-body-content">
              {{ data?.type }}
            </p>
            <p class="modaldetail__list-table-body-content">
              Rp. {{ formatNumber(data?.bonus) || 0 }}
            </p>
          </div>
        </div>
        <div class="modaldetail__list-table-body-user_no-data" v-else>
          <p class="modaldetail__list-table-body-user_no-data-text">
            Tidak ada data
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CustomButton from "@/components/atoms/CustomButton.vue";
import Dropdown from "@/components/molecules/CustomDropdown.vue";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    // CardTree,
    // CustomButton,
    Dropdown,
    DatePicker,
  },
  props: {
    userDetail: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      moment: moment,
      dateRange: null,
      formData: {
        id: null,
        name: null,
        code: null,
        bonus_initial_sales: null,
        bonus_leadership: null,
        bonus_cashback: null,
        bonus_point_reward: null,
        bonus_royalty_leadership: null,
        bonus_cashback_ro: null,
        bonus_ro: null,
        bonus_royalty_ro: null,
        total_bonus: null,
        user_bank: null,
      },
      dataBonus: [],
      dataDetailBonus: [],
      paginationOption: {
        limitPage: 10,
        totalPage: 1,
        currentPage: 1,
      },
      dateBatch: [],
      totalBonusDaily: null,
      selectedDate: null
    };
  },
  watch: {
    userDetail(val) {
      if (val) {
        this.setUserDetail();
      }
    },
    dateRange() {
      this.getDetailBonus(this.formData?.id);
    },
    selectedDate(val) {
      if (val) {
        this.getDetailBonusUser()
      }
    }
  },
  async mounted() {
    if (this.type === "add") {
      this.resetModalData();
    }

    this.setUserDetail(this.userDetail);
  },
  methods: {
    dateBatchSelected(val) {
      this.selectedDate = val;
    },
    removeDate(){
      this.selectedDate = null;
    },
    formatDate(date) {
      return moment(date)
        .utcOffset("+07:00")
        .format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
    },
    getDetailBonusUser() {
      const selectedDetail = this.dataBonus.filter(dt => dt.batch === this.selectedDate.value)
      this.totalBonusDaily = selectedDetail?.[0]?.total_bonus
      this.dataDetailBonus = selectedDetail?.[0]?.detail_bonus
    },
    getDateBatchList(data) {
      const dateList = data.map((dt) => dt.batch);
      if (dateList.length === 0) {
        this.$toast.error("Tidak ada data bonus di tanggal tersebut");
        return;
      }

      this.dateBatch = [];
      this.totalBonusDaily = null
      dateList.forEach((dt, index) => {
        this.dateBatch.push({
          id: index,
          name: moment(dt).format("DD MMM YYYY") ,
          value: dt
        });
      });
    },
    async getDetailBonus(id) {
      try {
        const params = {
          start_date: this.dateRange?.[0]
            ? this.formatDate(new Date(this.dateRange?.[0]))
            : "",
          end_date: this.dateRange?.[1]
            ? this.formatDate(new Date(this.dateRange?.[1]))
            : "",
        };
        const response = await this.ApiGo({
          url: `api/users/${id}/bonus/detail/batch`,
          formData: params,
        }).post();

        this.dataBonus = response?.body?.data?.user_bonus || [];
        this.getDateBatchList(this.dataBonus);
        this.paginationOption.totalPage = Math.ceil(
          response?.body?.data?.count_bonus / this.paginationOption.limitPage
        );
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },

    setUserDetail(val) {
      this.formData = {
        ...this.formData,
        id: val?.id,
        name: val?.name,
        code: val?.code,
        bonus_initial_sales: val?.bonus_initial_sales,
        bonus_leadership: val?.bonus_leadership,
        bonus_cashback: val?.bonus_cashback,
        bonus_point_reward: val?.bonus_point_reward,
        bonus_royalty_leadership: val?.bonus_royalty_leadership,
        bonus_cashback_ro: val?.bonus_cashback_ro,
        bonus_ro: val?.bonus_ro,
        bonus_royalty_ro: val?.bonus_royalty_ro,
        total_bonus: val?.total_bonus,
        user_bank: val?.user_bank,
      };
    },

    closeModal() {
      this.$emit("closeModal");
    },

    resetModalData() {
      this.formData = {
        email: null,
        password: null,
        name: null,
        role_id: null,
        card_id: null,
        phone: null,
        province: null,
        city: null,
        address: null,
        district: null,
        postal_code: null,
        bank_account_number: null,
        bank_name: null,
        user_unit: {
          level_id: [],
        },
        transaction: {
          point_value_locking: null,
          description: null,
          product_transactions: [],
        },
      };
      this.transactionList = [
        {
          id: null,
          regular_price: null,
          member_price: null,
          quantity: null,
          point_value: null,
        },
      ];
      this.selectedLevel = [];
      this.selectedRole = null;
      this.paymentStatus = null;
    },

    setCurrentPage(page) {
      this.paginationOption.currentPage = page;
      this.getDetailBonus(this.formData?.id);
    },
  },
};
</script>

<style scoped>
.modaldetail {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.modaldetail__mask {
  position: fixed;
  background: black;
  opacity: 75%;
  width: 100vw;
  height: 100vh;
}

.modaldetail__box {
  padding: 0px 2rem 2rem 2rem;
  background-color: white;
  border-radius: 16px;
  z-index: 2;
  min-width: 55rem;
  height: auto;
  max-height: 600px;
  overflow-y: scroll;
}

.modaldetail__box-row-last {
  padding-bottom: 48px;
}

.modaldetail__box::-webkit-scrollbar {
  display: none;
}

.modaldetail__box-title {
  position: sticky;
  background: white;
  top: 0px;
  padding: 2rem 0 1rem 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modaldetail__box-row-wrapper {
  display: flex;
  gap: 0.5rem;
  width: 100%;
}

.modaldetail__box-row {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2rem;
  flex: 0.5;
}

.modaldetail__box-row-title {
  font-size: 14px;
  line-height: 16px;
  color: #707880;
}

.modaldetail__box-row-input {
  outline: none;
  border-radius: 8px;
  border: 1px solid #eef0f5;
  background: #f6fafd;
  padding: 8px;
}

.modaldetail__box-row-input::placeholder {
  font-size: 14px;
  line-height: 16px;
  color: darkgray;
}

.modaldetail__box-title-text {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.modaldetail__box-row-button {
  position: sticky;
  text-align: right;
  bottom: 0px;
  width: 100%;
}

.modaldetail__box-title-text-img {
  width: 26px;
  height: auto;
  position: absolute;
  right: 0px;
  cursor: pointer;
}

.modaldetail__box-row-image-box {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  border: 1px dashed black;
  border-radius: 8px;
}

.modaldetail__box-row-image-box-text {
  font-weight: bold;
}

.modaldetail__box-row-image-box-text_normal {
  font-weight: 400;
}

.modaldetail__box-row-image-box-file {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  opacity: 0;
}

.modaldetail__box-row-level {
  display: flex;
  border-radius: 8px;
  border: 1px solid #e7ecf5;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 16px;
  color: black;
  padding: 10px;
  width: fit-content;
  gap: 8px;
}

.modaldetail__box-row-level-wrapper {
  display: flex;
  margin-top: 1rem;
}

.modaldetail__box-row-level-remove {
  cursor: pointer;
  width: 12px;
}

.modaldetail__box-row-transaction {
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background: #19cb46;
  width: 9rem;
  margin-top: 10px;
}

.modaldetail__box-row-transaction-text {
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.modaldetail__box-row-transaction-product {
  display: flex;
  gap: 10px;
}

.modaldetail__box-row-transaction-product-component {
  display: flex;
  gap: 10px;
  padding: 16px 0px;
  margin: 16px 0px;
  border-bottom: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;
}

.modaldetail__box-row-transaction-product-component-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
}

.modaldetail__box-row-transaction-product-component-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  flex: 0.25;
}

.modaldetail__box-row-transaction-product-component-value {
  flex: 0.25;
}

.modaldetail__box-row-transaction-product-component-stock {
  flex: 0.2;
}

.modaldetail__box-row-transaction-product-component-delete {
  display: flex;
  flex: 0.05;
  align-items: center;
  justify-content: center;
}

.modaldetail__box-row-transaction-product-component-delete-img {
  width: 24px;
  cursor: pointer;
}

.modaldetail__box-row-transaction-product-component-value-wrapper {
  display: flex;
  gap: 10px;
}

.modaldetail__box-row-input-preview {
  outline: none;
  border-radius: 8px;
  border: 1px solid #eef0f5;
  background: #f6fafd;
  padding: 6px 12px;
  width: 100%;
}

.modaldetail__box-row-input-preview::placeholder {
  font-size: 14px;
  line-height: 16px;
  color: darkgray;
}

.modaldetail__box-row-transaction-product-component-text-stock {
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  flex: 0.2;
}

.modaldetail__box-row-transaction-product-component-text-delete {
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  flex: 0.05;
}

.modaldetail__box-row-transaction_gray {
  filter: grayscale(1);
}

.networktreecontent__list-body {
  display: flex;
  flex-direction: column;
  min-height: 220px;
  gap: 80px;
}

.networktreecontent__list-body-row_first {
  flex: 1;
}

.networktreecontent__list-body-row_second {
  flex: 0.5;
}

.networktreecontent__list-body-row_third {
  flex: 0.25;
}

.networktreecontent__list-body-row_fourth {
  flex: 0.125;
}

.networktreecontent__list-body-row-wrapper {
  display: flex;
  flex: 0.25;
}

.networktreecontent__list-body-row-line {
  position: absolute;
  height: 40px;
  bottom: -40px;
  width: 2px;
  background-color: black;
  z-index: 10;
}

.networktreecontent__list-body-row-line_top {
  position: absolute;
  height: 40px;
  top: -40px;
  width: 2px;
  background-color: black;
  z-index: 10;
}

.networktreecontent__list-body-row-line_connector {
  position: absolute;
  height: 2px;
  top: -40px;
  width: 100%;
  right: -50%;
  background-color: black;
  z-index: 10;
}

.modaldetail__list-table-header {
  position: sticky;
  display: flex;
  margin-top: 1rem;
  background-color: #eef2f7;
  padding: 0.95rem;
  gap: 0.5rem;
  top: 70px;
}

.modaldetail__list-table-header-title {
  width: 25%;
}

.modaldetail__list-table-body {
  display: flex;
  flex-direction: column;
  padding: 0.95rem 0;
}

.modaldetail__list-table-body-user {
  cursor: pointer;
  padding: 0.95rem;
  display: flex;
  border-bottom: 1px solid #dee2e6;
  gap: 0.5rem;
  align-items: center;
}

.modaldetail__list-table-body-user:hover {
  background-color: #e9eef2;
}

.modaldetail__list-table-body-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 25%;
}

.modaldetail__list-table-body-status-wrapper {
  width: 25%;
}

.modaldetail__list-table-body-status-box {
  width: fit-content;
  padding: 8px 16px;
  border-radius: 10px;
  background: #fa5c7c;
}

.modaldetail__list-table-body-status-box_pending {
  background-color: #f7b011;
}

.modaldetail__list-table-body-status-box_paid {
  background-color: #19cb46;
}

.modaldetail__list-table-body-user_no-data-text {
  text-align: center;
  padding: 16px 0px 16px 0px;
}

.modaldetail__list-table-body-status {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-weight: bold;
}

.modaldetail__box-row-bank {
  font-weight: bold;
}

.bonususercontent__list-pagination {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.bonususercontent__list-pagination-chevron {
  cursor: pointer;
}

.bonususercontent__list-pagination-number {
  cursor: pointer;
  width: 1.5rem;
}

.bonususercontent__list-pagination-number_bold {
  font-weight: bold;
}

.bonususercontent__list-pagination-first {
  display: flex;
  gap: 1rem;
}

.modaldetail__box-row-date {
  margin: 16px 0px;
}
</style>
