<template>
  <div class="buttonmlm" :style="parentStyleProps">
    <button
      class="buttonmlm__box"
      @click="$emit('buttonClicked')"
      :style="styleProps"
    >
      <p class="buttonmlm__box-text" v-if="buttonDisplay">{{ buttonText }}</p>
      <img class="buttonmlm__box-img" :src="image" v-if="image" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    padding: {
      type: String,
      default: null,
    },
    paddingLeft: {
      type: String,
      default: null,
    },
    paddingTop: {
      type: String,
      default: null,
    },
    paddingRight: {
      type: String,
      default: null,
    },
    paddingBottom: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
    flex: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    buttonDisplay: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    parentStyleProps() {
      return {
        flex: this.flex
      };
    },
    styleProps() {
      return {
        "background-color": this.backgroundColor,
        padding: this.padding,
        "padding-left": this.paddingLeft,
        "padding-top": this.paddingTop,
        "padding-right": this.paddingRight,
        "padding-bottom": this.paddingBottom,
        width: this.width,
        height: this.height
      };
    },
  },
};
</script>

<style scoped>
.buttonmlm__box {
  cursor: pointer;
  width: fit-content;
  padding: 16px 32px;
  border-radius: 10px;
  background-color: #19cb46;
  border: none;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2);
}

.buttonmlm__box-text {
  font-weight: 700;
  color: white;
}

.buttonmlm__box-img {
  width: 25px;
  height: 25px;
}
</style>
