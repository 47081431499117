<template>
  <section class="design_area p_120" id="about">
    <div class="container">
      <!-- <div class="design_inner row"> -->
      <!-- <div class="design_inner row" v-for="data in compro" :key="data.id"> -->
      <!-- <div class="col-lg-5">
          <img
            class="img-fluid"
            v-bind:src="getGoogleDriveImage(data.image_url)"
            style="width: 458px; height: 679px"
          />
        </div> -->
      <!-- <div class="col-lg-7"> -->
      <div class="main_title" v-for="data in compro" :key="data.id">
        <h2 >Apa Itu Ghani ?</h2>
        <h4 style="color:#059240 ;">{{ data.description }}</h4>
        <p>
          <!-- {{ data.description.substring(0, 122) + ".." }} -->
        </p>
        <!-- <a class="main_btn" href="/about">Tentang GHANI</a> -->
      </div>

      <div class="row">
        <div class="col-3">
          <img src="@/assets/ICON TRAINING (1).svg" alt="" style="display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;">
          <h4 style="text-align: center; color:#059240;">Pelatihan</h4>
          <p style="text-align: center; color:#059240;">Setiap Mitra Berhak Mendapatkan Pelatihan Bisnis Berkualitas Dari Kami Melalui Mentor Bisnis Terbaik.</p>
        </div>
        <div class="col-3">
          <img src="@/assets/ICON FLEKSIBEL (1).svg" alt="" style="display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;">
          <h4 style="text-align: center; color:#059240;">Flexible</h4>
          <p style="text-align: center; color:#059240;">Tidak Ada Batas Waktu Untuk Berbisnis Bagi Mitra Ghani, Setiap Saat Dapat Dilakukan Dimana Pun Dan Kapanpun.</p>
        </div>
        <div class="col-3">
          <img src="@/assets/ICON PELUANG (1).svg" alt="" style="display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;">
          <h4 style="text-align: center; color:#059240;">Peluang</h4>
          <p style="text-align: center; color:#059240;">Peluang Mendapatkan Income Yang Tak Terbatas Berhak Kamu Dapatkan Sebagai Mitra Ghani, Kebebasan Finansial Berada Ditangan Mu.</p>
        </div>
        <div class="col-3">
          <img src="@/assets/ICON reward (1).svg" alt="" style="display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;">
          <h4 style="text-align: center; color:#059240;">Reward</h4>
          <p style="text-align: center; color:#059240; ">Kesempatan Mendapatkan Reward Berulang-Ulang Dengan Nilai Yang Cukup Besar Serta Menerima Pembayaran Cepat</p>
        </div>
      </div>
      <!-- </div> -->
      <!-- </div> -->
    </div>
  </section>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      compro: [],
      err: "",
    };
  },
  mounted() {
    this.fetchCompro();
  },

  methods: {
    async fetchCompro() {
      try {
        const params = {
          limit: 10,
          offset: 0,
          order_by: {
            id: "asc",
          },
        };
        const response = await this.ApiCompro({
          url: "api/rest/compro/companies",
          formData: params,
        }).post();
        this.compro = response.data.company_companies;
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
  },
};
</script>

<style></style>
