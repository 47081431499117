<template>
  <div class="companycontent">
    <div class="companycontent__list">
      <div class="companycontent__list-menu">
        <div class="companycontent__list-search">
          <input v-model="searchInput" type="text" class="companycontent__list-search-input" placeholder="Cari data..." />
          <button type="submit" class="companycontent__list-search-button" @click="searchData()">
            <img src="@/assets/admin/icon/search.png" alt="search" class="companycontent__list-search-button-img" />
          </button>
        </div>
        <div class="companycontent__list-dropdown">
          <div class="companycontent__list-dropdown-box" @click="toggleSort()" v-click-outside="closeSort">
            <p class="companycontent__list-dropdown-box-text">
              {{ selectedSort?.name }}
            </p>
            <img src="@/assets/admin/icon/sort-asc-black.png" alt="search" class="companycontent__list-search-button-img"
              v-if="selectedSort?.sortAsc" />
            <img src="@/assets/admin/icon/sort-desc-black.png" alt="search" class="companycontent__list-search-button-img"
              v-else />
            <div class="companycontent__list-dropdown-box-content" v-if="sortOpened">
              <p class="companycontent__list-dropdown-box-content-text" :class="index === sortList.length - 1
                  ? 'companycontent__list-dropdown-box-content-text_no-bottom'
                  : ''
                " v-for="(dt, index) in sortList" :key="index" @click="setSelectedSort(dt)">
                {{ dt.name }}
              </p>
            </div>
          </div>
          <button type="submit" class="companycontent__list-search-button">
            <img src="@/assets/admin/icon/filter.png" alt="search" class="companycontent__list-search-button-img" />
          </button>
        </div>
        <div class="companycontent__list-adddelete">
          <CustomButton :button-text="'Add'" @buttonClicked="showAddModal(), setType('add')"></CustomButton>
        </div>
      </div>
      <div class="companycontent__list-table">
        <div class="companycontent__list-table-header">
          <p class="companycontent__list-table-header-no">No</p>
          <p class="companycontent__list-table-header-name">Name</p>
          <p class="companycontent__list-table-header-image">Image</p>
          <p class="companycontent__list-table-header-active">Is Active</p>
          <p class="companycontent__list-table-header-address">Address</p>
          <p class="companycontent__list-table-header-phone">Phone</p>
          <p class="companycontent__list-table-header-description">Description</p>
          <p class="companycontent__list-table-header-action">Action</p>
        </div>
        <div class="companycontent__list-table-body">
          <div v-if="validData && validData.length > 0">
            <div class="companycontent__list-table-body-user" v-for="(company, index) in validData" :key="index">
              <p class="companycontent__list-table-body-no" @click="setDetailCompany(company)">{{ index + 1 }}</p>
              <p class="companycontent__list-table-body-name" @click="setDetailCompany(company)">
                {{ company.name }}
              </p>
              <p class="companycontent__list-table-body-image" @click="setDetailCompany(company)">
                <img class="companycontent__list-table-body-image-url" :src="getGoogleDriveImage(company.image_url)"
                  alt="company image" />
              </p>
              <p class="companycontent__list-table-body-active" @click="setDetailCompany(company)">
                {{ company.is_active ? "Yes" : "No" }}
              </p>
              <p class="companycontent__list-table-body-address" @click="setDetailCompany(company)">
                {{ company.address }}
              </p>
              <p class="companycontent__list-table-body-phone" @click="setDetailCompany(Company)">
                {{ company.phone }}
              </p>
              <p class="companycontent__list-table-body-description" @click="setDetailCompany(Company)">
                {{ company.description }}
              </p>

              <div class="companycontent__list-table-body-action">
                <CustomButton :button-display="false" :button-text="'Edit'" :background-color="'#0099CC'"
                  :padding-top="'0.5rem'" :padding-bottom="'0.5rem'" :image="require('@/assets/admin/icon/edit.png')"
                  @buttonClicked="editCompany(company)"></CustomButton>
                <CustomButton :button-display="false" :button-text="'Delete'" :background-color="'#FA5C7C'"
                  :padding-top="'0.5rem'" :padding-bottom="'0.5rem'" :image="require('@/assets/admin/icon/trash.svg')"
                  @buttonClicked="showModalConfirmation(company.id)"></CustomButton>
              </div>
            </div>
          </div>
          <div class="companycontent__list-table-body-user_no-data" v-else>
            <p class="companycontent__list-table-body-user_no-data-text">
              Tidak ada data
            </p>
          </div>
        </div>
      </div>
      <div class="companycontent__list-pagination">
        <!-- Go Back -->
        <div class="companycontent__list-pagination-chevron" @click="setCurrentPage(paginationOption.currentPage - 1)"
          v-if="paginationOption.currentPage - 1 > 0">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
          </svg>
        </div>

        <div class="companycontent__list-pagination-first" v-if="paginationOption.currentPage > 2">
          <p class="companycontent__list-pagination-number" @click="setCurrentPage(1)">
            1
          </p>
          <p class="companycontent__list-pagination-number" v-if="paginationOption.currentPage > 3">
            . . .
          </p>
        </div>

        <!-- Previous Page -->
        <p class="companycontent__list-pagination-number" v-if="paginationOption.currentPage - 1 > 0"
          @click="setCurrentPage(paginationOption.currentPage - 1)">
          {{ paginationOption.currentPage - 1 }}
        </p>

        <!-- Current Page -->
        <p class="companycontent__list-pagination-number companycontent__list-pagination-number_bold">
          {{ paginationOption.currentPage }}
        </p>

        <!-- Next Page -->
        <p class="companycontent__list-pagination-number"
          v-if="paginationOption.currentPage + 1 < paginationOption.totalPage"
          @click="setCurrentPage(paginationOption.currentPage + 1)">
          {{ paginationOption.currentPage + 1 }}
        </p>

        <div class="companycontent__list-pagination-first"
          v-if="paginationOption.currentPage < paginationOption.totalPage">
          <p class="companycontent__list-pagination-number" v-if="paginationOption.currentPage + 3 <= paginationOption.totalPage
            ">
            . . .
          </p>
          <p class="companycontent__list-pagination-number" @click="setCurrentPage(paginationOption.totalPage)">
            {{ paginationOption.totalPage }}
          </p>
        </div>

        <!-- Go Next -->
        <div class="companycontent__list-pagination-chevron" @click="setCurrentPage(paginationOption.currentPage + 1)"
          v-if="paginationOption.currentPage < paginationOption.totalPage">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </div>
      </div>
    </div>
    <ModalDetailVue v-if="statusAddModal" :company-detail="detailCompany" :type="type" @closeModal="hideAddModal"
      @createcompany="onCompanyCreated()"></ModalDetailVue>
    <ModalConfirmation v-if="statusModalConfirmation" :title="'Apakah anda ingin menghapus Data ini ?'"
      @yesClicked="deleteCompany" @closeModal="hideModalConfirmation"></ModalConfirmation>

  </div>
</template>
  
<script>
import CustomButton from "@/components/atoms/CustomButton.vue";
import ClickOutside from "vue-click-outside";
import ModalDetailVue from "./ModalDetail.vue";
import ModalConfirmation from "@/components/molecules/ModalConfirmation.vue";

export default {
  name: "NavigationBar",
  components: {
    CustomButton,
    ModalDetailVue,
    ModalConfirmation,
  },
  data() {
    return {
      companyList: [],
      resultSearch: null,
      searchInput: null,
      selectedFilter: null,
      filterOpened: false,
      filterList: [
        {
          name: "Company Name",
          code: "name",
        },
        {
          name: "Id",
          code: "id",
        },
      ],
      paginationOption: {
        limitPage: 10,
        totalPage: 1,
        currentPage: 1,
      },

      selectedSort: {
        name: "Company Name",
        order_by: "name",
        sortAsc: false,
      },
      sortOpened: false,
      sortList: [
        {
          name: "Id",
          order_by: "id",
          sortAsc: false,
        },
        {
          name: "Company Name",
          order_by: "name",
          sortAsc: false,
        },
        {
          name: "Date",
          order_by: "created_at",
          sortAsc: false,
        },
      ],
      statusAddModal: false,
      detailCompany: {},
      type: 'preview',
      buttonHovered: false,
      totalPage: null,
      statusModalConfirmation: false,
      savedId: null

    };
  },
  mounted() {
    this.getCompanyList();
    this.setInitialFilter();
  },
  methods: {
    setDetailCompany(company) {
      this.detailCompany = company
      this.setType('preview')
      this.showAddModal()
    },
    setInitialFilter() {
      this.selectedFilter = this.filterList[0];
    },
    setInitialSort() {
      this.selectedSort = this.sortList[0];
    },
    setSelectedFilter(params) {
      this.selectedFilter = params;
    },
    setSelectedSort(params) {
      if (this.selectedSort.order_by === params.order_by) {
        this.selectedSort.sortAsc = !this.selectedSort.sortAsc;
      } else {
        this.selectedSort = params;
      }

      this.getCompanyList();
    },
    showAddModal() {
      this.statusAddModal = true;
    },
    hideAddModal() {
      this.statusAddModal = false;
    },
    showModalConfirmation(id) {
      this.savedId = id;
      this.statusModalConfirmation = true;
    },
    hideModalConfirmation() {
      this.statusModalConfirmation = false;
    },

    setType(val) {
      this.type = val
    },
    async getCompanyList() {
      try {
        const params = {
          limit: this.paginationOption.limitPage,
          offset: this.paginationOption.currentPage - 1,
          order_by: {
            [this.selectedSort.order_by]: this.selectedSort.sortAsc ? "asc" : "desc",
          },
          // order_sort: this.selectedSort.sortAsc ? "asc" : "desc",
        };
        const response = await this.Api({
          url: "api/rest/compro/companies",
          formData: params,
        }).post();
        this.paginationOption.totalPage = Math.ceil(response?.body?.companies_aggregate?.aggregate?.count / this.paginationOption.limitPage)
        this.totalPage = this.paginationOption.totalPage;
        this.companyList = response.body.company_companies;

        // this.getTotalPage(response.data.total_page);
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    editCompany(company) {
      this.detailCompany = company
      this.setType('edit')
      this.showAddModal()
    },
    async deleteCompany() {
      try {
        const response = await this.Api({
          url: `api/rest/compro/companies/${this.savedId}`
        }).delete();

        if (response) {
          this.$toast.success("Data company berhasil dihapus")
          this.getCompanyList();
        }
      } catch(err) {
        this.$toast.error("Gagal menghapus Data");
      }finally {
        this.hideModalConfirmation()
      }

    },
    getTotalPage(totalData) {
      this.paginationOption.totalPage = Math.ceil(
        totalData / this.paginationOption.limitPage
      );
    },
    searchData() {
      const lowerCaseinput = this.searchInput?.toLowerCase();
      if (lowerCaseinput) {
  
        this.resultSearch = this.CompanyList.filter((dt) =>
          String(dt[this.selectedFilter.code])
            .toLowerCase()
            .includes(lowerCaseinput)
        );
        this.paginationOption.totalPage = 1;
      } else {
        this.resultSearch = null;
        this.paginationOption.totalPage = this.totalPage;
      }
    },
    toggleFilter() {
      this.filterOpened = !this.filterOpened;
    },
    closeFilter() {
      this.filterOpened = false;
    },
    toggleSort() {
      this.sortOpened = !this.sortOpened;
    },
    closeSort() {
      this.sortOpened = false;
    },
    setCurrentPage(page) {
      this.paginationOption.currentPage = page;
      this.getCompanyList();
    },
    resetSortList() {
      this.sortList.forEach((dt) => {
        dt.sortAsc = true;
      });
    },
    onCompanyCreated() {
      this.hideAddModal();
      this.getCompanyList();
    },
  },
  computed: {
    validData() {
      return this.resultSearch ? this.resultSearch : this.companyList;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
  
<style>
@import "./companycontent.css";
</style>
  