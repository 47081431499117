<template>
  <div class="main">
    <Header></Header>
    <Hero></Hero>

    <!--================About Area =================-->
    <section class="our_blog_area p_120">
      <div class="container">
        <div class="main_title_product">
          <h2>Daftar Produk yang tersedia kami sajikan untuk anda !</h2>
        </div>
        <div class="blog_inner row">
          <div class="col-lg-4 mt-4" v-for="data in products" :key="data.id">
            <div class="o_blog_item">
              <div class="blog_img">
                <img
                  class="img-fluid"
                  v-if="data.product_images.length > 0"
                  v-bind:src="
                  data.product_images[0].image_url
                  "
                  alt=""
                />
              </div>
              <div class="blog_text">
                <div class="blog_cat"></div>
                <router-link class="nav-link" :to="'/products/' + data.slug"
                  ><h4>
                    {{ data.name }}
                  </h4></router-link
                >
                <p class="ml-3">
                  {{ data.description.substring(0, 100) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--================About Area END =================-->

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/compro/Header.vue";
import Hero from "@/components/compro/Hero.vue";
import Footer from "@/components/compro/Footer.vue";

export default {
  name: "ProdukPageList",
  components: {
    Header,
    Hero,
    Footer,
  },
  data() {
    return {
      products: [],
      err: "",
    };
  },
  mounted() {
    this.fetchProducts();
  },
  methods: {
    async fetchProducts() {
      try {
        const params = {
          limit: 20,
          offset: 0,
          order: {
            id: "desc",
          },
          filter:"a3c87d4c-34ce-4612-a338-d39d6bec0409",
        };
        const response = await this.ApiCompro({
          url: "api/rest/products-filters",
          formData: params,
        }).post();
        this.products = response.data.products;
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
  },
};
</script>

<style></style>
