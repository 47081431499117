<template>
  <section class="feature_area">
    <div class="container">
      <div class="main_title_paket ">
        <br>
        <br>
        <br>
        <br>
        <h2>PILIHAN PAKET PRODUK MITRA</h2>
        <p>
          Pilihan Paket Produk Mitra Yang bisa Dipilih
        </p>
      </div>
      <div class="feature_inner row">
        <div class="col-lg-4 mt-4" v-for="data in packets" :key="data.id">
          <div class="feature_item">
            <img
              v-bind:src="getGoogleDriveImage(data.image_url)"
              style="width:150px"
              alt=""
            />
            <h4>{{ data.name }}</h4>
            <p>
              {{ data.description }}
            </p>
            <a class="main_btn" href="/packages">Lihat Paket</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PaketProduk",
  data() {
    return {
      packets: [],
      err: "",
    };
  },
  mounted() {
    this.fetchPackets();
  },
  methods: {
    async fetchPackets() {
      try {
        const params = {
          limit: 10,
          offset: 0,
          order_by: {
            point_value: "desc",
          },
        };
        const response = await this.ApiCompro({
          url: "api/rest/compro/levels",
          formData: params,
        }).post();
        this.packets = response.data.levels;
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
  },
};
</script>
<style></style>
