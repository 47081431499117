<template>
  <div class="modaldetail">
    <div class="modaldetail__mask" @click="closeModal()"></div>
    <div class="modaldetail__box">
      <div class="modaldetail__box-title">
        <p class="modaldetail__box-title-text">News Detail</p>
        <img
          src="@/assets/admin/icon/close.svg"
          alt="close"
          class="modaldetail__box-title-text-img"
          @click="closeModal()"
        />
      </div>
      <div>
        <div class="modaldetail__box-row">
          <UploadImageCover
            :title="'News'"
            :initialImage="[formData.cover_url]"
          ></UploadImageCover>
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Title</p>
          <input
            v-model="formData.title"
            class="modaldetail__box-row-input"
            placeholder="Judul Berita"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Description</p>
          <textarea
            v-model="formData.description"
            class="modaldetail__box-row-input"
            placeholder="deskripsi Berita"
          ></textarea>
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Author</p>
          <Dropdown
            :options="userList"
            @selected="userSelected"
            name="user Id"
            placeholder="Pilih author"
            :disabled="selectedUser ? true : false"
          >
          </Dropdown>
          <div class="modaldetail__box-row-level-wrapper" v-if="selectedUser">
            <div class="modaldetail__box-row-level">
              <p>{{ selectedUser.name }}</p>
              <img
                src="@/assets/admin/icon/close.svg"
                alt="close"
                class="modaldetail__box-row-level-remove"
                @click="removeUser()"
              />
            </div>
          </div>
        </div>
        <div class="modaldetail__box-row-button" v-if="type !== 'preview'">
          <CustomButton
            :button-text="capitalizeFirstLetter(type)"
            @buttonClicked="formValidation()"
          ></CustomButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/atoms/CustomButton.vue";
import UploadImageCover from "@/components/molecules/UploadCover.vue";
import Dropdown from "@/components/molecules/CustomDropdown.vue";

export default {
  components: {
    CustomButton,
    UploadImageCover,
    Dropdown,
  },
  props: {
    newsDetail: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formData: {
        title: null,
        slug: null,
        // is_active: false,
        // additional_note: null,
        cover_url: "",
        user_id: null,
        description: null,
      },
      userList: [
        {
          id: 1,
          name: null,
        },
      ],
      selectedUser: null,
    };
  },
  watch: {
    newsDetail(val) {
      if (val) {
        this.setNewsDetail();
      }
    },
  },
  created() {
    this.$bus.$on("sent-image-cover-checked", (status) => {
      if (status) {
        this.getCoverUrl();
      } else {
        this.$toast.error("Gambar cover tidak boleh kosong");
      }
    });

    this.$bus.$on("image-data-cover", (data) => {
      if (data.length > 0) {
        this.formData.cover_url = data[0];
      }
      if (this.type === "add") {
        this.createNews();
      } else {
        this.editNews();
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off("image-data-cover");
    this.$bus.$off("sent-image-cover-checked");
  },
  async mounted() {
    if (this.type === "add") {
      this.resetModalData();
    }
    await this.getUserList();
    this.setNewsDetail(this.newsDetail);
  },
  methods: {
    removeUser() {
      this.selectedUser = null;
    },
    async getUserList() {
      try {
        const params = {
          limit: 10,
          offset: 0,
          order_by: {
            id: "asc",
          },
        };
        const response = await this.Api({
          url: "api/rest/users",
          formData: params,
        }).post();
        this.userList = response.body.users;
      } catch {
        this.$toast.error("maaf terjadi kesalahan");
      }
    },

    userSelected(val) {
      this.selectedUser = val;
    },

    setActive(param) {
      this.formData.is_active = param;
    },
    setNewsDetail(val) {
      if (this.type === "add") {
        return;
      }
      this.formData.title = val.title;
      this.formData.slug = val.slug;
      this.formData.frequency = val.frequency;
      // this.formData.is_active = val.is_active;
      // this.formData.additional_note = val.additional_note;
      this.formData.description = val.description;
      this.formData.cover_url = val.cover_url;
      this.formData.user_id = val.user.id;
      this.selectedUser = val.user;
    },
    getCode() {
      let code = this.formData.name.replace(" ", "_");
      let codeNoDot = code.replace(".", "");
      let codeLowercase = codeNoDot.toLowerCase();
      return codeLowercase;
    },

    autoGenerateSlug() {
      const lowerCase = this.formData.title?.toLowerCase();
      const replaceSpace = lowerCase?.replace(/ /g, "-");
      return replaceSpace;
    },

    getCoverUrl() {
      this.$bus.$emit("process-upload-cover");
    },

    imageCoverValidation() {
      this.$bus.$emit("check-image-cover");
    },
    formValidation() {
      if (!this.formData.title) {
        this.$toast.error("Title berita wajib diisi");
        return;
      }

      if (!this.formData.description) {
        this.$toast.error("deskripsi berita wajib diisi");
        return;
      }

      this.imageCoverValidation();
    },

    async createNews() {
      try {
        this.formData.slug = this.autoGenerateSlug();
        this.formData = {
          ...this.formData,
          user_id: this.selectedUser?.id,
        };

        await this.Api({
          url: "api/rest/compro/news/create",
          formData: { company_news: this.formData },
        }).post();

        this.$emit("createnews");
        // this.getTotalPage(response.data.total_page);
        this.$toast.success("Berita berhasil ditambahkan");
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    async editNews() {
      try {
        this.formData.slug = this.autoGenerateSlug();
        this.formData = {
          ...this.formData,
          user_id: this.selectedUser?.id,
        };
        await this.Api({
          url: "api/rest/compro/news/" + this.newsDetail.id,
          formData: { company_news: this.formData },
        }).patch();

        this.$emit("createnews");
        // this.getTotalPage(response.data.total_page);
        this.$toast.success("Berita berhasil diubah");
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    resetModalData() {
      this.formData = {
        title: null,
        description: null,
        slug: null,
        // is_active: false,
        // additional_note: null,
        user_id: null,
      };
      this.selectedUser = null;
    },
  },
};
</script>

<style scoped>
.modaldetail {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.modaldetail__mask {
  position: fixed;
  background: black;
  opacity: 75%;
  width: 100vw;
  height: 100vh;
}

.modaldetail__box {
  padding: 0px 2rem 2rem 2rem;
  background-color: white;
  border-radius: 16px;
  z-index: 2;
  min-width: 55rem;
  height: auto;
  max-height: 600px;
  overflow-y: scroll;
}

.modaldetail__box-row-last {
  padding-bottom: 48px;
}

.modaldetail__box::-webkit-scrollbar {
  display: none;
}

.modaldetail__box-title {
  position: sticky;
  background: white;
  top: 0px;
  padding: 2rem 0 1rem 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modaldetail__box-row {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2rem;
}

.modaldetail__box-row-title {
  font-size: 14px;
  line-height: 16px;
  color: #707880;
}

.modaldetail__box-row-input {
  outline: none;
  border-radius: 8px;
  border: 1px solid #eef0f5;
  background: #f6fafd;
  padding: 8px;
}

.modaldetail__box-row-input::placeholder {
  font-size: 14px;
  line-height: 16px;
  color: darkgray;
}

.modaldetail__box-title-text {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.modaldetail__box-row-button {
  position: sticky;
  text-align: right;
  bottom: 0px;
  width: 100%;
}

.modaldetail__box-title-text-img {
  width: 26px;
  height: auto;
  position: absolute;
  right: 0px;
  cursor: pointer;
}

.modaldetail__box-row-image-box {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  border: 1px dashed black;
  border-radius: 8px;
}

.modaldetail__box-row-image-box-text {
  font-weight: bold;
}

.modaldetail__box-row-image-box-text_normal {
  font-weight: 400;
}

.modaldetail__box-row-image-box-file {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  opacity: 0;
}

.modaldetail__box-row-radio {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  border-radius: 1000px;
  border: 1px solid #333333;
}

.modaldetail__box-row-radio-active {
  width: 15px;
  height: 15px;
  border-radius: 1000px;
  border: 1.5px solid #333333;
  background-color: black;
}

.modaldetail__box-row-radio-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.modaldetail__box-row-radio-box-wrapper {
  display: flex;
  gap: 15px;
}

.modaldetail__box-row-level {
  display: flex;
  border-radius: 8px;
  border: 1px solid #e7ecf5;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 16px;
  color: black;
  padding: 10px;
  width: fit-content;
  gap: 8px;
}

.modaldetail__box-row-level-wrapper {
  display: flex;
  gap: 1rem;
}

.modaldetail__box-row-level-remove {
  cursor: pointer;
  width: 12px;
}
</style>
