<template>
  <div class="newscontent">
    <div class="newscontent__list">
      <div class="newscontent__list-menu">
        <div class="newscontent__list-search">
          <input v-model="searchInput" type="text" class="newscontent__list-search-input" placeholder="Cari data..." />
          <button type="submit" class="newscontent__list-search-button" @click="searchData()">
            <img src="@/assets/admin/icon/search.png" alt="search" class="newscontent__list-search-button-img" />
          </button>
        </div>
        <div class="newscontent__list-dropdown">
          <div class="newscontent__list-dropdown-box" @click="toggleSort()" v-click-outside="closeSort">
            <p class="newscontent__list-dropdown-box-text">
              {{ selectedSort?.name }}
            </p>
            <img src="@/assets/admin/icon/sort-asc-black.png" alt="search" class="newscontent__list-search-button-img"
              v-if="selectedSort?.sortAsc" />
            <img src="@/assets/admin/icon/sort-desc-black.png" alt="search" class="newscontent__list-search-button-img"
              v-else />
            <div class="newscontent__list-dropdown-box-content" v-if="sortOpened">
              <p class="newscontent__list-dropdown-box-content-text" :class="index === sortList.length - 1
                  ? 'newscontent__list-dropdown-box-content-text_no-bottom'
                  : ''
                " v-for="(dt, index) in sortList" :key="index" @click="setSelectedSort(dt)">
                {{ dt.name }}
              </p>
            </div>
          </div>
          <button type="submit" class="newscontent__list-search-button">
            <img src="@/assets/admin/icon/filter.png" alt="search" class="newscontent__list-search-button-img" />
          </button>
        </div>
        <div class="newscontent__list-adddelete">
          <CustomButton :button-text="'Add'" @buttonClicked="showAddModal(), setType('add')"></CustomButton>
        </div>
      </div>
      <div class="newscontent__list-table">
        <div class="newscontent__list-table-header">
          <p class="newscontent__list-table-header-no">No</p>
          <p class="newscontent__list-table-header-title">Title</p>
          <p class="newscontent__list-table-header-image">Cover Url</p>
          <p class="newscontent__list-table-header-description">Description</p>
          <p class="newscontent__list-table-header-author">Author</p>
          <p class="newscontent__list-table-header-active">Is Active</p>
          <p class="newscontent__list-table-header-created">Created</p>
          <p class="newscontent__list-table-header-action">Action</p>
        </div>
        <div class="newscontent__list-table-body">
          <div v-if="validData && validData.length > 0">
            <div class="newscontent__list-table-body-user" v-for="(news, index) in validData" :key="index">
              <p class="newscontent__list-table-body-no" @click="setDetailNews(news)">{{ index + 1 }}</p>
              <p class="newscontent__list-table-body-title" @click="setDetailNews(news)">
                {{ news.title }}
              </p>
              <p class="newscontent__list-table-body-image" @click="setDetailNews(mews)">
                <img class="newscontent__list-table-body-image-url" :src="getGoogleDriveImage(news.cover_url)"
                  alt="news image" />
              </p>
              <p class="newscontent__list-table-body-description" @click="setDetailNews(news)">
                {{ news.description }}
              </p>

              <p class="newscontent__list-table-body-author" @click="setDetailNews(news)">
                {{ news.user.name }}
              </p>
              <p class="newscontent__list-table-body-active" @click="setDetailNews(news)">
                {{ news.is_active ? "Yes" : "No" }}
              </p>
              <p class="newscontent__list-table-body-created" @click="setDetailNews(news)">
                {{moment(news.created_at).format("DD-MM-YYYY HH:mm:ss")}}
              </p>
              <div class="newscontent__list-table-body-action">
                <CustomButton :button-display="false" :button-text="'Edit'" :background-color="'#0099CC'"
                  :padding-top="'0.5rem'" :padding-bottom="'0.5rem'" :image="require('@/assets/admin/icon/edit.png')"
                  @buttonClicked="editNews(news)"></CustomButton>
                <CustomButton :button-display="false" :button-text="'Delete'" :background-color="'#FA5C7C'"
                  :padding-top="'0.5rem'" :padding-bottom="'0.5rem'" :image="require('@/assets/admin/icon/trash.svg')"
                  @buttonClicked="showModalConfirmation(news.id)"></CustomButton>
              </div>
            </div>
          </div>
          <div class="newscontent__list-table-body-user_no-data" v-else>
            <p class="newscontent__list-table-body-user_no-data-text">
              Tidak ada data
            </p>
          </div>
        </div>
      </div>
      <div class="newscontent__list-pagination">
        <!-- Go Back -->
        <div class="newscontent__list-pagination-chevron" @click="setCurrentPage(paginationOption.currentPage - 1)"
          v-if="paginationOption.currentPage - 1 > 0">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
          </svg>
        </div>

        <div class="newscontent__list-pagination-first" v-if="paginationOption.currentPage > 2">
          <p class="newscontent__list-pagination-number" @click="setCurrentPage(1)">
            1
          </p>
          <p class="newscontent__list-pagination-number" v-if="paginationOption.currentPage > 3">
            . . .
          </p>
        </div>

        <!-- Previous Page -->
        <p class="newscontent__list-pagination-number" v-if="paginationOption.currentPage - 1 > 0"
          @click="setCurrentPage(paginationOption.currentPage - 1)">
          {{ paginationOption.currentPage - 1 }}
        </p>

        <!-- Current Page -->
        <p class="newscontent__list-pagination-number newscontent__list-pagination-number_bold">
          {{ paginationOption.currentPage }}
        </p>

        <!-- Next Page -->
        <p class="newscontent__list-pagination-number"
          v-if="paginationOption.currentPage + 1 < paginationOption.totalPage"
          @click="setCurrentPage(paginationOption.currentPage + 1)">
          {{ paginationOption.currentPage + 1 }}
        </p>

        <div class="newscontent__list-pagination-first" v-if="paginationOption.currentPage < paginationOption.totalPage">
          <p class="newscontent__list-pagination-number" v-if="paginationOption.currentPage + 3 <= paginationOption.totalPage
            ">
            . . .
          </p>
          <p class="newscontent__list-pagination-number" @click="setCurrentPage(paginationOption.totalPage)">
            {{ paginationOption.totalPage }}
          </p>
        </div>

        <!-- Go Next -->
        <div class="newscontent__list-pagination-chevron" @click="setCurrentPage(paginationOption.currentPage + 1)"
          v-if="paginationOption.currentPage < paginationOption.totalPage">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </div>
      </div>
    </div>
    <ModalDetailVue v-if="statusAddModal" :news-detail="detailNews" :type="type" @closeModal="hideAddModal"
      @createnews="onNewsCreated()"></ModalDetailVue>
    <ModalConfirmation v-if="statusModalConfirmation" :title="'Apakah anda ingin menghapus Komisi ini ?'"
      @yesClicked="deleteNews" @closeModal="hideModalConfirmation"></ModalConfirmation>
  </div>
</template>

<script>
import CustomButton from "@/components/atoms/CustomButton.vue";
import ClickOutside from "vue-click-outside";
import ModalDetailVue from "./ModalDetail.vue";
import ModalConfirmation from "@/components/molecules/ModalConfirmation.vue";
import moment from "moment";


export default {
  name: "NavigationBar",
  components: {
    CustomButton,
    ModalDetailVue,
    ModalConfirmation,
  },
  data() {
    return {
      moment: moment,
      newsList: [],
      resultSearch: null,
      searchInput: null,
      selectedFilter: null,
      filterOpened: false,
      filterList: [
        {
          name: "News title",
          code: "title",
        },
        {
          name: "Id",
          code: "id",
        },
      ],
      paginationOption: {
        limitPage: 10,
        totalPage: 1,
        currentPage: 1,
      },

      selectedSort: {
        name: "News title",
        order_by: "title",
        sortAsc: false,
      },
      sortOpened: false,
      sortList: [
        {
          name: "Id",
          order_by: "id",
          sortAsc: false,
        },
        {
          name: "News title",
          order_by: "title",
          sortAsc: false,
        },
        {
          name: "Date",
          order_by: "created_at",
          sortAsc: false,
        },
      ],
      statusAddModal: false,
      detailNews: {},
      type: 'preview',
      buttonHovered: false,
      totalPage: null,
      statusModalConfirmation: false,
      savedId: null


    };

  },
  mounted() {
    this.getNewsList();
    this.setInitialFilter();
  },
  methods: {
    setDetailNews(news) {
      this.detailNews = news
      this.setType('preview')
      this.showAddModal()
    },
    setInitialFilter() {
      this.selectedFilter = this.filterList[0];
    },
    setInitialSort() {
      this.selectedSort = this.sortList[0];
    },
    setSelectedFilter(params) {
      this.selectedFilter = params;
    },
    setSelectedSort(params) {
      if (this.selectedSort.order_by === params.order_by) {
        this.selectedSort.sortAsc = !this.selectedSort.sortAsc;
      } else {
        this.selectedSort = params;
      }

      this.getNewsList();
    },
    showAddModal() {
      this.statusAddModal = true;
    },
    hideAddModal() {
      this.statusAddModal = false;
    },
    showModalConfirmation(id) {
      this.savedId = id;
      this.statusModalConfirmation = true;
    },
    hideModalConfirmation() {
      this.statusModalConfirmation = false;
    },

    setType(val) {
      this.type = val
    },
    async getNewsList() {
      try {
        const params = {
          limit: this.paginationOption.limitPage,
          offset: this.paginationOption.currentPage - 1,
          order_by: {
            [this.selectedSort.order_by]: this.selectedSort.sortAsc ? "asc" : "desc",
          },
          // order_sort: this.selectedSort.sortAsc ? "asc" : "desc",
        };
        const response = await this.Api({
          url: "api/rest/compro/news",
          formData: params,
        }).post();
        this.paginationOption.totalPage = Math.ceil(response?.body?.company_news_aggregate?.aggregate?.count / this.paginationOption.limitPage)
        this.totalPage = this.paginationOption.totalPage;
        this.newsList = response.body.company_news;

        // this.getTotalPage(response.data.total_page);
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    editNews(news) {
      this.detailNews = news
      this.setType('edit')
      this.showAddModal()
    },
    async deleteNews() {
      try {
        const response = await this.Api({
          url: `api/rest/compro/news/${this.savedId}`
        }).delete();

        if (response) {
          this.$toast.success("news berhasil dihapus")
          this.getNewsList();
        }
      } catch(err) {
        this.$toast.error("Gagal menghapus news");

      }finally {
        this.hideModalConfirmation()
      }

    },
    getTotalPage(totalData) {
      this.paginationOption.totalPage = Math.ceil(
        totalData / this.paginationOption.limitPage
      );
    },
    searchData() {
      const lowerCaseinput = this.searchInput?.toLowerCase();
      if (lowerCaseinput) {

        this.resultSearch = this.newsList.filter((dt) =>
          String(dt[this.selectedFilter.code])
            .toLowerCase()
            .includes(lowerCaseinput)
        );
        this.paginationOption.totalPage = 1;
      } else {
        this.resultSearch = null;
        this.paginationOption.totalPage = this.totalPage;
      }
    },
    toggleFilter() {
      this.filterOpened = !this.filterOpened;
    },
    closeFilter() {
      this.filterOpened = false;
    },
    toggleSort() {
      this.sortOpened = !this.sortOpened;
    },
    closeSort() {
      this.sortOpened = false;
    },
    setCurrentPage(page) {
      this.paginationOption.currentPage = page;
      this.getNewsList();
    },
    resetSortList() {
      this.sortList.forEach((dt) => {
        dt.sortAsc = true;
      });
    },
    onNewsCreated() {
      this.hideAddModal();
      this.getNewsList();
    },
  },
  computed: {
    validData() {
      return this.resultSearch ? this.resultSearch : this.newsList;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
  
<style>
@import "./newscontent.css";
</style>
  