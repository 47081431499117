<template>
  <div class="modaldetail">
    <div class="modaldetail__mask" @click="closeModal()"></div>
    <div class="modaldetail__box">
      <div class="modaldetail__box-title">
        <p class="modaldetail__box-title-text">Commissions Detail</p>
        <img
          src="@/assets/admin/icon/close.svg"
          alt="close"
          class="modaldetail__box-title-text-img"
          @click="closeModal()"
        />
      </div>
      <div>
        <div class="modaldetail__box-row">
          <UploadImageCover
            :title="'Komisi'"
            :initialImage="[formData.image_url]"
          ></UploadImageCover>
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Nama</p>
          <input
            v-model="formData.name"
            class="modaldetail__box-row-input"
            placeholder="Nama commissions"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Frequency</p>
          <input
            type="text"
            v-model="formData.frequency"
            class="modaldetail__box-row-input"
            placeholder="frequency commissions"
          />
        </div>
        <!-- <div class="modaldetail__box-row">
            <p class="modaldetail__box-row-title">Additional Notes</p>
            <input
              type="text"
              v-model="formData.additional_note"
              class="modaldetail__box-row-input"
              placeholder="Notes tambahan"
            />
          </div> -->
        <!-- <div class="modaldetail__box-row">
            <p class="modaldetail__box-row-title">Is Active</p>
            <div class="modaldetail__box-row-radio-box-wrapper">
              <div
                class="modaldetail__box-row-radio-box"
                @click="setActive(true)"
              >
                <div class="modaldetail__box-row-radio">
                  <div
                    class="modaldetail__box-row-radio-active"
                    v-if="formData.is_active"
                  ></div>
                </div>
                <p class="modaldetail__box-row-title">Yes</p>
              </div>
              <div
                class="modaldetail__box-row-radio-box"
                @click="setActive(false)"
              >
                <div class="modaldetail__box-row-radio">
                  <div
                    class="modaldetail__box-row-radio-active"
                    v-if="!formData.is_active"
                  ></div>
                </div>
                <p class="modaldetail__box-row-title">No</p>
              </div>
            </div>
          </div> -->
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Additional Note</p>
          <input
            type="text"
            v-model="formData.additional_note"
            class="modaldetail__box-row-input"
            placeholder="Catatan commissions"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Description</p>
          <textarea
            v-model="formData.description"
            class="modaldetail__box-row-input"
            placeholder="deskripsi commissions"
          ></textarea>
        </div>
        <div class="modaldetail__box-row-button" v-if="type !== 'preview'">
          <CustomButton
            :button-text="capitalizeFirstLetter(type)"
            @buttonClicked="formValidation()"
          ></CustomButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/atoms/CustomButton.vue";
import UploadImageCover from "@/components/molecules/UploadCover.vue";
export default {
  components: {
    CustomButton,
    UploadImageCover,
  },
  props: {
    commissionsDetail: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formData: {
        name: null,
        frequency: null,
        slug: null,
        // is_active: false,
        // additional_note: null,
        image_url: "",
        additional_note: null,
        description: null,
      },
    };
  },
  watch: {
    commissionsDetail(val) {
      if (val) {
        this.setCommissionsDetail();
      }
    },
  },
  created() {
    this.$bus.$on("sent-image-cover-checked", (status) => {
      if (status) {
        this.getCoverUrl();
      } else {
        this.$toast.error("Gambar cover tidak boleh kosong");
      }
    });

    this.$bus.$on("image-data-cover", (data) => {
      if (data.length > 0) {
        this.formData.image_url = data[0];
      }
      if (this.type === "add") {
        this.createCommissions();
      } else {
        this.editCommissions();
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off("image-data-cover");
    this.$bus.$off("sent-image-cover-checked");
  },
  mounted() {
    this.setCommissionsDetail(this.commissionsDetail);

    if (this.type === "add") {
      this.formData = {
        name: null,
        frequency: null,
        slug: null,
        // is_active: false,
        // additional_note: null,
        description: null,
        additional_note: null,
      };
    }
  },
  methods: {
    setActive(param) {
      this.formData.is_active = param;
    },
    setCommissionsDetail(val) {
      this.formData.name = val.name;
      this.formData.slug = val.slug;
      this.formData.frequency = val.frequency;
      // this.formData.is_active = val.is_active;
      // this.formData.additional_note = val.additional_note;
      this.formData.description = val.description;
      this.formData.image_url = val.image_url;
      this.formData.additional_note = val.additional_note;
    },
    getCode() {
      let code = this.formData.name.replace(" ", "_");
      let codeNoDot = code.replace(".", "");
      let codeLowercase = codeNoDot.toLowerCase();
      return codeLowercase;
    },
    autoGenerateSlug() {
      const lowerCase = this.formData.name?.toLowerCase();
      const replaceSpace = lowerCase?.replace(/ /g, "-");
      return replaceSpace;
    },

    getCoverUrl() {
      this.$bus.$emit("process-upload-cover");
    },

    imageCoverValidation() {
      this.$bus.$emit("check-image-cover");
    },
    formValidation() {
      if (!this.formData.name) {
        this.$toast.error("Nama komisi wajib diisi");
        return;
      }

      if (!this.formData.frequency) {
        this.$toast.error("tempo pembayaran wajib diisi");
        return;
      }

      if (!this.formData.additional_note) {
        this.$toast.error("Catatan wajib diisi");
        return;
      }

      if (!this.formData.description) {
        this.$toast.error("Deskripsi level wajib diisi");
        return;
      }

      this.imageCoverValidation();
    },
    async createCommissions() {
      try {
        this.formData.slug = this.autoGenerateSlug();
        await this.Api({
          url: "api/rest/compro/commissions/create",
          formData: { company_commission: this.formData },
        }).post();
        this.$emit("createcommissions");
        // this.getTotalPage(response.data.total_page);
        this.$toast.success("Komisi berhasil ditambahkan");
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    async editCommissions() {
      try {
        this.formData.slug = this.autoGenerateSlug();
        await this.Api({
          url: "api/rest/compro/commissions/" + this.commissionsDetail.id,
          formData: { company_commission: this.formData },
        }).patch();
        this.$emit("createcommissions");
        // this.getTotalPage(response.data.total_page);
        this.$toast.success("Komisi berhasil diubah");
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    resetModalData() {
      this.formData = {
        name: "",
        description: "",
        slug: "",
        additional_note: "",
        frequency: "",
      };
    },
  },
};
</script>

<style scoped>
.modaldetail {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.modaldetail__mask {
  position: fixed;
  background: black;
  opacity: 75%;
  width: 100vw;
  height: 100vh;
}

.modaldetail__box {
  padding: 0px 2rem 2rem 2rem;
  background-color: white;
  border-radius: 16px;
  z-index: 2;
  min-width: 55rem;
  height: auto;
  max-height: 600px;
  overflow-y: scroll;
}

.modaldetail__box-row-last {
  padding-bottom: 48px;
}

.modaldetail__box::-webkit-scrollbar {
  display: none;
}

.modaldetail__box-title {
  position: sticky;
  background: white;
  top: 0px;
  padding: 2rem 0 1rem 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modaldetail__box-row {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2rem;
}

.modaldetail__box-row-title {
  font-size: 14px;
  line-height: 16px;
  color: #707880;
}

.modaldetail__box-row-input {
  outline: none;
  border-radius: 8px;
  border: 1px solid #eef0f5;
  background: #f6fafd;
  padding: 8px;
}

.modaldetail__box-row-input::placeholder {
  font-size: 14px;
  line-height: 16px;
  color: darkgray;
}

.modaldetail__box-title-text {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.modaldetail__box-row-button {
  position: sticky;
  text-align: right;
  bottom: 0px;
  width: 100%;
}

.modaldetail__box-title-text-img {
  width: 26px;
  height: auto;
  position: absolute;
  right: 0px;
  cursor: pointer;
}

.modaldetail__box-row-image-box {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  border: 1px dashed black;
  border-radius: 8px;
}

.modaldetail__box-row-image-box-text {
  font-weight: bold;
}

.modaldetail__box-row-image-box-text_normal {
  font-weight: 400;
}

.modaldetail__box-row-image-box-file {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  opacity: 0;
}

.modaldetail__box-row-radio {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  border-radius: 1000px;
  border: 1px solid #333333;
}

.modaldetail__box-row-radio-active {
  width: 15px;
  height: 15px;
  border-radius: 1000px;
  border: 1.5px solid #333333;
  background-color: black;
}

.modaldetail__box-row-radio-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.modaldetail__box-row-radio-box-wrapper {
  display: flex;
  gap: 15px;
}
</style>
