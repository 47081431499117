<template>
  <div class="header">
    <div class="header__box">
      <p>{{ name || "-" }}</p>
      <p class="header__box-logout" @click="logout">Logout</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationBar",
  props: {
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    logout() {
      localStorage.removeItem("token-jwt");
      this.$router.push('/mlm-admin')
    }
  },
};
</script>

<style>
@import "./header.css";
</style>
