<template>
  <div class="main">
    <Header></Header>
    <Hero></Hero>

    <!--================Contact Area =================-->
    <section class="blog_area single-post-area p_120">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 posts-list">
            <div class="single-post row">
              <div class="col-lg-12">
                <div class="feature-img">
                  <img
                    class="img-fluid"
                    v-bind:src="getGoogleDriveImage(news.cover_url)"
                    alt=""
                    style="width: 750px; height: 350px"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-md-3">
                <div class="blog_info text-right">
                  <ul class="blog_meta list">
                    <li>
                      <a href="#"
                        >{{ news.user.name }}<i class="lnr lnr-user"></i
                      ></a>
                    </li>
                    <li>
                      <a href="#"
                        >{{ moment(news.created_at).format("DD-MM-YYYY")
                        }}<i class="lnr lnr-calendar-full"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 blog_details">
                <h2>{{ news.title }}</h2>
                <p class="excert">
                  {{ news.description }}
                </p>
              </div>
              <!-- <div class="col-lg-12">
                <div class="quotes">
                  MCSE boot camps have its supporters and its detractors. Some
                  people do not understand why you should have to spend money on
                  boot camp when you can get the MCSE study materials yourself
                  at a fraction of the camp price. However, who has the
                  willpower to actually sit through a self-imposed MCSE
                  training.
                </div>
              </div> -->
            </div>
          </div>
          <div class="col-lg-4">
            <div class="blog_right_sidebar">
              <aside class="single_sidebar_widget search_widget">
                <!-- /input-group -->
                <div class="br"></div>
              </aside>
              <aside class="single_sidebar_widget author_widget">
                <img
                  class="author_img rounded-circle"
                  src="@/assets/ghani-logo-small.png"
                  alt=""
                />
                <h4>GHANI INDONESIA</h4>
                <div class="social_icon">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-github"></i></a>
                  <a href="#"><i class="fa fa-behance"></i></a>
                </div>
                <p>
                  Boot camps have its supporters andit sdetractors. Some people
                  do not understand why you should have to spend money on boot
                  camp when you can get. Boot camps have itssuppor ters andits
                  detractors.
                </p>
                <div class="br"></div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--================Contact Area END =================-->

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/compro/Header.vue";
import Hero from "@/components/compro/Hero.vue";
import Footer from "@/components/compro/Footer.vue";
import moment from "moment";

export default {
  name: "NewsPage",
  components: {
    Header,
    Hero,
    Footer,
  },
  data() {
    return {
      moment: moment,
      news: [],
      newsPage: [],
      err: "",
    };
  },
  mounted() {
    this.fetchNewsPage();
  },
  methods: {
    async fetchNewsPage() {
      try {
        const response = await this.ApiCompro({
          url: "api/rest/compro/news/" + this.$route.params.slug,
        }).get();
        this.news = response.data.company_news;
        this.news = this.news.length > 0 ? this.news[0] : [];
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
  },
};
</script>

<style></style>
