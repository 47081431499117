<template>
  <section class="banner_area">
    <div class="banner_inner d-flex align-items-center">
      <div
        class="overlay bg-parallax"
        data-stellar-ratio="0.9"
        data-stellar-vertical-offset="0"
        data-background=""
      ></div>
      <div class="container">
        <div class="banner_content text-center">
          <h2>{{ title }}</h2>
          <div class="page_link">
            <a href="/">Home</a>
            <a href="/">{{ title }}</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Hero",
  data() {
    return {
      title: null,
    };
  },
  mounted() {
    this.getTitle();
  },

  methods: {
    getTitle() {
      
      this.title = this.setTitleName(this.$route.name);
    },
    setTitleName(routerName) {
      switch (routerName.toLowerCase()) {
        case "aboutpage":
          return "Tentang Ghani";
        case "kontakpage":
          return "Hubungi Kami";
        case "newspage":
          return "Berita";
        case "paketpage":
          return "Paket";
        case "produkpage":
          return "Produk";
        case "komisipage":
          return "Komisi Ghani";
        case "productspagelist":
          return "Daftar Produk";
        default:
          return "-";
      }
    },
  },
};
</script>

<style>
</style>