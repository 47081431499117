<template>
  <header class="header_area">
    <div class="main_menu" id="mainNav">
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container box_1620">
          <!-- Brand and toggle get grouped for better mobile display -->
          <a class="navbar-brand logo_h" href="/"
            ><img
              class="img_brand_logo"
              src="@/assets/ghani-icon.png"
              alt=""
              style="width: 130px;"
          /></a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <!-- Collect the nav links, forms, and other content for toggling -->
          <div
            class="collapse navbar-collapse offset"
            id="navbarSupportedContent"
          >
            <ul class="nav navbar-nav menu_nav ml-auto">
              <li class="nav-item active">
                <router-link class="nav-link" to="/">Beranda</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/about">Tentang</router-link>
                <!-- <p class="nav-link" @click="goTo('about')">Tentang</p> -->
              </li>
              <li class="nav-item submenu dropdown">
                <a
                  href="#"
                  class="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  >Produk</a
                >
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a class="nav-link" href="/products/spirulina-fit"
                      >SPIRULINA FIT</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/products/obajan"
                      >OBAJAN</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/products/habbatplus-19">HABBATPLUS 19</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/products/propolis-plus"
                      >PROPOLIS PLUS</a
                    >
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="/productslist"
                      >Loadmore....</router-link
                    >
                  </li>
                </ul>
              </li>

              <li class="nav-item submenu dropdown">
                <a
                  href="#"
                  class="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  >Marketing Plan</a
                >
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a class="nav-link" href="/commissions/komisi-penjualan-awal"
                      >KOMISI PENJUALAN AWAL
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/commissions/komisi-kepemimpinan"
                      >KOMISI KEPEMIMPINAN
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/commissions/komisi-generasi"
                      >KOMISI GENERASI
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/commissions/komisi-apresiasi"
                      >KOMISI APRESIASI (Berulang)
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/commissions/komisi-belanja-ulang"
                      >KOMISI BELANJA ULANG
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/commissions/komisi-penjualan-belanja-ulang"
                      >KOMISI PENJUALAN BELANJA ULANG
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/commissions/komisi-generasi-belanja-ulang"
                      >KOMISI GENERASI BELANJA ULANG
                    </a>
                  </li>
                </ul>
              </li>

              <li class="nav-item submenu dropdown">
                <a
                  href="#"
                  class="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  >Transparansi</a
                >
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a class="nav-link" href="/commissions/cashback"
                      >CASHBACK
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/commissions/reward"
                      >REWARD
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/contact">Kontak</router-link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  methods: {
    goTo(routeName) {
      this.$router.push(`/${routeName}`).catch(() => {});
    },
  },
};
</script>

