<template>
  <div class="loginmlm">
    <div class="loginmlm__modal">
      <div class="loginmlm__modal-circle-wrapper">
        <div class="loginmlm__modal-circle">
          <img
            class="loginmlm__modal-circle-img"
            src="@/assets/admin/images/ghani-logo.png"
          />
        </div>
      </div>
      <div class="loginmlm__modal-input">
        <div class="loginmlm__modal-input-section">
          <p class="loginmlm__modal-input-title">Email</p>
          <input
            v-model="inputData.email"
            class="loginmlm__modal-input-box"
            placeholder="Masukan email"
          />
        </div>
        <div class="loginmlm__modal-input-section">
          <p class="loginmlm__modal-input-title">Password</p>
          <input
            v-model="inputData.password"
            class="loginmlm__modal-input-box"
            type="password"
            placeholder="Masukan password"
          />
        </div>
      </div>
      <div class="loginmlm__modal-login">
        <CustomButton
          :button-text="'Login'"
          @buttonClicked="submitLogin"
        ></CustomButton>
      </div>
    </div>
    <LoadingVue v-if="isLoading" />
  </div>
</template>

<script>
import LoadingVue from "@/components/molecules/Loading.vue";
import CustomButton from "@/components/atoms/CustomButton.vue";
import axios from "axios";
export default {
  name: "LoginView",
  components: {
    CustomButton,
    LoadingVue
  },
  data() {
    return {
      isLoading: false,
      inputData: {
        email: "",
        password: "",
      },
    };
  },
  mounted() {},
  methods: {
    async submitLogin() {
      this.isLoading = true
      const formData = {
        email: this.inputData.email,
        password: this.inputData.password,
        // email: this.inputData.email || "jdoe@foobar.com",
        // password: this.inputData.password || "@Root786",
        is_mobile: false,
      };

      try {
        const response = await axios.post(
          process.env.VUE_APP_MLM_ADMIN_GO + "/api/login",
          formData
        );
        localStorage.setItem("token-jwt", response.data.data.access_token);
        localStorage.setItem("user-id", response.data.data.user_id);
        this.$router.push("/mlm-admin/dashboard?type=Product");
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan saat login");
      } finally {
        this.isLoading = false
      }
    },
  },
};
</script>

<style>
@import "./login.css";
</style>
