<template>
  <div class="commissionscontent">
    <div class="commissionscontent__list">
      <div class="commissionscontent__list-menu">
        <div class="commissionscontent__list-search">
          <input
            v-model="searchInput"
            type="text"
            class="commissionscontent__list-search-input"
            placeholder="Cari data..."
          />
          <button
            type="submit"
            class="commissionscontent__list-search-button"
            @click="searchData()"
          >
            <img
              src="@/assets/admin/icon/search.png"
              alt="search"
              class="commissionscontent__list-search-button-img"
            />
          </button>
        </div>
        <div class="commissionscontent__list-dropdown">
          <div
            class="commissionscontent__list-dropdown-box"
            @click="toggleSort()"
            v-click-outside="closeSort"
          >
            <p class="commissionscontent__list-dropdown-box-text">
              {{ selectedSort?.name }}
            </p>
            <img
              src="@/assets/admin/icon/sort-asc-black.png"
              alt="search"
              class="commissionscontent__list-search-button-img"
              v-if="selectedSort?.sortAsc"
            />
            <img
              src="@/assets/admin/icon/sort-desc-black.png"
              alt="search"
              class="commissionscontent__list-search-button-img"
              v-else
            />
            <div
              class="commissionscontent__list-dropdown-box-content"
              v-if="sortOpened"
            >
              <p
                class="commissionscontent__list-dropdown-box-content-text"
                :class="
                  index === sortList.length - 1
                    ? 'commissionscontent__list-dropdown-box-content-text_no-bottom'
                    : ''
                "
                v-for="(dt, index) in sortList"
                :key="index"
                @click="setSelectedSort(dt)"
              >
                {{ dt.name }}
              </p>
            </div>
          </div>
          <button type="submit" class="commissionscontent__list-search-button">
            <img
              src="@/assets/admin/icon/filter.png"
              alt="search"
              class="commissionscontent__list-search-button-img"
            />
          </button>
        </div>
        <div class="commissionscontent__list-adddelete">
          <CustomButton
            :button-text="'Add'"
            @buttonClicked="showAddModal(), setType('add')"
          ></CustomButton>
        </div>
      </div>
      <div class="commissionscontent__list-table">
        <div class="commissionscontent__list-table-header">
          <p class="commissionscontent__list-table-header-no">No</p>
          <p class="commissionscontent__list-table-header-name">Name</p>
          <p class="commissionscontent__list-table-header-image">Image</p>
          <p class="commissionscontent__list-table-header-active">Is Active</p>
          <p class="commissionscontent__list-table-header-frequency">
            Frequency
          </p>
          <p class="commissionscontent__list-table-header-note">
            Additional Note
          </p>
          <p class="commissionscontent__list-table-header-description">
            Description
          </p>
          <p class="commissionscontent__list-table-header-action">Action</p>
        </div>
        <div class="commissionscontent__list-table-body">
          <div v-if="validData && validData.length > 0">
            <div
              class="commissionscontent__list-table-body-user"
              v-for="(commissions, index) in validData"
              :key="index"
            >
              <p
                class="commissionscontent__list-table-body-no"
                @click="setDetailCommissions(commissions)"
              >
                {{ index + 1 }}
              </p>
              <p
                class="commissionscontent__list-table-body-name"
                @click="setDetailCommissions(commissions)"
              >
                {{ commissions.name }}
              </p>
              <p
                class="commissionscontent__list-table-body-image"
                @click="setDetailCommissions(commissions)"
              >
                <img
                  class="commissionscontent__list-table-body-image-url"
                  :src="getGoogleDriveImage(commissions.image_url)"
                  alt="commissions image"
                />
              </p>
              <p
                class="commissionscontent__list-table-body-active"
                @click="setDetailCommissions(commissions)"
              >
                {{ commissions.is_active ? "Yes" : "No" }}
              </p>
              <p
                class="commissionscontent__list-table-body-frequency"
                @click="setDetailCommissions(commissions)"
              >
                {{ commissions.frequency }}
              </p>
              <p
                class="commissionscontent__list-table-body-note"
                @click="setDetailCommissions(commissions)"
              >
                {{ commissions.additional_note }}
              </p>
              <p
                class="commissionscontent__list-table-body-description"
                @click="setDetailCommissions(commissions)"
              >
                {{ commissions.description }}
              </p>

              <div class="commissionscontent__list-table-body-action">
                <CustomButton
                  :button-display="false"
                  :button-text="'Edit'"
                  :background-color="'#0099CC'"
                  :padding-top="'0.5rem'"
                  :padding-bottom="'0.5rem'"
                  :image="require('@/assets/admin/icon/edit.png')"
                  @buttonClicked="editCommissions(commissions)"
                ></CustomButton>
                <CustomButton
                  :button-display="false"
                  :button-text="'Delete'"
                  :background-color="'#FA5C7C'"
                  :padding-top="'0.5rem'"
                  :padding-bottom="'0.5rem'"
                  :image="require('@/assets/admin/icon/trash.svg')"
                  @buttonClicked="showModalConfirmation(commissions.id)"
                ></CustomButton>
              </div>
            </div>
          </div>
          <div class="commissionscontent__list-table-body-user_no-data" v-else>
            <p class="commissionscontent__list-table-body-user_no-data-text">
              Tidak ada data
            </p>
          </div>
        </div>
      </div>
      <div class="commissionscontent__list-pagination">
        <!-- Go Back -->
        <div
          class="commissionscontent__list-pagination-chevron"
          @click="setCurrentPage(paginationOption.currentPage - 1)"
          v-if="paginationOption.currentPage - 1 > 0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </div>

        <div
          class="commissionscontent__list-pagination-first"
          v-if="paginationOption.currentPage > 2"
        >
          <p
            class="commissionscontent__list-pagination-number"
            @click="setCurrentPage(1)"
          >
            1
          </p>
          <p
            class="commissionscontent__list-pagination-number"
            v-if="paginationOption.currentPage > 3"
          >
            . . .
          </p>
        </div>

        <!-- Previous Page -->
        <p
          class="commissionscontent__list-pagination-number"
          v-if="paginationOption.currentPage - 1 > 0"
          @click="setCurrentPage(paginationOption.currentPage - 1)"
        >
          {{ paginationOption.currentPage - 1 }}
        </p>

        <!-- Current Page -->
        <p
          class="commissionscontent__list-pagination-number commissionscontent__list-pagination-number_bold"
        >
          {{ paginationOption.currentPage }}
        </p>

        <!-- Next Page -->
        <p
          class="commissionscontent__list-pagination-number"
          v-if="paginationOption.currentPage + 1 < paginationOption.totalPage"
          @click="setCurrentPage(paginationOption.currentPage + 1)"
        >
          {{ paginationOption.currentPage + 1 }}
        </p>

        <div
          class="commissionscontent__list-pagination-first"
          v-if="paginationOption.currentPage < paginationOption.totalPage"
        >
          <p
            class="commissionscontent__list-pagination-number"
            v-if="
              paginationOption.currentPage + 3 <= paginationOption.totalPage
            "
          >
            . . .
          </p>
          <p
            class="commissionscontent__list-pagination-number"
            @click="setCurrentPage(paginationOption.totalPage)"
          >
            {{ paginationOption.totalPage }}
          </p>
        </div>

        <!-- Go Next -->
        <div
          class="commissionscontent__list-pagination-chevron"
          @click="setCurrentPage(paginationOption.currentPage + 1)"
          v-if="paginationOption.currentPage < paginationOption.totalPage"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-right"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </div>
      </div>
    </div>
    <ModalDetailVue
      v-if="statusAddModal"
      :commissions-detail="detailCommissions"
      :type="type"
      @closeModal="hideAddModal"
      @createcommissions="onCommissionsCreated()"
    ></ModalDetailVue>
    <ModalConfirmation
      v-if="statusModalConfirmation"
      :title="'Apakah anda ingin menghapus Komisi ini ?'"
      @yesClicked="deleteCommissions"
      @closeModal="hideModalConfirmation"
    ></ModalConfirmation>
  </div>
</template>

<script>
import CustomButton from "@/components/atoms/CustomButton.vue";
import ClickOutside from "vue-click-outside";
import ModalDetailVue from "./ModalDetail.vue";
import ModalConfirmation from "@/components/molecules/ModalConfirmation.vue";

export default {
  name: "NavigationBar",
  components: {
    CustomButton,
    ModalDetailVue,
    ModalConfirmation,
  },
  data() {
    return {
      commissionsList: [],
      resultSearch: null,
      searchInput: null,
      selectedFilter: null,
      filterOpened: false,
      filterList: [
        {
          name: "Commissions Name",
          code: "name",
        },
        {
          name: "Id",
          code: "id",
        },
      ],
      paginationOption: {
        limitPage: 10,
        totalPage: 1,
        currentPage: 1,
      },

      selectedSort: {
        name: "Commissions Name",
        order_by: "name",
        sortAsc: false,
      },
      sortOpened: false,
      sortList: [
        {
          name: "Id",
          order_by: "id",
          sortAsc: false,
        },
        {
          name: "Commissions Name",
          order_by: "name",
          sortAsc: false,
        },
      ],
      statusAddModal: false,
      detailCommissions: {},
      type: "preview",
      buttonHovered: false,
      totalPage: null,
      statusModalConfirmation: false,
      savedId: null,
    };
  },
  mounted() {
    this.getCommissionsList();
    this.setInitialFilter();
  },
  methods: {
    setDetailCommissions(commissions) {
      this.detailCommissions = commissions;
      this.setType("preview");
      this.showAddModal();
    },
    setInitialFilter() {
      this.selectedFilter = this.filterList[0];
    },
    setInitialSort() {
      this.selectedSort = this.sortList[0];
    },
    setSelectedFilter(params) {
      this.selectedFilter = params;
    },
    setSelectedSort(params) {
      if (this.selectedSort.order_by === params.order_by) {
        this.selectedSort.sortAsc = !this.selectedSort.sortAsc;
      } else {
        this.selectedSort = params;
      }

      this.getCommissionsList();
    },
    showAddModal() {
      this.statusAddModal = true;
    },
    hideAddModal() {
      this.statusAddModal = false;
    },
    showModalConfirmation(id) {
      this.savedId = id;
      this.statusModalConfirmation = true;
    },
    hideModalConfirmation() {
      this.statusModalConfirmation = false;
    },
    setType(val) {
      this.type = val;
    },
    async getCommissionsList() {
      try {
        const params = {
          limit: this.paginationOption.limitPage,
          offset: this.paginationOption.currentPage - 1,
          order_by: {
            [this.selectedSort.order_by]: this.selectedSort.sortAsc
              ? "asc"
              : "desc",
          },
          // order_sort: this.selectedSort.sortAsc ? "asc" : "desc",
        };
        const response = await this.Api({
          url: "api/rest/compro/commissions",
          formData: params,
        }).post();
        this.paginationOption.totalPage = Math.ceil(
          response?.body?.commissions_aggregate?.aggregate?.count /
            this.paginationOption.limitPage
        );
        this.totalPage = this.paginationOption.totalPage;
        this.commissionsList = response.body.company_commissions;

        // this.getTotalPage(response.data.total_page);
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    editCommissions(commissions) {
      this.detailCommissions = commissions;
      this.setType("edit");
      this.showAddModal();
    },
    async deleteCommissions() {
      try {
        const response = await this.Api({
          url: `api/rest/compro/commissions/${this.savedId}`,
        }).delete();

        if (response) {
          this.$toast.success("Komisi berhasil dihapus");
          this.getCommissionsList();
        }
      } catch (err) {
        this.$toast.error("Gagal menghapus Komisi");
      } finally {
        this.hideModalConfirmation();
      }
    },
    getTotalPage(totalData) {
      this.paginationOption.totalPage = Math.ceil(
        totalData / this.paginationOption.limitPage
      );
    },
    searchData() {
      const lowerCaseinput = this.searchInput?.toLowerCase();
      if (lowerCaseinput) {
        this.resultSearch = this.commissionsList.filter((dt) =>
          String(dt[this.selectedFilter.code])
            .toLowerCase()
            .includes(lowerCaseinput)
        );
        this.paginationOption.totalPage = 1;
      } else {
        this.resultSearch = null;
        this.paginationOption.totalPage = this.totalPage;
      }
    },
    toggleFilter() {
      this.filterOpened = !this.filterOpened;
    },
    closeFilter() {
      this.filterOpened = false;
    },
    toggleSort() {
      this.sortOpened = !this.sortOpened;
    },
    closeSort() {
      this.sortOpened = false;
    },
    setCurrentPage(page) {
      this.paginationOption.currentPage = page;
      this.getCommissionsList();
    },
    resetSortList() {
      this.sortList.forEach((dt) => {
        dt.sortAsc = true;
      });
    },
    onCommissionsCreated() {
      this.hideAddModal();
      this.getCommissionsList();
    },
  },
  computed: {
    validData() {
      return this.resultSearch ? this.resultSearch : this.commissionsList;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style>
@import "./commissionscontent.css";
</style>
