<template>
  <div class="contactscontent">
    <div class="contactscontent__list">
      <div class="contactscontent__list-menu">
        <div class="contactscontent__list-search">
          <input v-model="searchInput" type="text" class="contactscontent__list-search-input"
            placeholder="Cari data..." />
          <button type="submit" class="contactscontent__list-search-button" @click="searchData()">
            <img src="@/assets/admin/icon/search.png" alt="search" class="contactscontent__list-search-button-img" />
          </button>
        </div>
        <div class="contactscontent__list-dropdown">
          <div class="contactscontent__list-dropdown-box" @click="toggleSort()" v-click-outside="closeSort">
            <p class="contactscontent__list-dropdown-box-text">
              {{ selectedSort?.name }}
            </p>
            <img src="@/assets/admin/icon/sort-asc-black.png" alt="search" class="contactscontent__list-search-button-img"
              v-if="selectedSort?.sortAsc" />
            <img src="@/assets/admin/icon/sort-desc-black.png" alt="search"
              class="contactscontent__list-search-button-img" v-else />
            <div class="contactscontent__list-dropdown-box-content" v-if="sortOpened">
              <p class="contactscontent__list-dropdown-box-content-text" :class="index === sortList.length - 1
                  ? 'contactscontent__list-dropdown-box-content-text_no-bottom'
                  : ''
                " v-for="(dt, index) in sortList" :key="index" @click="setSelectedSort(dt)">
                {{ dt.name }}
              </p>
            </div>
          </div>
          <button type="submit" class="contactscontent__list-search-button">
            <img src="@/assets/admin/icon/filter.png" alt="search" class="contactscontent__list-search-button-img" />
          </button>
        </div>
        <div class="contactscontent__list-adddelete">
          <!-- <CustomButton
              :button-text="'Add'"
              @buttonClicked="showAddModal(), setType('add')"
            ></CustomButton> -->
        </div>
      </div>
      <div class="contactscontent__list-table">
        <div class="contactscontent__list-table-header">
          <p class="contactscontent__list-table-header-no">No</p>
          <p class="contactscontent__list-table-header-name">Name</p>
          <p class="contactscontent__list-table-header-email">Email</p>
          <p class="contactscontent__list-table-header-subject">Subject</p>
          <p class="contactscontent__list-table-header-message">Message</p>
          <p class="contactscontent__list-table-header-active">Is Active</p>
          <p class="contactscontent__list-table-header-created">Created</p>
          <!-- <p class="contactscontent__list-table-header-action">Action</p> -->
        </div>
        <div class="contactscontent__list-table-body">
          <div v-if="validData && validData.length > 0">
            <div class="contactscontent__list-table-body-user" v-for="(contacts, index) in validData" :key="index">
              <p class="contactscontent__list-table-body-no" @click="setDetailContacts(contacts)">{{ index + 1 }}</p>
              <p class="contactscontent__list-table-body-name" @click="setDetailContacts(contacts)">
                {{ contacts.name }}
              </p>
              <p class="contactscontent__list-table-body-email" @click="setDetailContacts(contacts)">
                {{ contacts.email }}
              </p>
              <p class="contactscontent__list-table-body-subject" @click="setDetailContacts(contacts)">
                {{ contacts.subject }}
              </p>
              <p class="contactscontent__list-table-body-message" @click="setDetailContacts(contacts)">
                {{ contacts.message }}
              </p>
              <p class="contactscontent__list-table-body-active" @click="setDetailContacs(contacts)">
                {{ contacts.is_active ? "Yes" : "No" }}
              </p>
              <p class="contactscontent__list-table-body-created" @click="setDetailContacts(contacts)">
                {{ moment(contacts.created_at).format("DD-MM-YYYY HH:mm:ss") }}
              </p>
              <!-- <div class="contactscontent__list-table-body-action">
                  <CustomButton
                    :button-display="false"
                    :button-text="'Edit'"
                    :background-color="'#0099CC'"
                    :padding-top="'0.5rem'"
                    :padding-bottom="'0.5rem'"
                    :image="require('@/assets/admin/icon/edit.png')"
                    @buttonClicked="editContacts(contacts)"
                  ></CustomButton>
                  <CustomButton
                    :button-display="false"
                    :button-text="'Delete'"
                    :background-color="'#FA5C7C'"
                    :padding-top="'0.5rem'"
                    :padding-bottom="'0.5rem'"
                    :image="require('@/assets/admin/icon/trash.svg')"
                    @buttonClicked="deleteContacts(contacts.id)"
                  ></CustomButton>
                </div> -->
            </div>
          </div>
          <div class="contactscontent__list-table-body-user_no-data" v-else>
            <p class="contactscontent__list-table-body-user_no-data-text">
              Tidak ada data
            </p>
          </div>
        </div>
      </div>
      <div class="contactscontent__list-pagination">
        <!-- Go Back -->
        <div class="contactscontent__list-pagination-chevron" @click="setCurrentPage(paginationOption.currentPage - 1)"
          v-if="paginationOption.currentPage - 1 > 0">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
          </svg>
        </div>

        <div class="contactscontent__list-pagination-first" v-if="paginationOption.currentPage > 2">
          <p class="contactscontent__list-pagination-number" @click="setCurrentPage(1)">
            1
          </p>
          <p class="contactscontent__list-pagination-number" v-if="paginationOption.currentPage > 3">
            . . .
          </p>
        </div>

        <!-- Previous Page -->
        <p class="contactscontent__list-pagination-number" v-if="paginationOption.currentPage - 1 > 0"
          @click="setCurrentPage(paginationOption.currentPage - 1)">
          {{ paginationOption.currentPage - 1 }}
        </p>

        <!-- Current Page -->
        <p class="contactscontent__list-pagination-number contactscontent__list-pagination-number_bold">
          {{ paginationOption.currentPage }}
        </p>

        <!-- Next Page -->
        <p class="contactscontent__list-pagination-number"
          v-if="paginationOption.currentPage + 1 < paginationOption.totalPage"
          @click="setCurrentPage(paginationOption.currentPage + 1)">
          {{ paginationOption.currentPage + 1 }}
        </p>

        <div class="contactscontent__list-pagination-first"
          v-if="paginationOption.currentPage < paginationOption.totalPage">
          <p class="contactscontent__list-pagination-number" v-if="paginationOption.currentPage + 3 <= paginationOption.totalPage
            ">
            . . .
          </p>
          <p class="contactscontent__list-pagination-number" @click="setCurrentPage(paginationOption.totalPage)">
            {{ paginationOption.totalPage }}
          </p>
        </div>

        <!-- Go Next -->
        <div class="contactscontent__list-pagination-chevron" @click="setCurrentPage(paginationOption.currentPage + 1)"
          v-if="paginationOption.currentPage < paginationOption.totalPage">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </div>
      </div>
    </div>
    <ModalDetailVue v-if="statusAddModal" :contacts-detail="detailContacts" :type="type" @closeModal="hideAddModal"
      @createcontacts="onContactsCreated()"></ModalDetailVue>
  </div>
</template>

<script>
// import CustomButton from "@/components/atoms/CustomButton.vue";
import ClickOutside from "vue-click-outside";
import ModalDetailVue from "./ModalDetail.vue";
import moment from "moment";

export default {
  name: "NavigationBar",
  components: {
    ModalDetailVue,
  },
  data() {
    return {
      moment: moment,
      contactsList: [],
      resultSearch: null,
      searchInput: null,
      selectedFilter: null,
      filterOpened: false,
      filterList: [
        {
          name: "Contacts Name",
          code: "name",
        },
        {
          name: "Id",
          code: "id",
        },
      ],
      paginationOption: {
        limitPage: 10,
        totalPage: 1,
        currentPage: 1,
      },

      selectedSort: {
        name: "Contacts Name",
        order_by: "name",
        sortAsc: false,
      },
      sortOpened: false,
      sortList: [
        {
          name: "Id",
          order_by: "id",
          sortAsc: false,
        },
        {
          name: "Contacts Name",
          order_by: "name",
          sortAsc: false,
        },
        {
          name: "Date",
          order_by: "created_at",
          sortAsc: false,
        },
      ],
      statusAddModal: false,
      detailContacts: {},
      type: 'preview',
      buttonHovered: false,
      totalPage: null,
    };
  },
  mounted() {
    this.getContactsList();
    this.setInitialFilter();
  },
  methods: {
    setDetailContacts(contacts) {
      this.detailContacts = contacts
      this.setType('preview')
      this.showAddModal()
    },
    setInitialFilter() {
      this.selectedFilter = this.filterList[0];
    },
    setInitialSort() {
      this.selectedSort = this.sortList[0];
    },
    setSelectedFilter(params) {
      this.selectedFilter = params;
    },
    setSelectedSort(params) {
      if (this.selectedSort.order_by === params.order_by) {
        this.selectedSort.sortAsc = !this.selectedSort.sortAsc;
      } else {
        this.selectedSort = params;
      }

      this.getContactsList();
    },
    showAddModal() {
      this.statusAddModal = true;
    },
    hideAddModal() {
      this.statusAddModal = false;
    },
    setType(val) {
      this.type = val
    },
    async getContactsList() {
      try {
        const params = {
          limit: this.paginationOption.limitPage,
          offset: this.paginationOption.currentPage - 1,
          order_by: {
            [this.selectedSort.order_by]: this.selectedSort.sortAsc ? "asc" : "desc",
          },
          // order_sort: this.selectedSort.sortAsc ? "asc" : "desc",
        };
        const response = await this.Api({
          url: "api/rest/compro/contacts",
          formData: params,
        }).post();
        this.paginationOption.totalPage = Math.ceil(response?.body?.company_contacts_aggregate?.aggregate?.count / this.paginationOption.limitPage)
        this.totalPage = this.paginationOption.totalPage;
        this.contactsList = response.body.company_contacts;

        // this.getTotalPage(response.data.total_page);
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    editContacts(contacts) {
      this.detailContacts = contacts
      this.setType('edit')
      this.showAddModal()
    },
    async deleteContacts(id) {
      try {
        const response = await this.Api({
          url: `api/rest/compro/contacts/${id}`
        }).delete();

        if (response) {
          this.$toast.success("Kontak berhasil dihapus")
          this.getContactsList();
        }
      } catch {
        this.$toast.error("Gagal menghapus Kontak");
      }
    },
    getTotalPage(totalData) {
      this.paginationOption.totalPage = Math.ceil(
        totalData / this.paginationOption.limitPage
      );
    },
    searchData() {
      const lowerCaseinput = this.searchInput?.toLowerCase();
      if (lowerCaseinput) {
        this.resultSearch = this.contactsList.filter((dt) =>
          String(dt[this.selectedFilter.code])
            .toLowerCase()
            .includes(lowerCaseinput)
        );
        this.paginationOption.totalPage = 1;
      } else {
        this.resultSearch = null;
        this.paginationOption.totalPage = this.totalPage;
      }
    },
    toggleFilter() {
      this.filterOpened = !this.filterOpened;
    },
    closeFilter() {
      this.filterOpened = false;
    },
    toggleSort() {
      this.sortOpened = !this.sortOpened;
    },
    closeSort() {
      this.sortOpened = false;
    },
    setCurrentPage(page) {
      this.paginationOption.currentPage = page;
      this.getContactsList();
    },
    resetSortList() {
      this.sortList.forEach((dt) => {
        dt.sortAsc = true;
      });
    },
    onContactsCreated() {
      this.hideAddModal();
      this.getContactsList();
    },
  },
  computed: {
    validData() {
      return this.resultSearch ? this.resultSearch : this.contactsList;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
  
<style>
@import "./contactscontent.css";
</style>
  