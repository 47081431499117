<template>
  <div class="rolecontent">
    <div class="rolecontent__list">
      <div class="rolecontent__list-menu">
        <div class="rolecontent__list-search">
          <input
            v-model="searchInput"
            type="text"
            class="rolecontent__list-search-input"
            placeholder="Cari data..."
          />
          <button
            type="submit"
            class="rolecontent__list-search-button"
            @click="searchData()"
          >
            <img
              src="@/assets/admin/icon/search.png"
              alt="search"
              class="rolecontent__list-search-button-img"
            />
          </button>
        </div>
        <div class="productcontent__list-dropdown">
          <div
            class="productcontent__list-dropdown-box"
            @click="toggleFilter()"
            v-click-outside="closeFilter"
          >
            <p class="productcontent__list-dropdown-box-text">
              {{ selectedFilter?.name }}
            </p>
            <div
              class="productcontent__list-dropdown-box-content"
              v-if="filterOpened"
            >
              <p
                class="productcontent__list-dropdown-box-content-text"
                :class="
                  index === filterList.length - 1
                    ? 'productcontent__list-dropdown-box-content-text_no-bottom'
                    : ''
                "
                v-for="(dt, index) in filterList"
                :key="index"
                @click="setSelectedFilter(dt)"
              >
                {{ dt.name }}
              </p>
            </div>
          </div>
          <button type="submit" class="productcontent__list-search-button">
            <img
              src="@/assets/admin/icon/filter.png"
              alt="search"
              class="productcontent__list-search-button-img"
            />
          </button>
        </div>
        <div class="rolecontent__list-adddelete">
          <CustomButton
            :button-text="'Add'"
            @buttonClicked="showAddModal(), setType('add')"
          ></CustomButton>
        </div>
      </div>
      <div class="rolecontent__list-table">
        <div class="rolecontent__list-table-header">
          <p class="rolecontent__list-table-header-no">No</p>
          <p class="rolecontent__list-table-header-name">Name</p>
          <p class="rolecontent__list-table-header-deskripsi">Deskripsi</p>
          <p class="rolecontent__list-table-header-action">Action</p>
        </div>
        <div class="rolecontent__list-table-body">
          <div v-if="validData && validData.length > 0">
            <div
              class="rolecontent__list-table-body-user"
              v-for="(role, index) in validData"
              :key="index"
            >
              <p
                class="rolecontent__list-table-body-no"
                @click="setDetailRole(role)"
              >
                {{ index + 1 }}
              </p>
              <p
                class="rolecontent__list-table-body-name"
                @click="setDetailRole(role)"
              >
                {{ role.name }}
              </p>
              <p
                class="rolecontent__list-table-body-deskripsi"
                @click="setDetailRole(role)"
              >
                {{ role.description }}
              </p>
              <div class="rolecontent__list-table-body-action">
                <CustomButton
                  :button-display="false"
                  :button-text="'Edit'"
                  :background-color="'#0099CC'"
                  :padding-top="'0.5rem'"
                  :padding-bottom="'0.5rem'"
                  :padding-left="'0px'"
                  :padding-right="'0px'"
                  :width="'100%'"
                  :flex="'0.5'"
                  :image="require('@/assets/admin/icon/edit.png')"
                  @buttonClicked="editRole(role)"
                ></CustomButton>
                <CustomButton
                  :button-display="false"
                  :button-text="'Delete'"
                  :background-color="'#FA5C7C'"
                  :padding-top="'0.5rem'"
                  :padding-bottom="'0.5rem'"
                  :padding-left="'0px'"
                  :padding-right="'0px'"
                  :width="'100%'"
                  :flex="'0.5'"
                  :image="require('@/assets/admin/icon/trash.svg')"
                  @buttonClicked="showModalConfirmation(role.id)"
                ></CustomButton>
              </div>
            </div>
          </div>
          <div class="rolecontent__list-table-body-user_no-data" v-else>
            <p class="rolecontent__list-table-body-user_no-data-text">
              Tidak ada data
            </p>
          </div>
        </div>
      </div>
      <div class="rolecontent__list-pagination">
        <!-- Go Back -->
        <div
          class="rolecontent__list-pagination-chevron"
          @click="setCurrentPage(paginationOption.currentPage - 1)"
          v-if="paginationOption.currentPage - 1 > 0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </div>

        <div
          class="rolecontent__list-pagination-first"
          v-if="paginationOption.currentPage > 2"
        >
          <p
            class="rolecontent__list-pagination-number"
            @click="setCurrentPage(1)"
          >
            1
          </p>
          <p
            class="rolecontent__list-pagination-number"
            v-if="paginationOption.currentPage > 3"
          >
            . . .
          </p>
        </div>

        <!-- Previous Page -->
        <p
          class="rolecontent__list-pagination-number"
          v-if="paginationOption.currentPage - 1 > 0"
          @click="setCurrentPage(paginationOption.currentPage - 1)"
        >
          {{ paginationOption.currentPage - 1 }}
        </p>

        <!-- Current Page -->
        <p
          class="rolecontent__list-pagination-number rolecontent__list-pagination-number_bold"
        >
          {{ paginationOption.currentPage }}
        </p>

        <!-- Next Page -->
        <p
          class="rolecontent__list-pagination-number"
          v-if="paginationOption.currentPage + 1 < paginationOption.totalPage"
          @click="setCurrentPage(paginationOption.currentPage + 1)"
        >
          {{ paginationOption.currentPage + 1 }}
        </p>

        <div
          class="rolecontent__list-pagination-first"
          v-if="paginationOption.currentPage < paginationOption.totalPage"
        >
          <p
            class="rolecontent__list-pagination-number"
            v-if="
              paginationOption.currentPage + 3 <= paginationOption.totalPage
            "
          >
            . . .
          </p>
          <p
            class="rolecontent__list-pagination-number"
            @click="setCurrentPage(paginationOption.totalPage)"
          >
            {{ paginationOption.totalPage }}
          </p>
        </div>

        <!-- Go Next -->
        <div
          class="rolecontent__list-pagination-chevron"
          @click="setCurrentPage(paginationOption.currentPage + 1)"
          v-if="paginationOption.currentPage < paginationOption.totalPage"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-right"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </div>
      </div>
    </div>
    <ModalDetailVue
      v-if="statusAddModal"
      :role-detail="detailRole"
      :type="type"
      @closeModal="hideAddModal"
      @createrole="onRoleCreated()"
    ></ModalDetailVue>
    <ModalConfirmation
      v-if="statusModalConfirmation"
      :title="'Apakah anda ingin menghapus role ini ?'"
      @yesClicked="deleteRole"
      @closeModal="hideModalConfirmation"
    ></ModalConfirmation>
  </div>
</template>

<script>
import CustomButton from "@/components/atoms/CustomButton.vue";
import ClickOutside from "vue-click-outside";
import ModalDetailVue from "./ModalDetail.vue";
import ModalConfirmation from "@/components/molecules/ModalConfirmation.vue";

export default {
  name: "NavigationBar",
  components: {
    CustomButton,
    ModalDetailVue,
    ModalConfirmation
  },
  data() {
    return {
      roleList: [],
      resultSearch: null,
      searchInput: null,
      selectedFilter: null,
      filterOpened: false,
      filterList: [
        {
          name: "Role Name",
          code: "name",
        },
      ],
      paginationOption: {
        limitPage: 10,
        totalPage: 1,
        currentPage: 1,
      },

      selectedSort: {
        name: "Role Name",
        order_by: "name",
        sortAsc: false,
      },
      sortOpened: false,
      sortList: [
        {
          name: "Role Name",
          order_by: "name",
          sortAsc: false,
        },
      ],
      statusAddModal: false,
      detailRole: {},
      type: "preview",
      buttonHovered: false,
      totalPage: null,
      statusModalConfirmation: false,
      savedId: null
    };
  },
  mounted() {
    this.getRoleList();
    this.setInitialFilter();
  },
  methods: {
    setDetailRole(role) {
      this.detailRole = role;
      this.setType("preview");
      this.showAddModal();
    },
    setInitialFilter() {
      this.selectedFilter = this.filterList[0];
    },
    setInitialSort() {
      this.selectedSort = this.sortList[0];
    },
    setSelectedFilter(params) {
      this.selectedFilter = params;
    },
    setSelectedSort(params) {
      if (this.selectedSort.order_by === params.order_by) {
        this.selectedSort.sortAsc = !this.selectedSort.sortAsc;
      } else {
        this.selectedSort = params;
      }

      this.getRoleList();
    },
    showAddModal() {
      this.statusAddModal = true;
    },
    hideAddModal() {
      this.statusAddModal = false;
    },
    setType(val) {
      this.type = val;
    },
    showModalConfirmation(id) {
      this.savedId = id;
      this.statusModalConfirmation = true;
    },
    hideModalConfirmation() {
      this.statusModalConfirmation = false;
    },
    async getRoleList() {
      try {
        const params = {
          limit: this.paginationOption.limitPage,
          offset: (this.paginationOption.currentPage - 1) * this.paginationOption.limitPage,
          // order: {
          //   [this.selectedSort.order_by]: this.selectedSort.sortAsc ? "asc" : "desc",
          // },
          // order_sort: this.selectedSort.sortAsc ? "asc" : "desc",
        };
        const response = await this.Api({
          url: "api/rest/roles",
          formData: params,
        }).post();
        this.paginationOption.totalPage = Math.ceil(
          response?.body?.roles_aggregate?.aggregate?.count /
            this.paginationOption.limitPage
        );
        this.totalPage = this.paginationOption.totalPage;
        this.roleList = response.body.roles;
        // this.getTotalPage(response.data.total_page);
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    editRole(role) {
      this.detailRole = role;
      this.setType("edit");
      this.showAddModal();
    },
    async deleteRole() {
      try {
        const response = await this.Api({
          url: `api/rest/roles/${this.savedId}`,
        }).delete();

        if (response) {
          this.$toast.success("Produk berhasil dihapus");
          this.getRoleList();
        }
      } catch {
        this.$toast.error("Gagal menghapus produk");
      } finally {
        this.hideModalConfirmation()
      }
    },
    getTotalPage(totalData) {
      this.paginationOption.totalPage = Math.ceil(
        totalData / this.paginationOption.limitPage
      );
    },
    searchData() {
      const lowerCaseinput = this.searchInput?.toLowerCase();
      if (lowerCaseinput) {
        this.resultSearch = this.roleList.filter((dt) =>
          String(dt[this.selectedFilter.code])
            .toLowerCase()
            .includes(lowerCaseinput)
        );
        this.paginationOption.totalPage = 1;
      } else {
        this.resultSearch = null;
        this.paginationOption.totalPage = this.totalPage;
      }
    },
    toggleFilter() {
      this.filterOpened = !this.filterOpened;
    },
    closeFilter() {
      this.filterOpened = false;
    },
    toggleSort() {
      this.sortOpened = !this.sortOpened;
    },
    closeSort() {
      this.sortOpened = false;
    },
    setCurrentPage(page) {
      this.paginationOption.currentPage = page;
      this.getRoleList();
    },
    resetSortList() {
      this.sortList.forEach((dt) => {
        dt.sortAsc = true;
      });
    },
    onRoleCreated() {
      this.hideAddModal();
      this.getRoleList();
    },
  },
  computed: {
    validData() {
      return this.resultSearch ? this.resultSearch : this.roleList;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style>
@import "./rolecontent.css";
</style>
