<template> 
  <vue-fab mainBtnColor="#059240">
  <fab-item @clickItem="clickItem(0)" :idx="0" title="Home" icon="home" />
  <fab-item @clickItem="clickItem(1)" :idx="1" title="Whatsapp" icon="arrow_forward" />
  </vue-fab>
            
</template>
    
<script>
export default {
    name: 'FloatingButton',
    data() {
        return {
            link: ["https://ghanicenter.com/","https://wa.me/message/JMT7LYSMJ5UKG1"]
        };
    },        
    methods: {
        clickItem: function (idx) {
            window.open(this.link[idx], '_blank')
        }
    }

}
</script>