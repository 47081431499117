<template>
  <section class="clients_logo_area p_120">
    <div class="container">
      <div class="row" v-for="data in compro" :key="data.id">
        <div class="col-lg-7">
          <img src="@/assets/asset.png" alt="" style="width: 100%; height: auto;">
          
        </div>
        <div class="col-lg-5">
          <br>
          <br>
          <br>
          <br>
          <br>
          <h1 style="color: orange;">Dengan Menjadi Mitra GHANI Raih Potensi Bisnis Yang Tak Terbatas</h1>
          <br>
            <a
              class="genric-btn primary-border radius e-large"
              v-bind:href="
                'https://api.whatsapp.com/send?phone=' +
                data.phone +
                '&text=Hello,%20' +
                data.name +
                '!'
              "
              target="_blank"
              >Konsultasi</a
            >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Client",
  data() {
    return {
      compro: [],
      err: "",
    };
  },
  mounted() {
    this.fetchCompro();
  },

  methods: {
    async fetchCompro() {
      try {
        const params = {
          limit: 10,
          offset: 0,
          order_by: {
            id: "asc",
          },
        };
        const response = await this.ApiCompro({
          url: "api/rest/compro/companies",
          formData: params,
        }).post();
        this.compro = response.data.company_companies;
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
  },
};
</script>

<style></style>
