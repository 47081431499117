<template>
  <div class="networktreecontent">
    <Dropdown
      :options="userList"
      @selected="userSelected"
      name="User Id"
      placeholder="Pilih code user yang ingin dicari"
    >
    </Dropdown>
    <div class="networktreecontent__list">
      <div class="networktreecontent__list-body">
        <div class="networktreecontent__list-body-row-wrapper">
          <div
            class="networktreecontent__list-body-row networktreecontent__list-body-row_first"
          >
          {{card_row_one?.pv}}
            <CardTree
              :level="card_row_one?.level"
              :name="card_row_one?.name"
              :id="card_row_one?.code"
              :generation="card_row_one?.generation"
              :pv="card_row_one?.pv"
              :back-level="true"
              @backLevel="backToPreviousLevel()"
            ></CardTree>
            <div class="networktreecontent__list-body-row-line"></div>
          </div>
        </div>
        <div class="networktreecontent__list-body-row-wrapper">
          <div
            class="networktreecontent__list-body-row networktreecontent__list-body-row_second"
          >
            <div class="networktreecontent__list-body-row-line_top"></div>
            <div class="networktreecontent__list-body-row-line_connector"></div>
            <CardTree
              :level="card_row_two?.[0]?.level"
              :name="card_row_two?.[0]?.name"
              :id="card_row_two?.[0]?.code"
              :generation="card_row_two?.[0]?.generation" 
              :pv="card_row_two?.[0]?.pv"
              @cardPressed="getNetworkByUserId(card_row_two?.[0]?.id, true)"
            ></CardTree>
            <div class="networktreecontent__list-body-row-line"></div>
          </div>
          <div
            class="networktreecontent__list-body-row networktreecontent__list-body-row_second"
          >
            <div class="networktreecontent__list-body-row-line_top"></div>
            <CardTree
              :level="card_row_two?.[1]?.level"
              :name="card_row_two?.[1]?.name"
              :id="card_row_two?.[1]?.code"
              :pv="card_row_two?.[1]?.pv"
              :generation="card_row_two?.[1]?.generation"
              @cardPressed="getNetworkByUserId(card_row_two?.[1]?.id, true)"
            ></CardTree>
            <div class="networktreecontent__list-body-row-line"></div>
          </div>
        </div>
        <div class="networktreecontent__list-body-row-wrapper">
          <div
            class="networktreecontent__list-body-row networktreecontent__list-body-row_third"
          >
            <div class="networktreecontent__list-body-row-line_top"></div>
            <div class="networktreecontent__list-body-row-line_connector"></div>
            <CardTree
              :level="card_row_tree?.[0]?.[0]?.level"
              :name="card_row_tree?.[0]?.[0]?.name"
              :id="card_row_tree?.[0]?.[0]?.code"
              :pv="card_row_tree?.[0]?.[0]?.pv"
              :generation="card_row_tree?.[0]?.[0]?.generation"
              @cardPressed="getNetworkByUserId(card_row_tree?.[0]?.[0]?.id, true)"
            ></CardTree>
          </div>
          <div
            class="networktreecontent__list-body-row networktreecontent__list-body-row_third"
          >
            <div class="networktreecontent__list-body-row-line_top"></div>
            <CardTree
              :level="card_row_tree?.[0]?.[1]?.level"
              :name="card_row_tree?.[0]?.[1]?.name"
              :id="card_row_tree?.[0]?.[1]?.code"
              :pv="card_row_tree?.[0]?.[1]?.pv"
              :generation="card_row_tree?.[0]?.[1]?.generation"
              
              @cardPressed="getNetworkByUserId(card_row_tree?.[0]?.[1]?.id, true)"
            ></CardTree>
          </div>
          <div
            class="networktreecontent__list-body-row networktreecontent__list-body-row_third"
          >
            <div class="networktreecontent__list-body-row-line_top"></div>
            <div class="networktreecontent__list-body-row-line_connector"></div>
            <CardTree
              :level="card_row_tree?.[1]?.[0]?.level"
              :name="card_row_tree?.[1]?.[0]?.name"
              :id="card_row_tree?.[1]?.[0]?.code"
              :pv="card_row_tree?.[1]?.[0]?.pv"
              :generation="card_row_tree?.[1]?.[0]?.generation"
              @cardPressed="getNetworkByUserId(card_row_tree?.[1]?.[0]?.id, true)"
            ></CardTree>
          </div>
          <div
            class="networktreecontent__list-body-row networktreecontent__list-body-row_third"
          >
            <div class="networktreecontent__list-body-row-line_top"></div>
            <CardTree
              :level="card_row_tree?.[1]?.[1]?.level"
              :name="card_row_tree?.[1]?.[1]?.name"
              :id="card_row_tree?.[1]?.[1]?.code"
              :generation="card_row_tree?.[1]?.[1]?.generation"
              @cardPressed="getNetworkByUserId(card_row_tree?.[1]?.[1]?.id, true)"
            ></CardTree>
          </div>
        </div>
      </div>
    </div>
    <LoadingVue v-if="loading" />
  </div>
</template>

<script>
import CardTree from "@/components/atoms/CardTree.vue";
import Dropdown from "@/components/molecules/CustomDropdown.vue";
import LoadingVue from "@/components/molecules/Loading.vue";
// import CustomButton from "@/components/atoms/CustomButton.vue";
// import ClickOutside from "vue-click-outside";
// import ModalDetailVue from "./ModalDetail.vue";
// import ModalConfirmation from "@/components/molecules/ModalConfirmation.vue";

export default {
  name: "NavigationBar",
  components: {
    CardTree,
    Dropdown,
    LoadingVue,
    // CustomButton,
    // ModalDetailVue,
    // ModalConfirmation
  },
  data() {
    return {
      paginationOption: {
        limitPage: 10,
        totalPage: 1,
        currentPage: 1,
      },
      selectedSort: {
        name: "id",
        order_by: "id",
        sortAsc: false,
      },
      userList: [
        {
          id: 1,
          name: null,
        },
      ],
      selectedUser: null,
      loading: false,
      // For Card Data
      // Card 1
      card_row_one: {
        name: null,
        level: "basic",
        code: null,
        generation: null,
        pv : 0,
        id: null,
      },
      card_row_two: [
        {
          name: null,
          level: "basic",
          code: null,
          generation: null,
          pv : 0,
          id: null,
        },
        {
          name: null,
          level: "basic",
          code: null,
          generation: null,
          pv : 0,
          id: null,
        },
      ],
      card_row_tree: [
        [
          {
            name: null,
            level: "basic",
            code: null,
            generation: null,
            pv : 0,
            id: null,
          },
          {
            name: null,
            level: "basic",
            code: null,
            generation: null,
            pv : 0,
            id: null,
          },
        ],
        [
          {
            name: null,
            level: "basic",
            code: null,
            pv : 0,
            generation: null,
          },
          {
            name: null,
            level: "basic",
            code: null,
            pv : 0,
            generation: null,
          },
        ],
      ],
      historyTree: []
    };
  },
  async mounted() {
    // await this.getNetworkByUserId();
    await this.getUserList();
  },
  methods: {
    setHistory(id) {
      this.historyTree.push(id);
    },
    async backToPreviousLevel() {
      if (this.historyTree.length - 2 >= 0) {
        await this.getNetworkByUserId(this.historyTree[this.historyTree.length - 2]);
        this.historyTree.pop()
      }
    },
    userSelected(val) {
      this.historyTree = []
      this.selectedUser = val;
      this.getNetworkByUnitId(val.fullData?.user_units?.[0]?.id);
      console.log("selected user ", val)
    },
    async getUserList() {
      try {
        const params = {
          limit: 1000,
          offset: 0,
          order_by: {
            name: "desc",
          },
          // order_sort: this.selectedSort.sortAsc ? "asc" : "desc",
        };
        const response = await this.Api({
          url: "api/rest/users",
          formData: params,
        }).post();
        (this.userList = []),
          response.body.users.forEach((dt) => {
            this.userList.push({
              name: dt.code,
              id: dt.code,
              code: dt.code,
              fullData: dt,
            });
          });
        // this.getTotalPage(response.data.total_page);
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    setTreeData(data) {
      let user_unit_networks = data?.user_unit?.user_unit_networks;

      // Reset card data
      this.card_row_two = [
        {
          name: null,
          level: "basic",
          code: null,
          pv : 0,
          generation: null,
          id: null,
        },
        {
          name: null,
          level: "basic",
          code: null,
          pv : 0,
          generation: null,
          id: null,
        },
      ];

      this.card_row_tree = [
        [
          {
            name: null,
            level: "basic",
            code: null,
            pv : 0,
            generation: null,
            id: null,
          },
          {
            name: null,
            level: "basic",
            code: null,
            pv : 0,
            generation: null,
            id: null,
          },
        ],
        [
          {
            name: null,
            level: "basic",
            code: null,
            pv : 0,
            generation: null,
            id: null,
          },
          {
            name: null,
            level: "basic",
            code: null,
            pv : 0,
            generation: null,
            id: null,
          },
        ],
      ];
      // End Reset

      // if (user_unit_networks.length === 0) {
      //   this.$toast.error("Tidak ada data unit");
      //   return;
      // }

      // To check and set empty card
      if (user_unit_networks.length < 2) {
        for (let i = 0; i < 2 - user_unit_networks.length; i++)
          user_unit_networks.push({
            id: null,
            position:
              user_unit_networks?.[0]?.position === "left" ? "right" : "left",
            user_sub_unit: [],
          });
      }

      let sortedRowTwoCard = user_unit_networks.sort((a, b) => {
        if (a.position < b.position) {
          return -1;
        }
        if (a.position > b.position) {
          return 1;
        }
        return 0;
      });

      sortedRowTwoCard.forEach((dt, index) => {
        // Set data for row 2
        if (dt.id === null) {
          this.card_row_two[index] = {
            name: null,
            level: "basic",
            code: null,
            pv : 0,
            generation: null,
            id: null,
          };
        } else {
          const network = dt?.user_sub_unit?.user_unit_networks;
          const result = (network?.[0]?.point_value || 0) + (network?.[1]?.point_value || 0);
          this.card_row_two[index] = {
            name: dt?.user_sub_unit?.user?.name,
            level: dt?.user_sub_unit?.level?.name?.toLowerCase(),
            code: dt?.user_sub_unit?.attribute?.name,
            generation: dt?.order,
            pv: result,
        // pv : 0,
            id: dt?.user_sub_unit?.id,
          };
        }

        // To check and set empty card
        let tempRowThree = dt?.user_sub_unit?.user_unit_networks;
        if (tempRowThree) {
          if (tempRowThree?.length < 2) {
            for (let i = 0; i < 2 - tempRowThree?.length; i++)
              tempRowThree?.push({
                id: null,
                position:
                  tempRowThree?.[0]?.position === "left" ? "right" : "left",
                user_sub_unit: [],
              });
          }

          let sortedRowThreeCard = tempRowThree.sort((a, b) => {
            if (a.position < b.position) {
              return -1;
            }
            if (a.position > b.position) {
              return 1;
            }
            return 0;
          });

          // Set data for row 3
          sortedRowThreeCard.forEach((dtSub, idxSub) => {
            if (dtSub.id === null) {
              this.card_row_tree[index][idxSub] = {
                name: null,
                level: "basic",
                code: null,
                pv : 0,
                generation: null,
                id: null,
              };
            } else {
              this.card_row_tree[index][idxSub] = {
                name: dtSub?.user_sub_unit?.user?.name,
                level: dtSub?.user_sub_unit?.level?.name?.toLowerCase(),
                code: dtSub?.user_sub_unit?.attribute?.name,
                pv : tempRowThree?.[idxSub]?.point_value,
                generation: dtSub?.order,
                id: dtSub?.user_sub_unit?.id,
              };
            }
          });
        }
      });
    },
    resetAllCard() {
      this.card_row_one = {
        name: null,
        level: "basic",
        code: null,
        pv : 0,
        generation: null,
      };

      this.card_row_two = [
        {
          name: null,
          level: "basic",
          code: null,
          pv : 0,
          generation: null,
        },
        {
          name: null,
          level: "basic",
          code: null,
          pv : 0,
          generation: null,
        },
      ];

      this.card_row_tree = [
        [
          {
            name: null,
            level: "basic",
            code: null,
            pv : 0,
            generation: null,
          },
          {
            name: null,
            level: "basic",
            code: null,
            pv : 0,
            generation: null,
          },
        ],
        [
          {
            name: null,
            level: "basic",
            code: null,
            pv : 0,
            generation: null,
          },
          {
            name: null,
            level: "basic",
            code: null,
            pv : 0,
            generation: null,
          },
        ],
      ];
    },
    async getNetworkByUserId(id, setHistory = false, fromDropdown = false) {
      if (!id) {
        return
      }

      if (setHistory) {
        this.setHistory(id)
      }
      
      this.loading = true;
      try {
        const params = {
          limit: this.paginationOption.limitPage,
          offset:
            (this.paginationOption.currentPage - 1) *
            this.paginationOption.limitPage,
          order_by: {
            [this.selectedSort.order_by]: "asc",
          },
          // order_sort: this.selectedSort.sortAsc ? "asc" : "desc",
        };
        const response = await this.Api({
          url: `api/rest/users/${id}/networks`,
          formData: params,
        }).get();

        if (response) {
          if (!response.body?.user_network?.[0]) {
            this.resetAllCard();
            // this.$toast.error("Tidak ada data unit");
            this.getNetworkByUnitId(fromDropdown ? this.selectedUser?.fullData?.user_units?.[0].id : id);
            return;
          }
          console.log("pvnya ", response.body?.user_network?.[0]?.user_unit?.user_unit_networks?.[0]?.is_point_value_all_trans)
          console.log("usernetworks ", response.body?.user_network?.[0]?.user_unit?.user_unit_networks)
          this.card_row_one = {
            ...this.card_row_one,
            name: response.body?.user_network?.[0]?.user_unit?.user?.name,
            level: response.body?.user_network?.[0]?.user_unit?.level?.name?.toLowerCase(),
              pv: response.body?.user_network?.[0]?.user_unit?.user_unit_networks?.[0]?.is_point_value_all_trans,
             // pv : 0,
            code: response.body?.user_network?.[0]?.user_unit?.attribute?.name,
            generation: "1",
          };
          this.setTreeData(response.body?.user_network?.[0]);
        }
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      } finally {
        this.loading = false;
      }
    },
    async getNetworkByUnitId(id) {
      this.loading = true;
      try {
        const params = {
          limit: this.paginationOption.limitPage,
          offset:
            (this.paginationOption.currentPage - 1) *
            this.paginationOption.limitPage,
          order_by: {
            [this.selectedSort.order_by]: "asc",
          },
          // order_sort: this.selectedSort.sortAsc ? "asc" : "desc",
        };
        const response = await this.ApiGo({
          url: `api/users/units/${id}/networks`,
          formData: params,
        }).get();

        if (response) {
          if (!response.body?.data?.user_network?.[0]) {
            this.resetAllCard();
            this.$toast.error("Tidak ada data unit");
            return;
          }

          this.card_row_one = {
            ...this.card_row_one,
            name: response.body?.data?.user_network?.[0]?.user_unit?.user?.name,
            level:  response.body?.data?.user_network?.[0]?.user_unit?.level?.name?.toLowerCase(),
             pv: response.body?.data?.user_network?.[0]?.user_unit?.user_rewards?.[0]?.point_value_left + response.body?.data?.user_network?.[0]?.user_unit?.user_rewards?.[0]?.point_value_right,
            code: response.body?.data?.user_network?.[0]?.user_unit?.attribute?.name,
            generation: "1",
          };
          this.setTreeData(response.body?.data?.user_network?.[0]);
        }
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
@import "./networktreecontent.css";
</style>
