<template>
  <div class="main">
    <Header></Header>
    <Hero></Hero>

    <!--================Paket Area =================-->
    <div class="container">
      <div class="section-top-border">
        <h3 class="mb-30 title_color" style="text-align: center;">Pilihan Paket Mitra</h3>
        <img src="@/assets/graph.png" alt="">
        <div class="progress-table-wrap">
          <div class="progress-table">
            <div class="table-head">
              <div class="percentage">Nama Paket</div>
              <div class="percentage">Harga Minimal</div>
              <div class="percentage">Harga Maksimal</div>
              <div class="percentage">Poin Value (PV)</div>
              <div class="percentage">Ketentuan Khusus</div>
            </div>
            <div class="table-row" v-for="data in packets" :key="data.id">
              <div class="percentage">
                {{ data.name }}
              </div>
              <div class="percentage">RP. {{ data.minimum_price }}</div>
              <div class="percentage">RP. {{ data.maximum_price }}</div>
              <div class="percentage">
                {{ data.point_value }} (Akumulatif Belanja)
              </div>
              <div class="percentage">{{ data.additional_note }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-top-border">
        <h3 class="mb-30 title_color">CASHBACK 100%</h3>
        <div class="row">
          <div class="col-lg-12">
            <blockquote class="generic-blockquote">
              KHUSUS YANG BERGABUNG PAKET GOLD & PAKET PLATINUM Cash Back 100%
              adalah pengembalian uang setiap hari bagi yang bergabung Paket
              Gold (Rp. 4.200.000) & Paket Platinum (Rp. 9.000.000,-) dari 1%
              omzet nasional.
            </blockquote>
          </div>
        </div>
      </div>
    </div>

    <!--================About Area END =================-->

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/compro/Header.vue";
import Hero from "@/components/compro/Hero.vue";
import Footer from "@/components/compro/Footer.vue";
// import axios from "axios";

export default {
  name: "PaketPage",
  components: {
    Header,
    Hero,
    Footer,
  },
  data() {
    return {
      packets: [],
      err: "",
    };
  },
  mounted() {
    this.fetchPacketsPage();
  },
  methods: {
    async fetchPacketsPage() {
      try {
        const params = {
          limit: 10,
          offset: 0,
          order_by: {
            id: "asc",
          },
        };
        const response = await this.ApiCompro({
          url: "api/rest/compro/levels",
          formData: params,
        }).post();
        this.packets = response.data.levels;
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
  },
};
</script>

<style></style>
