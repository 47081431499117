<template>
  <section class="post_slider_area">
    <div class="main_title_product ">
      <br>
      <br>
      <br>
      <br>
      <h2>Tentang Produk Kami</h2>
      <p>
        Produk Kami Sebagian Besar adalah Herbal, Dengan Bahan Alami Yang Mendukung Kesehatan Bagi Tubuh, Berkhasiat dan Aman untuk dikonsumsi
      </p>
    </div>
    <div class="post_slider_inner">
      <carousel
        :per-page="4"
        :autoplay="true"
        :mouse-drag="true"
        :looping="true"
      >
        <slide v-for="data in products" :key="data.id">
          <div class="item">
            <div class="post_s_item">
              <div class="post_img">
                <img
                  v-if="data.product_images.length > 0"
                  v-bind:src="
                    getGoogleDriveImage(data.product_images[0].image_url)
                  "
                  alt="images_url"
                  style="width: 480px; height: 600px"
                />
              </div>
              <div class="post_text">
                <a v-bind:href="'/products/' + data.slug">
                  <h4>{{ data.name }}</h4>
                </a>
                <p>
                  {{ data.description.substring(0, 100) + "......" }}
                </p>
                <a class="main_btn" v-bind:href="'/products/' + data.slug"
                  >Lihat Produk</a
                >
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide,
  },
  name: "Produk",
  data() {
    return {
      products: [],
      err: "",
    };
  },
  mounted() {
    this.fetchProducts();
  },
  methods: {
    async fetchProducts() {
      try {
        const params = {
          limit: 20,
          offset: 0,
          order: {
            id: "desc",
          },
          filter:"a3c87d4c-34ce-4612-a338-d39d6bec0409",
        };
        const response = await this.ApiCompro({
          url: "api/rest/products-filters",
          formData: params,
        }).post();
        this.products = response.data.products;
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
  },
};
</script>

<style></style>
