<template>
  <div class="transactioncontent">
    <div class="transactioncontent__list">
      <div class="transactioncontent__list-menu">
        <div class="transactioncontent__list-search">
          <input
            v-model="searchInput"
            type="text"
            class="transactioncontent__list-search-input"
            placeholder="Cari data..."
          />
          <button
            type="submit"
            class="transactioncontent__list-search-button"
            @click="searchData()"
          >
            <img
              src="@/assets/admin/icon/search.png"
              alt="search"
              class="transactioncontent__list-search-button-img"
            />
          </button>
        </div>
        <div class="usercontent__list-dropdown">
          <div
            class="usercontent__list-dropdown-box"
            @click="toggleFilter()"
            v-click-outside="closeFilter"
          >
            <p class="usercontent__list-dropdown-box-text">
              {{ selectedFilter?.name }}
            </p>
            <div
              class="usercontent__list-dropdown-box-content"
              v-if="filterOpened"
            >
              <p
                class="usercontent__list-dropdown-box-content-text"
                :class="
                  index === filterList.length - 1
                    ? 'usercontent__list-dropdown-box-content-text_no-bottom'
                    : ''
                "
                v-for="(dt, index) in filterList"
                :key="index"
                @click="setSelectedFilter(dt)"
              >
                {{ dt.name }}
              </p>
            </div>
          </div>
          <button type="submit" class="usercontent__list-search-button">
            <img
              src="@/assets/admin/icon/filter.png"
              alt="search"
              class="usercontent__list-search-button-img"
            />
          </button>
        </div>
        <div class="transactioncontent__list-adddelete">
          <!-- <CustomButton
            :button-text="'Add'"
            @buttonClicked="showAddModal(), setType('add')"
          ></CustomButton> -->
          <p class="transactioncontent__list-adddelete_omset">
            Omset Harian:
            <span class="transactioncontent__list-adddelete_omset-amount"
              >Rp {{ formatNumber(dailyOmset) ?? 0 }}</span
            >
          </p>
          <p class="transactioncontent__list-adddelete_omset">
            Total Omset:
            <span class="transactioncontent__list-adddelete_omset-amount"
              >Rp {{ formatNumber(totalOmset) ?? 0 }}</span
            >
          </p>
          <a
            href="https://ghanicenter.com/backend-go/api/transactions/all-data/download"
          class="btn btn-primary mr-5 "
            download
          >
DOWNLOAD
          </a>
        </div>
      </div>
      <div class="transactioncontent__list-date">
        <p>Pilih Tanggal</p>
        <date-picker v-model="dateRange" range />
      </div>
      <div class="transactioncontent__list-table">
        <div class="transactioncontent__list-table-header">
          <p class="transactioncontent__list-table-header-no">No</p>
          <p class="transactioncontent__list-table-header-status">Status</p>
          <p class="transactioncontent__list-table-header-name">Invoice</p>
          <p class="transactioncontent__list-table-header-user">Name</p>
          <p class="transactioncontent__list-table-header-created">Created</p>
          <p class="transactioncontent__list-table-header-deskripsi">Jumlah</p>
          <p class="transactioncontent__list-table-header-delivered">
            Delivered
          </p>
          <p class="transactioncontent__list-table-header-action">Action</p>
        </div>
        <div class="transactioncontent__list-table-body">
          <div v-if="validData && validData.length > 0">
            <div
              class="transactioncontent__list-table-body-user"
              v-for="(trx, index) in validData"
              :key="index"
            >
              <p
                class="transactioncontent__list-table-body-no"
                @click="setDetailTransaction(trx)"
              >
                {{ index + 1 }}
              </p>
              <div class="transactioncontent__list-table-body-status-wrapper">
                <div
                  class="transactioncontent__list-table-body-status-box"
                  :class="
                    trx.status === 'PAID'
                      ? 'transactioncontent__list-table-body-status-box_paid'
                      : trx.status === 'PENDING'
                      ? 'transactioncontent__list-table-body-status-box_pending '
                      : ''
                  "
                >
                  <p
                    class="transactioncontent__list-table-body-status"
                    @click="setDetailTransaction(trx)"
                  >
                    {{ trx.status }}
                  </p>
                </div>
              </div>
              <p
                class="transactioncontent__list-table-body-name"
                @click="setDetailTransaction(trx)"
              >
                {{ trx.id }}
              </p>
              <p
                class="transactioncontent__list-table-body-user-name"
                @click="setDetailTransaction(trx)"
              >
                {{ trx?.attribute?.name }}
              </p>
              <p
                class="transactioncontent__list-table-body-created"
                @click="setDetailTransaction(trx)"
              >
                {{ moment(trx.created_at).format("DD MMM YYYY HH:mm") }}
              </p>
              <p
                class="transactioncontent__list-table-body-deskripsi"
                @click="setDetailTransaction(trx)"
              >
                Rp {{ formatNumber(trx?.payments?.[0]?.payment_amount) || "-" }}
              </p>
              <p
                class="transactioncontent__list-table-body-delivered"
                @click="setDetailTransaction(trx)"
              >
                {{ trx?.is_sent ? "Yes" : "No" }}
              </p>
              <div class="transactioncontent__list-table-body-action">
                <CustomButton
                  :button-display="false"
                  :button-text="'Edit'"
                  :background-color="'#0099CC'"
                  :padding-top="'0.5rem'"
                  :padding-bottom="'0.5rem'"
                  :padding-left="'0px'"
                  :padding-right="'0px'"
                  :width="'100%'"
                  :flex="'0.5'"
                  :image="require('@/assets/admin/icon/white-check.svg')"
                  @buttonClicked="showModalConfirmation(trx?.payments[0]?.id)"
                ></CustomButton>
                <CustomButton
                  :button-display="false"
                  :button-text="'Edit'"
                  :background-color="'#19CB46'"
                  :padding-top="'0.5rem'"
                  :padding-bottom="'0.5rem'"
                  :padding-left="'0px'"
                  :padding-right="'0px'"
                  :width="'100%'"
                  :flex="'0.5'"
                  :image="require('@/assets/admin/icon/white-delivery.png')"
                  @buttonClicked="showModalConfirmationDelivery(trx?.id)"
                ></CustomButton>
                <!-- <CustomButton
                  :button-display="false"
                  :button-text="'Delete'"
                  :background-color="'#FA5C7C'"
                  :padding-top="'0.5rem'"
                  :padding-bottom="'0.5rem'"
                  :image="require('@/assets/admin/icon/trash.svg')"
                  @buttonClicked="deleteTransaction(trx.id)"
                ></CustomButton> -->
              </div>
            </div>
          </div>
          <div class="transactioncontent__list-table-body-user_no-data" v-else>
            <p class="transactioncontent__list-table-body-user_no-data-text">
              Tidak ada data
            </p>
          </div>
        </div>
      </div>
      <div class="transactioncontent__list-pagination">
        <!-- Go Back -->
        <div
          class="transactioncontent__list-pagination-chevron"
          @click="setCurrentPage(paginationOption.currentPage - 1)"
          v-if="paginationOption.currentPage - 1 > 0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </div>

        <div
          class="transactioncontent__list-pagination-first"
          v-if="paginationOption.currentPage > 2"
        >
          <p
            class="transactioncontent__list-pagination-number"
            @click="setCurrentPage(1)"
          >
            1
          </p>
          <p
            class="transactioncontent__list-pagination-number"
            v-if="paginationOption.currentPage > 3"
          >
            . . .
          </p>
        </div>

        <!-- Previous Page -->
        <p
          class="transactioncontent__list-pagination-number"
          v-if="paginationOption.currentPage - 1 > 0"
          @click="setCurrentPage(paginationOption.currentPage - 1)"
        >
          {{ paginationOption.currentPage - 1 }}
        </p>

        <!-- Current Page -->
        <p
          class="transactioncontent__list-pagination-number transactioncontent__list-pagination-number_bold"
        >
          {{ paginationOption.currentPage }}
        </p>

        <!-- Next Page -->
        <p
          class="transactioncontent__list-pagination-number"
          v-if="paginationOption.currentPage + 1 < paginationOption.totalPage"
          @click="setCurrentPage(paginationOption.currentPage + 1)"
        >
          {{ paginationOption.currentPage + 1 }}
        </p>

        <div
          class="transactioncontent__list-pagination-first"
          v-if="paginationOption.currentPage < paginationOption.totalPage"
        >
          <p
            class="transactioncontent__list-pagination-number"
            v-if="
              paginationOption.currentPage + 3 <= paginationOption.totalPage
            "
          >
            . . .
          </p>
          <p
            class="transactioncontent__list-pagination-number"
            @click="setCurrentPage(paginationOption.totalPage)"
          >
            {{ paginationOption.totalPage }}
          </p>
        </div>

        <!-- Go Next -->
        <div
          class="transactioncontent__list-pagination-chevron"
          @click="setCurrentPage(paginationOption.currentPage + 1)"
          v-if="paginationOption.currentPage < paginationOption.totalPage"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-right"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </div>
      </div>
    </div>
    <ModalDetailVue
      v-if="statusAddModal"
      :transaction-detail="detailTransaction"
      :type="type"
      @closeModal="hideAddModal"
      @createtransaction="onTransactionCreated()"
    ></ModalDetailVue>
    <ModalConfirmation
      v-if="statusModalConfirmation"
      :title="'Apakah anda ingin menyetujui transaksi ini ?'"
      @yesClicked="confirmTransaction"
      @closeModal="hideModalConfirmation"
    ></ModalConfirmation>
    <ModalConfirmation
      v-if="statusModalConfirmationDelivery"
      :title="'Apakah anda ingin menyetujui pengiriman ini ?'"
      @yesClicked="confirmDelivery"
      @closeModal="hideModalConfirmationDelivery"
    ></ModalConfirmation>
    <LoadingVue v-if="loading" />
  </div>
</template>

<script>
import CustomButton from "@/components/atoms/CustomButton.vue";
import ClickOutside from "vue-click-outside";
import ModalDetailVue from "./ModalDetail.vue";
import ModalConfirmation from "@/components/molecules/ModalConfirmation.vue";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import LoadingVue from "@/components/molecules/Loading.vue";

export default {
  name: "NavigationBar",
  components: {
    DatePicker,
    CustomButton,
    ModalDetailVue,
    ModalConfirmation,
    LoadingVue,
  },
  data() {
    return {
      loading: false,
      dateRange: null,
      dailyOmset: null,
      totalOmset: null,
      transactionList: [],
      resultSearch: null,
      searchInput: null,
      selectedFilter: null,
      filterOpened: false,
      filterList: [
        {
          name: "Transaction Status",
          code: "status",
        },
        {
          name: "Transaction Id",
          code: "id",
        },
      ],
      paginationOption: {
        limitPage: 10,
        totalPage: 1,
        currentPage: 1,
      },

      selectedSort: {
        name: "Transaction Status",
        order_by: "status",
        sortAsc: false,
      },
      sortOpened: false,
      sortList: [
        {
          name: "Transaction Status",
          order_by: "status",
          sortAsc: false,
        },
        {
          name: "Transaction Id",
          order_by: "id",
          sortAsc: false,
        },
        {
          name: "Date",
          order_by: "created_at",
          sortAsc: false,
        },
      ],
      statusAddModal: false,
      statusModalConfirmation: false,
      statusModalConfirmationDelivery: false,
      detailTransaction: {},
      type: "preview",
      buttonHovered: false,
      totalPage: null,
      savedId: null,
    };
  },
  mounted() {
    this.getTransactionList();
    this.setInitialFilter();
  },
  watch: {
    dateRange() {
      this.getTransactionList();
    },
  },
  methods: {
    moment,

    setDetailTransaction(trx) {
      this.detailTransaction = trx;
      this.setType("preview");
      this.showAddModal();
    },
    setInitialFilter() {
      this.selectedFilter = this.filterList[0];
    },
    setInitialSort() {
      this.selectedSort = this.sortList[0];
    },
    setSelectedFilter(params) {
      this.selectedFilter = params;
    },
    setSelectedSort(params) {
      if (this.selectedSort.order_by === params.order_by) {
        this.selectedSort.sortAsc = !this.selectedSort.sortAsc;
      } else {
        this.selectedSort = params;
      }

      this.getTransactionList();
    },
    showModalConfirmation(id) {
      this.savedId = id;
      this.statusModalConfirmation = true;
    },
    hideModalConfirmation() {
      this.statusModalConfirmation = false;
    },
    showModalConfirmationDelivery(id) {
      this.savedId = id;
      this.statusModalConfirmationDelivery = true;
    },
    hideModalConfirmationDelivery() {
      this.statusModalConfirmationDelivery = false;
    },
    showAddModal() {
      this.statusAddModal = true;
    },
    hideAddModal() {
      this.statusAddModal = false;
    },
    setType(val) {
      this.type = val;
    },
    formatDate(date) {
      return moment(date)
        .utcOffset("+07:00")
        .format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
    },
    async getTransactionList() {
      try {
        this.loading = true;
        const params = {
          // date: moment(new Date()).format("YYYY-MM-DD"),
          limit: this.paginationOption.limitPage,
          start_date: this.dateRange?.[0]
            ? this.formatDate(new Date(this.dateRange?.[0]))
            : "",
          end_date: this.dateRange?.[1]
            ? this.formatDate(new Date(this.dateRange?.[1]))
            : "",
          offset:
            (this.paginationOption.currentPage - 1) *
            this.paginationOption.limitPage,
          // order_by: {
          //   created_at: "desc",
          // },
          // order_sort: this.selectedSort.sortAsc ? "asc" : "desc",
        };
        const response = await this.ApiGo({
          url: "api/transactions/all-data",
          formData: params,
        }).post();
        this.dailyOmset = response?.body?.data?.daily_omset;
        this.totalOmset = response?.body?.data?.total_omset;
        this.paginationOption.totalPage = Math.ceil(
          response?.body?.data?.transactions_aggregate?.aggregate?.count /
            this.paginationOption.limitPage
        );
        this.totalPage = this.paginationOption.totalPage;
        this.transactionList = response.body.data.transactions;
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      } finally {
        this.loading = false;
      }
    },
    async confirmTransaction() {
      try {
        await this.ApiGo({
          url: `api/transactions/payment/${this.savedId}`,
          formData: {
            status: "success",
          },
        }).put();
        this.getTransactionList();
        this.$toast.success("Transaksi berhasil disetujui");
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      } finally {
        this.hideModalConfirmation();
      }
    },
    findTransactionStatus() {
      const trx = this.transactionList.filter((dt) => dt.id === this.savedId);
      return trx?.[0].status;
    },
    async confirmDelivery() {
      if (this.findTransactionStatus() !== "PAID") {
        this.$toast.error("Mohon menyetujui transaksi ini terlebih dahulu");
        return
      }

      try {
        await this.Api({
          url: `api/rest/transactions/${this.savedId}/sent/package`,
          formData: {
            sent: true,
          },
        }).patch();
        this.getTransactionList();
        this.$toast.success("Pengiriman status berhasil diupdate");
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      } finally {
        this.hideModalConfirmationDelivery();
      }
    },
    async deleteTransaction(id) {
      try {
        const response = await this.Api({
          url: `api/rest/roles/${id}`,
        }).delete();

        if (response) {
          this.$toast.success("Produk berhasil dihapus");
          this.getTransactionList();
        }
      } catch {
        this.$toast.error("Gagal menghapus produk");
      }
    },
    getTotalPage(totalData) {
      this.paginationOption.totalPage = Math.ceil(
        totalData / this.paginationOption.limitPage
      );
    },
    searchData() {
      const lowerCaseinput = this.searchInput?.toLowerCase();
      if (lowerCaseinput) {
        this.resultSearch = this.transactionList.filter((dt) =>
          String(dt[this.selectedFilter.code])
            .toLowerCase()
            .includes(lowerCaseinput)
        );
        this.paginationOption.totalPage = 1;
      } else {
        this.resultSearch = null;
        this.paginationOption.totalPage = this.totalPage;
      }
    },
    toggleFilter() {
      this.filterOpened = !this.filterOpened;
    },
    closeFilter() {
      this.filterOpened = false;
    },
    toggleSort() {
      this.sortOpened = !this.sortOpened;
    },
    closeSort() {
      this.sortOpened = false;
    },
    setCurrentPage(page) {
      this.paginationOption.currentPage = page;
      this.getTransactionList();
    },
    resetSortList() {
      this.sortList.forEach((dt) => {
        dt.sortAsc = true;
      });
    },
    onTransactionCreated() {
      this.hideAddModal();
      this.hideModalConfirmationDelivery();
      this.getTransactionList();
    },
  },
  computed: {
    validData() {
      return this.resultSearch ? this.resultSearch : this.transactionList;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style>
@import "./transactioncontent.css";
</style>
