<template>
  <div class="modaldetail">
    <div class="modaldetail__mask" @click="closeModal()"></div>
    <div class="modaldetail__box">
      <div class="modaldetail__box-title">
        <p class="modaldetail__box-title-text">Contacts Detail</p>
        <img
          src="@/assets/admin/icon/close.svg"
          alt="close"
          class="modaldetail__box-title-text-img"
          @click="closeModal()"
        />
      </div>
      <div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Nama</p>
          <input
            v-model="formData.name"
            class="modaldetail__box-row-input"
            placeholder="Nama "
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Email</p>
          <input
            v-model="formData.email"
            class="modaldetail__box-row-input"
            placeholder="Email"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Subject</p>
          <input
            v-model="formData.subject"
            class="modaldetail__box-row-input"
            placeholder="Subject"
          />
        </div>
        <div class="modaldetail__box-row">
          <p class="modaldetail__box-row-title">Message</p>
          <textarea
            v-model="formData.message"
            class="modaldetail__box-row-input"
            placeholder="Message"
          ></textarea>
        </div>
        <!-- <div class="modaldetail__box-row">
            <p class="modaldetail__box-row-title">Additional Notes</p>
            <input
              type="text"
              v-model="formData.additional_note"
              class="modaldetail__box-row-input"
              placeholder="Notes tambahan"
            />
          </div> -->
        <!-- <div class="modaldetail__box-row">
            <p class="modaldetail__box-row-title">Is Active</p>
            <div class="modaldetail__box-row-radio-box-wrapper">
              <div
                class="modaldetail__box-row-radio-box"
                @click="setActive(true)"
              >
                <div class="modaldetail__box-row-radio">
                  <div
                    class="modaldetail__box-row-radio-active"
                    v-if="formData.is_active"
                  ></div>
                </div>
                <p class="modaldetail__box-row-title">Yes</p>
              </div>
              <div
                class="modaldetail__box-row-radio-box"
                @click="setActive(false)"
              >
                <div class="modaldetail__box-row-radio">
                  <div
                    class="modaldetail__box-row-radio-active"
                    v-if="!formData.is_active"
                  ></div>
                </div>
                <p class="modaldetail__box-row-title">No</p>
              </div>
            </div>
          </div> -->
        <div class="modaldetail__box-row-button" v-if="type !== 'preview'">
          <CustomButton
            :button-text="capitalizeFirstLetter(type)"
            @buttonClicked="formValidation()"
          ></CustomButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/atoms/CustomButton.vue";
export default {
  components: {
    CustomButton,
  },
  props: {
    ContactsDetail: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
    };
  },
  watch: {
    ContactsDetail(val) {
      if (val) {
        this.setContactsDetail();
      }
    },
  },
  mounted() {
    this.setContactsDetail(this.ContactsDetail);
  },
  methods: {
    setContactsDetail(val) {
      this.formData.name = val.name;
      this.formData.email = val.email;
      this.formData.subject = val.subject;
      this.formData.message = val.message;
    },
    getCode() {
      let code = this.formData.name.replace(" ", "_");
      let codeNoDot = code.replace(".", "");
      let codeLowercase = codeNoDot.toLowerCase();
      return codeLowercase;
    },

    formValidation() {
      if (!this.formData.name) {
        this.$toast.error("nama wajib diisi");
        return;
      }

      if (!this.formData.email) {
        this.$toast.error("email wajib diisi");
        return;
      }

      if (!this.formData.subject) {
        this.$toast.error("subject wajib diisi");
        return;
      }

      if (!this.formData.message) {
        this.$toast.error("message wajib diisi");
        return;
      }

      if (this.type === "add") {
        this.createContacts();
      } else {
        this.$toast.error("nothing");
      }
    },
    async createContacts() {
      // let formData = {
      //   code: this.getCode(),
      //   name: this.formData.name,
      //   email: this.formData.email,
      //   subject : this.formData.subject,
      //   message : this.formData.message
      // }

      try {
        await this.Api({
          url: "api/rest/compro/contacts/create",
          formData: { company_contact: this.formData },
        }).post();
        this.$emit("createcontacts");
        // this.getTotalPage(response.data.total_page);
        this.$toast.success("Kontak berhasil ditambahkan");
      } catch (err) {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    resetModalData() {
      this.formData = {
        name: "",
        email: "",
        subject: "",
        message: "",
      };
    },
  },
};
</script>

<style scoped>
.modaldetail {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.modaldetail__mask {
  position: fixed;
  background: black;
  opacity: 75%;
  width: 100vw;
  height: 100vh;
}

.modaldetail__box {
  padding: 0px 2rem 2rem 2rem;
  background-color: white;
  border-radius: 16px;
  z-index: 2;
  min-width: 55rem;
  height: auto;
  max-height: 600px;
  overflow-y: scroll;
}

.modaldetail__box-row-last {
  padding-bottom: 48px;
}

.modaldetail__box::-webkit-scrollbar {
  display: none;
}

.modaldetail__box-title {
  position: sticky;
  background: white;
  top: 0px;
  padding: 2rem 0 1rem 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modaldetail__box-row {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2rem;
}

.modaldetail__box-row-title {
  font-size: 14px;
  line-height: 16px;
  color: #707880;
}

.modaldetail__box-row-input {
  outline: none;
  border-radius: 8px;
  border: 1px solid #eef0f5;
  background: #f6fafd;
  padding: 8px;
}

.modaldetail__box-row-input::placeholder {
  font-size: 14px;
  line-height: 16px;
  color: darkgray;
}

.modaldetail__box-title-text {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.modaldetail__box-row-button {
  position: sticky;
  text-align: right;
  bottom: 0px;
  width: 100%;
}

.modaldetail__box-title-text-img {
  width: 26px;
  height: auto;
  position: absolute;
  right: 0px;
  cursor: pointer;
}

.modaldetail__box-row-image-box {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  border: 1px dashed black;
  border-radius: 8px;
}

.modaldetail__box-row-image-box-text {
  font-weight: bold;
}

.modaldetail__box-row-image-box-text_normal {
  font-weight: 400;
}

.modaldetail__box-row-image-box-file {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  opacity: 0;
}

.modaldetail__box-row-radio {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  border-radius: 1000px;
  border: 1px solid #333333;
}

.modaldetail__box-row-radio-active {
  width: 15px;
  height: 15px;
  border-radius: 1000px;
  border: 1.5px solid #333333;
  background-color: black;
}

.modaldetail__box-row-radio-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.modaldetail__box-row-radio-box-wrapper {
  display: flex;
  gap: 15px;
}
</style>
