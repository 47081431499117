var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navigationbar"},[_c('div',{staticClass:"navigationbar__box"},[_vm._m(0),_c('div',{staticClass:"navigationbar__box-body"},_vm._l((_vm.filteredPage),function(dt,index){return _c('div',{key:index,staticClass:"navigationbar__box-body-list"},[_c('p',{staticClass:"navigationbar__box-body-list-text",on:{"click":function($event){!dt.subMenu
              ? _vm.goTo(dt.queryRoute)
              : (dt.subMenuOpened = !dt.subMenuOpened)}}},[_vm._v(" "+_vm._s(dt?.name)+" ")]),(dt.subMenu)?_c('div',{staticClass:"navigationbar__box-body-list-wrapper",class:dt.subMenuOpened
              ? 'navigationbar__box-body-list-wrapper-full'
              : ''},[_c('div',{staticClass:"navigationbar__box-body-list-sub-container",class:dt.subMenuOpened
                ? 'navigationbar__box-body-list-sub-container_active'
                : ''},_vm._l((dt.subMenuContent),function(detail,idxDetail){return _c('div',{key:idxDetail,staticClass:"navigationbar__box-body-list-sub",on:{"click":function($event){return _vm.goTo(detail.queryRoute)}}},[_c('p',[_vm._v(_vm._s(detail.name))])])}),0)]):_vm._e()])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navigationbar__box-header"},[_c('img',{staticClass:"navigationbar__box-header-image",attrs:{"src":require("@/assets/admin/images/ghani-logo-small.png")}})])
}]

export { render, staticRenderFns }